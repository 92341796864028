import { View, Image, Text, Platform } from 'react-native';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import { PropType } from './index';
import Button from '../../components/Button';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import { SafeAreaView } from 'react-native-safe-area-context';
import HorizontalLine from '../../components/HorizontalLine';
import { AppGap } from '../../components/AppGap';

export default ({ onButtonPress }: PropType) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%', position: 'relative' }}>
        <AuthBackground />
        <View
          style={{
            marginTop: Platform.select({ android: 150, default: 100 }),
            marginLeft: Platform.select({ android: 60, default: 50 }),
            zIndex: 100,
            position: 'relative',
          }}
        >
          <Image
            source={require('../../assets/illustrations/Blue9.png')}
            style={{
              width: 300,
              height: 400,
            }}
            resizeMode="contain"
          />
        </View>
        <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
          {/*  // ? May be add Separator (AppGap) instead of MarginBottom */}
          <CustomText weight="500" style={{ fontSize: 24, marginTop: 50, textAlign: 'center' }}>
            Welcome to PhoneBox!
          </CustomText>
          <HorizontalLine thinkness={1} style={{ marginVertical: 24 }} />
          <Button
            size={'FULL'}
            color={Colors.canadaButtonColor}
            style={{ marginBottom: 10 }}
            onPress={() => onButtonPress('CANADA')}
          >
            Canadian Account
          </Button>
          <Button size={'FULL'} color={Colors.usaButtonColor} onPress={() => onButtonPress('USA')}>
            USA Account
          </Button>
          <Button
            size={'FULL'}
            color={Colors.noColor}
            textColor={Colors.greyColor}
            style={{
              padding: 5,
              marginTop: 20,
              width: 'auto',
            }}
            onPress={() => onButtonPress('GUEST')}
          >
            Continue as a guest
          </Button>
          <AppGap size={30} direction="vertical" />
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};
