import { View, ViewStyle } from 'react-native';
import { Colors } from '../constants/Colors';

type HorizontalLineProps = {
  color?: string;
  thinkness?: number;
  style?: ViewStyle;
};

const HorizontalLine = (props: HorizontalLineProps) => {
  return (
    <View
      style={{
        width: '100%',
        height: props.thinkness ?? 1,
        borderRadius: props.thinkness ?? 1,
        backgroundColor: props.color ?? Colors.HorizonalLineDefaultColor,
        ...props.style,
      }}
    ></View>
  );
};

export default HorizontalLine;
