import { View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import Checkbox from 'expo-checkbox';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { AntDesign } from '@expo/vector-icons';
import { useState } from 'react';
import { CustomerShippingOrder } from '../../types/models/models';
import { AppDrawer } from '../../components/AppDrawer';
import HorizontalLine from '../../components/HorizontalLine';
import Button from '../../components/Button';
import { accountService } from '../../services/MyAccount/AccountService';

const MarkReceivedView = ({
  selectingShippingOrder,
  setLoading,
  onComplete,
  onCancel,
}: {
  selectingShippingOrder: CustomerShippingOrder | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onComplete(): void;
  onCancel(): void;
}) => {
  const [simNumberDifferent, setSimNumberDifferent] = useState<boolean>(false);
  const [simCardNumber, setSimCardNumber] = useState<string>('');
  const [showConfirmReceived, setShowConfirmReceived] = useState<boolean>(false);

  return (
    <>
      <View>
        <CustomText weight="600" style={{ fontSize: 16 }}>
          Please confirm your SIM card number
        </CustomText>
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            gap: 10,
            borderRadius: 8,
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          <CustomText
            weight="500"
            style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor }}
          >
            SIM Card Number
          </CustomText>
          <CustomText
            weight="500"
            style={{ fontSize: 14, color: Colors.inputTextPlaceholderColor }}
          >
            {selectingShippingOrder?.SimCardNumber}
          </CustomText>
        </View>
        <View style={{ flexDirection: 'row', gap: 8, marginLeft: 16 }}>
          <Checkbox
            value={simNumberDifferent}
            onValueChange={setSimNumberDifferent}
            color={simNumberDifferent ? Colors.canadaButtonColor : undefined}
          />
          <CustomText>SIM card Number is not matched</CustomText>
        </View>
        {simNumberDifferent ? (
          <>
            <InputWithTitle
              title="Type your SIM Card Number"
              inputValue={simCardNumber}
              onInputChange={setSimCardNumber}
            />
            <View
              style={{
                borderColor: Colors.activePlanBorderColor,
                borderWidth: 1,
                borderRadius: 8,
                padding: 16,
                marginTop: 16,
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
                <View
                  style={{
                    backgroundColor: Colors.badge.red.bg,
                    padding: 4,
                    marginRight: 8,
                    borderRadius: 10,
                  }}
                >
                  <AntDesign name="exclamationcircleo" size={16} color={Colors.badge.red.color} />
                </View>
                <CustomText weight="600" style={{ fontSize: 16 }}>
                  Be aware!
                </CustomText>
              </View>
              <CustomText>
                Please enter a new SIM card number only if it does not match the number we shipped
                to you. Updates will be processed after verification.
              </CustomText>
            </View>
          </>
        ) : null}
      </View>
      <AppDrawer
        visible={showConfirmReceived}
        height={385}
        onClose={() => {
          setShowConfirmReceived(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Confirm SIM Card?
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText weight="400" style={{ fontSize: 16, textAlign: 'justify', marginVertical: 16 }}>
          Do you confirm this is the correct SIM card number and that you are ready to use our
          service?
        </CustomText>
        <Button
          style={{ marginTop: 32 }}
          onPress={async () => {
            if (!selectingShippingOrder) {
              return;
            }
            try {
              setLoading(true);
              const response = await accountService.confirmShippingOrderReceipt({
                type: selectingShippingOrder.Type,
                shippingOrderId: selectingShippingOrder.ShippingOrderId,
                simCardNumber: simNumberDifferent
                  ? simCardNumber
                  : selectingShippingOrder.SimCardNumber,
              });
              onComplete();
            } catch (error) {
            } finally {
              setLoading(false);
            }
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Confirm
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => {
            setShowConfirmReceived(false);
          }}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Cancel
          </CustomText>
        </Button>
      </AppDrawer>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            setShowConfirmReceived(true);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Confirm Receipt
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => {
            onCancel();
          }}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Close
          </CustomText>
        </Button>
      </View>
    </>
  );
};

export default MarkReceivedView;
