import { Image, Text, View } from 'react-native';
import { Colors } from '../constants/Colors';
import Button from './Button';
import CustomText from './utils/CustomText';

const CountryToggle = () => {
  return (
    <Button
      isChildNonText={true}
      onPress={() =>
        alert(
          'To Switch to US Application, Please download it from App Store (Ios) or Play Store (Android)'
        )
      }
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.countryToggleBackgroundColor,
        borderRadius: 10,
        padding: 10,
        width: 90,
        position: 'relative',
      }}
    >
      <Image
        source={require('../assets/images/flag-canada.png')}
        style={{
          width: 30,
          height: 20,
          borderRadius: 2,
        }}
        resizeMode="cover"
      />
      <Image
        source={require('../assets/images/flag-us.png')}
        style={{
          width: 30,
          height: 20,
          borderRadius: 2,
          marginLeft: 10,
        }}
        resizeMode="cover"
      />
      {/* <CustomText
        weight="600"
        style={{
          marginLeft: 5,
          color: Colors.canadaButtonColor,
          fontWeight: '600',
        }}
      >
        CA
      </CustomText> */}
    </Button>
  );
};

export default CountryToggle;
