import { Image, ImageSourcePropType, SafeAreaView, Text, View } from 'react-native';
import { AppGap } from '../../components/AppGap';
import CustomText from '../../components/utils/CustomText';

type Props = {
  title: string;
  subtitle: string;
  imageChild: React.ReactNode;
  index?: number;
  width: number;
};

const IntroSlide = ({ title, subtitle, imageChild, width, index }: Props) => {
  return (
    <SafeAreaView style={{ display: 'flex', height: '100%', width: width }}>
      {/* {index < 3 ? <AppGap direction="vertical" size={100} /> : null} */}
      <View style={{ width: width, position: 'absolute', top: 0, left: 0 }}>
        <Image
          source={require('../../assets/intro-slider/intro-slider-bg.png')}
          resizeMode="cover"
          style={{ width: '100%' }}
        />
      </View>
      {imageChild}
      <CustomText
        weight="600"
        style={{
          fontSize: 36,
          fontWeight: '600',
          lineHeight: 46,
          textAlign: 'center',
          maxWidth: 300,
          alignSelf: 'center',
          marginBottom: 10,
        }}
      >
        {title}
      </CustomText>
      <CustomText
        style={{
          fontSize: 16,
          fontWeight: '400',
          lineHeight: 24,
          textAlign: 'center',
          maxWidth: 300,
          alignSelf: 'center',
        }}
      >
        {subtitle}
      </CustomText>
    </SafeAreaView>
  );
};

export default IntroSlide;
