import {
  GradientDirection,
  InfoStatus,
  PaymentMethod,
  PaymentType,
  PortCarrier,
  PortInStatus,
  PortOutStatus,
  ProvinceInfo,
  ShippingStatus,
  SuspensionReason,
  SuspensionStatus,
} from '../types/types';
import { Colors } from './Colors';

// regexs
export const emailReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const everythingButChineseCharacters = /^[\u0000-\u4DFF\u9FA6-\uFFFF]*$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&.]{8,}$/;
export const phoneNumberRegex = /^\d{10}$/;
export const onlyNumberRegex = /^\d{1,6}$/;

export const provinces: ProvinceInfo[] = [
  { key: 1, name: 'Alberta', code: 'AB' },
  { key: 2, name: 'British Columbia', code: 'BC' },
  { key: 3, name: 'Manitoba', code: 'MB' },
  { key: 4, name: 'New Brunswick', code: 'NB' },
  { key: 5, name: 'Newfoundland and Labrador', code: 'NL' },
  { key: 6, name: 'Northwest Territories', code: 'NT' },
  { key: 7, name: 'Nova Scotia', code: 'NS' },
  { key: 8, name: 'Nunavut', code: 'NU' },
  { key: 9, name: 'Ontario', code: 'ON' },
  { key: 10, name: 'Prince Edward Island', code: 'PE' },
  // { key: 11, name: 'Quebec', code: 'QC' },
  { key: 12, name: 'Saskatchewan', code: 'SK' },
  { key: 13, name: 'Yukon Territory', code: 'YT' },
];

export const DATAUSAGE_NOT_AVAILABLE = 99999999;
export const DATACAPACITY_UNLIMITED = 95367;
export const NON_ACTIVATED_CNUM = '0000000000000';

export const MOBILE_RETURN_URL = 'https://myaccount.gophonebox.com/mobilereturn';
export const MOBILE_CANCEL_URL = 'https://myaccount.gophonebox.com/mobilecancel';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CRED_EMAIL = 'cred-email';
export const CRED_REMEMBER_ME = 'cred-remember-me';
export const REMEBER_ME = 'remember-me';

export const SUSPENSION_TYPE: { [key in SuspensionReason]: SuspensionStatus } = {
  UP: 'unpaid',
  LS: 'lost-stolen',
  VC: 'vacation',
  NU: 'no-usage',
  ED: 'excessive-data',
};

export const HERO_GRADIENT: {
  [key in SuspensionStatus]: { start: string; end: string; direction: GradientDirection };
} = {
  default: {
    start: Colors.gradient.default.start,
    end: Colors.gradient.default.end,
    direction: 'LEFT-RIGHT',
  },
  vacation: {
    start: Colors.gradient.vacation.start,
    end: Colors.gradient.vacation.end,
    direction: 'TOP-DOWN',
  },
  'lost-stolen': {
    start: Colors.gradient.lostStolen.start,
    end: Colors.gradient.lostStolen.end,
    direction: 'TOP-DOWN',
  },
  terminated: {
    start: Colors.gradient.terminated.start,
    end: Colors.gradient.terminated.end,
    direction: 'TOP-DOWN',
  },
  unpaid: {
    start: Colors.gradient.unpaid.start,
    end: Colors.gradient.unpaid.end,
    direction: 'TOP-DOWN',
  },
  'no-usage': {
    start: Colors.gradient.default.start,
    end: Colors.gradient.default.end,
    direction: 'LEFT-RIGHT',
  },
  'excessive-data': {
    start: Colors.gradient.default.start,
    end: Colors.gradient.default.end,
    direction: 'LEFT-RIGHT',
  },
};

export const ShippingStatuses: ShippingStatus[] = [
  'queued',
  'ready',
  'sent/in-transit',
  'incomplete',
  'invalid address',
  'returned',
  'received',
];

export const BADGES_COLOR: Record<ShippingStatus | PortOutStatus | PortInStatus, InfoStatus> = {
  queued: 'grey',
  ready: 'grey',
  'sent/in-transit': 'green',
  incomplete: 'red',
  'invalid address': 'red',
  returned: 'red',
  received: 'green',
  processing: 'grey',
  completed: 'green',
  cancelled: 'red',
  telus: 'light-blue',
  rogers: 'light-blue',
  'ownership transfer': 'light-blue',
  'china telecom': 'light-blue',
  '1st pending port out': 'light-blue',
  '2st pending port out': 'light-blue',
  'revoke completion': 'light-blue',
  'notified: 90 mins': 'blue',
  'notified: invalid info': 'red',
  'notified: device balance': 'red',
  'notified: dependent line': 'grey',
  'notified: inactive line': 'grey',
  'notified: authorization note': 'grey',
  'notified: incompleted': 'red',
  'notified: land line': 'grey',
  submition: 'grey',
  incompleted: 'red',
  'notified: cancelled': 'red',
  'notified: others': 'grey',
  'notified: invalid sim card': 'red',
  'notified: interaction id (t)': 'grey',
  'notified: completion (t)': 'grey',
};

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_FORMAT_FOR_API = 'YYYY-MM-DD';

export const PAYMENT_METHOD_PAYPAL = 'paypal';
export const PAYMENT_METHOD_CREDIT_CARD = 'creditcard';
export const PAYMENT_METHOD_ALIPAY_QR = 'alipay_qr';
export const PAYMENT_METHOD_WECHAT_PAY = 'wx_native';
export const PAYMENT_METHOD_APPLE_PAY = 'applePay';
export const PAYMENT_METHOD_GOOGLE_PAY = 'googlePay';

// Record<PaymentType, PaymentMethod>
export const PaymentTypeMethod: {
  paymentTypeSlug: PaymentType;
  paymentType: PaymentMethod;
}[] = [
    { paymentTypeSlug: 'SAVED_CARD', paymentType: PAYMENT_METHOD_CREDIT_CARD },
    { paymentTypeSlug: 'NEW_CARD', paymentType: PAYMENT_METHOD_CREDIT_CARD },
    { paymentTypeSlug: 'CREDIT_CARD', paymentType: PAYMENT_METHOD_CREDIT_CARD },
    { paymentTypeSlug: 'GOOGLE_PAY', paymentType: PAYMENT_METHOD_GOOGLE_PAY },
    { paymentTypeSlug: 'PAYPAL', paymentType: PAYMENT_METHOD_PAYPAL },
    { paymentTypeSlug: 'WECHAT_PAY', paymentType: PAYMENT_METHOD_WECHAT_PAY },
    { paymentTypeSlug: 'ALIPAY', paymentType: PAYMENT_METHOD_ALIPAY_QR },
    { paymentTypeSlug: 'APPLE_PAY', paymentType: PAYMENT_METHOD_APPLE_PAY },
  ];

export const carriers: PortCarrier[] = [
  'Rogers',
  'Fido',
  'Telus',
  'Koodo',
  'Bell',
  'Lucky',
  'Virgin',
  'Chatr',
  'Public Mobile',
  'Freedom',
  'Others',
];

export const InvoiceDateMonth: Record<string, string> = {
  'jan': '01',
  'feb': '02',
  'mar': '03',
  'apr': '04',
  'may': '05',
  'jun': '06',
  'jul': '07',
  'aug': '08',
  'sep': '09',
  'oct': '10',
  'nov': '11',
  'dev': '12',
}