import { instance } from '../../api/axios';
import { ApiRequest } from '../../types/requests/ApiRequest';
import { ApiResponse } from '../../types/responses/ApiResponse';
import { TopUpExtensionRequest } from '../../types/requests/MyAccount/TopUpExtensionRequest';
import { TopUpExtensionResponse } from '../../types/responses/MyAccount/TopUpExtensionResponse';
import { PrepaidUserInfoRequest } from '../../types/requests/MyAccount/PrepaidUserInfoRequest';
import { PrepaidUserInfoResponse } from '../../types/responses/MyAccount/PrepaidUserInfoResponse';
import { UpdateAutoExtensionPaymentInfoRequest } from '../../types/requests/MyAccount/UpdateAutoExtensionPaymentInfoRequest';
import { UpdateAutoExtensionPaymentInfoResponse } from '../../types/responses/MyAccount/UpdateAutoExtensionPaymentInfoResponse';
import { AvailablePlansRequest } from '../../types/requests/MyAccount/AvailablePlansRequest';
import { AvailablePlansResponse } from '../../types/responses/MyAccount/AvailablePlansResponse';
import { PlanDetailByIDRequest } from '../../types/requests/MyAccount/PlanDetailByIDRequest';
import { PlanDetailByIDResponse } from '../../types/responses/MyAccount/PlanDetailByIDResponse';

export const prepaidService = {
  topUpExtension: async (
    request: ApiRequest<TopUpExtensionRequest>
  ): Promise<ApiResponse<TopUpExtensionResponse>> =>
    await instance.post<ApiRequest<TopUpExtensionRequest>, ApiResponse<TopUpExtensionResponse>>(
      '/Prepaid/TopUpExtension',
      {
        amount: request.data.payload.amount,
        cnum: request.data.payload.cnum,
        currency: request.data.payload.currency,
        ex_edate: request.data.payload.extensionEndDate,
        ex_sdate: request.data.payload.extensionStartDate,
        ex_subtotal: request.data.payload.extensionSubtotal,
        newPfID: request.data.payload.newPfID,
        paymentMethod: request.data.payload.paymentMethod,
        systag: request.data.payload.systag,
        tp_capacity: request.data.payload.topUpCapacity,
        duration: request.data.payload.duration,
      },
      {
        params: {
          return_url: request.data.returnUrl,
          cancel_url: request.data.cancelUrl,
        },
      }
    ),
  prepaidUserInfo: async () => { },
  updateAutoExtensionPaymentInfo: async () => { },
  availablePlans: async () => { },
  planDetailByID: async () => { },
};