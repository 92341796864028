import { ScrollView, StyleSheet, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import Button from '../../components/Button';

const CancelPlanView = ({ onPress }: { onPress(): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.errorRed }}
        >
          Port the Number out
        </CustomText>
        <CustomText style={{ fontSize: 14, marginTop: 16, textAlign: 'center', marginBottom: 24 }}>
          Port-out is the process of transferring your existing phone number from PhoneBox to
          another provider.
        </CustomText>
        <View style={{ gap: 16 }}>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>1</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Request Port out.
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>2</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Data service will be paused.
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>3</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Account Number will be provided via email within 2-3 business day.
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>4</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Submit Port In with new service provider.
              <CustomText
                weight="400"
                style={{ fontSize: 10, color: Colors.errorRed, display: 'flex' }}
              >
                Please cancel the port-in request if you have already submitted it before receiving
                your account number.
              </CustomText>
            </CustomText>
          </View>
          <View style={style.listItem}>
            <CustomText style={style.listItemNumber}>5</CustomText>
            <CustomText weight="500" style={style.listItemText}>
              Once port in is done, you need to request termination.
            </CustomText>
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={onPress}
          textStyle={{ fontSize: 14 }}
          color={Colors.errorRed}
        >
          Next
        </Button>
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    padding: 10,
  },
  listItemNumber: {
    backgroundColor: Colors.ActionLinkColorBG,
    display: 'flex',
    width: 26,
    height: 26,
    marginRight: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.ActionLinkColor,
    fontSize: 16,
  },
  listItemText: { fontSize: 14 },
});
export default CancelPlanView;
