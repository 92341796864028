import { Platform, Text, View } from 'react-native';
import { Colors } from '../../../constants/Colors';
import { AntDesign, Feather } from '@expo/vector-icons';
import { formatPhoneNumber } from '../../../utilities/utilities';
import VerticalLine from '../../VerticalLine';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import CustomText from '../../utils/CustomText';

type Props = {
  status?: 'Active' | 'Terminated';
};

const Plan = ({ status = 'Active', ...props }: Props) => {
  const { accounts, activeCnum, isMultiLineAccount } = useSelector(
    (state: RootState) => state.user
  );
  const { PhoneNumber } = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const { CurrentPlan_Days, DataCapacityName, CurrentPlan } = useSelector(
    (state: RootState) => state.selfServeCA.accountInfoCA
  );
  // @ts-ignore
  const selectedAccount = isMultiLineAccount ? accounts.find((a) => a.Cnum === activeCnum) : null;

  return (
    <View
      style={{
        backgroundColor: status === 'Active' ? Colors.white : Colors.backgroundGrey,
        width: Platform.select({android: 190, ios: 190, default: 175}),
        // height: 104,
        borderRadius: 8,
        borderColor: Colors.activePlanBorderColor,
        borderWidth: 1,
        position: 'relative',
        marginLeft: 20,
        // justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingBottom: 16,
        paddingTop: 20,
        marginVertical: 12,
      }}
    >
      <View
        style={{
          backgroundColor:
            status === 'Active' ? Colors.activePlanBannerColor1 : Colors.NoPlanBannerColor1,
          // width: 65,
          paddingLeft: 7,
          paddingRight: 10,
          paddingVertical: 4,
          position: 'absolute',
          top: -10,
          left: -7,
          borderTopLeftRadius: Platform.select({ default: 30, android: 30, ios: 8 }),
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
          zIndex: 1,
        }}
      >
        <View
          style={{
            position: 'absolute',
            top: Platform.select({ web: 24, android: 28, ios: 24,  default: 28 }),
            left: 0,
            backgroundColor:
              status === 'Active' ? Colors.activePlanBannerColor2 : Colors.NoPlanBannerColor2,
            zIndex: -1,
            width: 6.5,
            height: 5,
            borderBottomLeftRadius: 15,
          }}
        ></View>
        <CustomText
          weight="500"
          style={{
            color: Colors.white,
            fontWeight: '500',
            fontSize: 12,
            textAlign: 'center',
          }}
          numberOfLines={1}
        >
          {status}
        </CustomText>
      </View>

      <View style={{ alignItems: 'center' }}>
        <CustomText weight="500" style={{ fontSize: 14, fontWeight: '500', marginBottom: 10 }} numberOfLines={1}>
          Canada Mobile Plan
        </CustomText>
        <View
          style={{
            backgroundColor:
              status === 'Active' ? Colors.countryToggleBackgroundColor : Colors.terminatedPlanBG0,
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 4,
            paddingHorizontal: 18,
            borderRadius: 8,
            marginBottom: 10,
          }}
        >
          <Feather
            name="phone"
            size={20}
            color={status === 'Active' ? Colors.ActionLinkColor : Colors.inputTextPlaceholderColor}
          />
          <CustomText style={{ marginLeft: 4, fontSize: 12, fontWeight: '400' }} numberOfLines={1}>
            {formatPhoneNumber(
              isMultiLineAccount && selectedAccount && typeof selectedAccount !== 'string'
                ? selectedAccount!.Pnum
                : PhoneNumber
            )}
          </CustomText>
        </View>
        <CustomText
          weight="600"
          style={{
            fontSize: 16,
            fontWeight: '600',
            marginBottom: 10,
            alignSelf: 'flex-start',
          }}
        >
          {CurrentPlan.split('/')[0]}
        </CustomText>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            minWidth: 130,
            marginBottom: 10,
            alignSelf: 'flex-start',
          }}
        >
          <CustomText weight="500" style={{ fontSize: 14, fontWeight: '500' }}>
            {DataCapacityName}
          </CustomText>
          <VerticalLine thinkness={1} />
          <CustomText weight="500" style={{ fontSize: 14, fontWeight: '500' }}>
            {CurrentPlan_Days} DAYS
          </CustomText>
        </View>
      </View>
    </View>
  );
};

export default Plan;
