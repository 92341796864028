import {
  NavigationContainer,
  createNavigationContainerRef,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  createNativeStackNavigator,
  NativeStackNavigationOptions,
} from '@react-navigation/native-stack';
import { RootStackParamList, RootTabParamList } from '../types';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import useIsMobile from '../hooks/useIsMobile';
import LinkingConfiguration from './LinkingConfiguration';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { tokenService } from '../services/TokenService';
import { authService } from '../services/SSO/AuthService';
import user, {
  resetComplete,
  resetUser,
  setAccounts,
  setLoggedIn,
  setProfile,
} from '../store/user';
import {
  Ionicons,
  MaterialCommunityIcons,
  AntDesign,
  FontAwesome6,
  Feather,
  Octicons,
} from '@expo/vector-icons';
import { Colors } from '../constants/Colors';
import { setIsLoading, setIsNewUser } from '../store/app';

import LandingScreen from '../screens/LandingScreen';
import RecoverYourPasswordScreen from '../screens/RecoverYourPasswordScreen';
import JoinSuccessScreen from '../screens/JoinSuccessScreen';
import SelectLineScreen from '../screens/SelectLineScreen';
// import WelcomeScreen from '../screens/OnboardingScreen/WelcomeScreen';
import LoadingScreen from '../screens/LoadingScreen';
// import OnboardingSelectSimCardScreen from '../screens/OnboardingScreen/OnboardingSelectSimCardScreen';
// import OnboardingActivationInfoScreen from '../screens/OnboardingScreen/OnboardingActivationInfoScreen';
// import OnboardingPaymentScreen from '../screens/OnboardingScreen/OnboardingPaymentScreen';
// import OnboardingPaymentSuccessScreen from '../screens/OnboardingScreen/OnboardingPaymentSuccessScreen';
// import OnboardingCartScreen from '../screens/OnboardingScreen/OnboardingCartScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ShopScreen from '../screens/ShopScreen';
import BillingScreen from '../screens/BillingScreen';
import FriendsReferralScreen from '../screens/FriendsReferralScreen';
import AccountScreen from '../screens/AccountScreen';
import EditNameScreen from '../screens/EditNameScreen';
import EditAddressScreen from '../screens/EditAddressScreen';
import EditEmailScreen from '../screens/EditEmailScreen';
import BillingPayBalanceScreen from '../screens/BillingPayBalanceScreen';
import PaymentSuccessScreen from '../screens/Payment/PaymentSuccessScreen';
import ChangePlanScreen from '../screens/ChangePlanScreen';
import TemporaryHoldsScreen from '../screens/TemporaryHoldsScreen';
import VacationHoldScreen from '../screens/VacationHoldScreen';
import LostOrStolenScreen from '../screens/LostOrStolenScreen';
import CloseAccountScreen from '../screens/CloseAccountScreen';
import GenericSuccessScreen from '../screens/GenericSuccessScreen';
import EditPasswordScreen from '../screens/EditPasswordScreen';
// import OnboardingAddressScreen from '../screens/OnboardingScreen/OnboardingAddressScreen';
import HomeScreen from '../screens/HomeScreen';

import LogInScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/JoinScreen';
import IntroSliderScreen from '../screens/IntroSliderScreen';
import BenefitsScreen from '../screens/BenefitsScreen';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { CRED_REMEMBER_ME, REMEBER_ME } from '../constants/constants';
import FullScreenLoader from '../components/utils/FullScreenLoader';
import { Platform, SafeAreaView, StatusBar, Text } from 'react-native';
import { getUserProfileAsync } from '../utilities/functions';
import { setAccountInfoCA } from '../store/selfServeCA';
import { accountService } from '../services/MyAccount/AccountService';
import StatusTrackingScreen from '../screens/StatusTrackingScreen';
import ShippingTrackingScreen from '../screens/ShippingTrackingScreen';
import CustomText from '../components/utils/CustomText';
import DeleteAccountScreen from '../screens/CloseAccount';
import PlansScreen from '../screens/PlansScreen';
import PurchasePlanFLowScreen from '../screens/PurchasePlanFlowScreen';
import PaymentWebViewScreen from '../screens/Payment/PaymentWebViewScreen';
import OnboardingScreen from '../screens/OnboardingScreen';
import ChangePlanSuccessScreen from '../screens/ChangePlanScreen/ChangePlanSuccessScreen';
import PrepaidPlanCheckoutScreen from '../screens/ChangePlanScreen/PrepaidPlanCheckoutScreen';
import AddMoreDataScreen from '../screens/ChangePlanScreen/AddMoreDataScreen';
import PortOptionScreen from '../screens/PortOptionScreen';
import MyOrderScreen from '../screens/MyOrderScreen';
import ServiceRequestTrackingScreen from '../screens/ServiceRequestTrackingScreen';
import InvoicesScreen from '../screens/InvoicesScreen';
import ManagePaymentScreen from '../screens/ManagePaymentScreen';

export default function Navigation() {
  const isAppLoading = useSelector((state: RootState) => state.app.isLoading);
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <FullScreenLoader loading={isAppLoading} />
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { loggedIn, cnum, id, userReset, ...user } = useSelector((state: RootState) => state.user);
  const { isNewUser } = useSelector((state: RootState) => state.app);

  // Persist Login State if RememberMe!
  useEffect(() => {
    dispatch(setIsLoading(true));
    const initialChecks = async (isNew: boolean) => {
      const rememberMe = (await AsyncStorage.getItem(CRED_REMEMBER_ME)) === REMEBER_ME;
      if (!rememberMe) {
        // If not rememberMe Force Logout user on initial load
        // this is for mobile application only
        if (Platform.OS !== 'web') {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteCASelfServeTokenAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRefreshTokenExpiryAsync();
          await tokenService.deleteRememberMe();
          dispatch(resetUser());
          dispatch(setIsLoading(false));
          navigation.navigate(isNew ? 'IntroSlider' : 'Landing');
          return;
        }
      }
      const token = await tokenService.getAccessTokenAsync();
      if (!token) {
        dispatch(setIsLoading(false));
        navigation.navigate(isNew ? 'IntroSlider' : 'Landing');
        return;
      }
      dispatch(setLoggedIn(true));
    };
    const checkIfNewUser = async () => {
      const isNew = await AsyncStorage.getItem('viewed-intro-slider');
      console.log({ isNew });

      dispatch(setIsNewUser(isNew !== 'yes'));
      return isNew !== 'yes';
    };
    checkIfNewUser()
      .then((isNew) => {
        initialChecks(isNew);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const checkAccessToken = async () => {
      dispatch(setIsLoading(true));
      const token = await tokenService.getAccessTokenAsync();

      if (!token) {
        console.log('Token not found.');
        return;
      }

      const now = new Date();
      const tokenExpiry = await tokenService.getAccessTokenExpiryAsync();
      const refreshTokenExpiry = await tokenService.getRefreshTokenExpiryAsync();

      if (tokenExpiry && +now > +tokenExpiry) {
        console.log('Token no longer valid');

        if (refreshTokenExpiry && +now < +refreshTokenExpiry) {
          console.log('Refreshing...');

          const refreshTokenResponse = await authService.refreshToken({});

          if (
            !refreshTokenResponse ||
            !refreshTokenResponse.data ||
            !refreshTokenResponse.data.success
          ) {
            console.log('Refresh failed, forcing logout.');
            await tokenService.deleteAccessTokenAsync();
            await tokenService.deleteAccessTokenExpiryAsync();
            await tokenService.deleteRefreshTokenAsync();
            await tokenService.deleteRememberMe();
            await tokenService.deleteRefreshTokenExpiryAsync();
            return;
          }

          if (refreshTokenResponse?.data?.success && refreshTokenResponse.data.data.idToken) {
            await tokenService.setAccessTokenAsync(refreshTokenResponse.data.data.idToken);
            await tokenService.setAccessTokenExpiryAsync(
              +now + refreshTokenResponse.data.data.idTokenExpiresIn * 1000
            );
            console.log('refresh succeeded!');
          }
        } else {
          console.log('Refresh token expired, forcing logout.');
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteAccessTokenExpiryAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRememberMe();
          await tokenService.deleteRefreshTokenExpiryAsync();
          return;
        }
      }

      console.log('Token found.');
      console.log(`Time is now: ${now.toISOString()}`);
      console.log(`token expiry: ${tokenExpiry}`);
    };

    const _checkAccessTokenThen = () => {
      // @ts-ignore
      getUserProfileAsync(dispatch, navigation, cnum)
        .then((res: boolean | undefined | null) => {
          if (res === true) {
            console.info(
              '%cWARNING: Update to Alert!',
              'color: red; font-size: 40px; font-weight: bold; background-color: #fdd; padding: 14px; border: 4px solid red; border-radius: 10px;'
            );
            // alert('Remove this');
            navigation.navigate('Root');
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(setIsLoading(false));
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    };

    const _checkAccessTokenCatch = async (err: any) => {
      console.error(err);
      // log user out if refresh is unauthorized (meaning refresh token expired)
      if (err.response.status === 401 && err.code === 'ERR_BAD_REQUEST') {
        try {
          await tokenService.deleteAccessTokenAsync();
          await tokenService.deleteRefreshTokenAsync();
          await tokenService.deleteRememberMe();
          dispatch(setLoggedIn(false));
          dispatch(resetUser());
        } catch (deleteErr) {
          console.error(deleteErr);
        }
      }
      dispatch(setIsLoading(false));
    };

    console.info(
      '%cWARNING: May be remove this? I think Redux is keeping track of state even after reloading.',
      'color: red; font-size: 20px; font-weight: bold; background-color: #fdd; padding: 10px; border: 1px solid red; border-radius: 5px;'
    );

    if (loggedIn) {
      console.info(
        '%cWARNING: May be Fix this? Being called 2 times on initial load.',
        'color: red; font-size: 20px; font-weight: bold; background-color: #fdd; padding: 10px; border: 1px solid red; border-radius: 5px;'
      );
      checkAccessToken().then(_checkAccessTokenThen).catch(_checkAccessTokenCatch);
    } else {
      dispatch(setIsLoading(false));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (userReset === true) {
      navigation.navigate('Landing');
      setTimeout(() => {
        dispatch(resetComplete());
      }, 0);
    }
  }, [userReset]);

  const pageOptions = {
    headerTintColor: '#000000',
    animation: 'fade',
    headerShown: false,
    headerStyle: { backgroundColor: '#ffffff' },
    headerTitleStyle: {
      fontFamily: 'Poppins-Medium',
      fontSize: 16,
      fontWeight: '500',
    },
    headerShadowVisible: false,
    title: '',
  } as NativeStackNavigationOptions;

  const flipPageOptions = {
    headerTintColor: '#000000',
    animation: 'default',
    headerShown: false,
    headerStyle: { backgroundColor: '#ffffff' },
    headerTitleStyle: {
      fontFamily: 'Poppins-Medium',
      fontSize: 16,
      fontWeight: '500',
    },
    headerShadowVisible: false,
    title: '',
  } as NativeStackNavigationOptions;

  return (
    <Stack.Navigator
      // initialRouteName={'StatusTracking'}
      // initialRouteName={'EditPassword'}
      initialRouteName={isNewUser && Platform.OS !== 'web' ? 'IntroSlider' : 'Loading'}
      // initialRouteName={loggedIn ? 'Loading' : 'Landing'}
      screenOptions={{
        title: 'PhoneBox',
        contentStyle: { backgroundColor: '#ffffff' },
        // headerTitle: ({ children }) => (
        //   <CustomText weight="500" style={{ fontSize: 16, backgroundColor: 'red' }}>
        //     {children}
        //   </CustomText>
        // ),
      }}
    >
      {/* // ToDo:  Need to fix this, need to set which screens are available for which status */}
      {!loggedIn ? (
        <>
          <Stack.Screen
            name="IntroSlider"
            component={IntroSliderScreen}
            options={{
              headerShown: false,
              headerBackVisible: true,
              title: isMobile ? '' : 'PhoneBox - Welcome',
            }}
          />
          <Stack.Screen
            name="Landing"
            component={LandingScreen}
            options={{
              headerShown: false,
              headerBackVisible: true,
              title: isMobile ? '' : 'PhoneBox - Welcome',
            }}
          />
          <Stack.Screen
            name="LogIn"
            component={LogInScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Login' }}
          />
          <Stack.Screen
            name="Join"
            component={RegisterScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Join' }}
          />
          <Stack.Screen
            name="RecoverPassword"
            component={RecoverYourPasswordScreen}
            options={{
              ...pageOptions,
              title: isMobile ? '' : 'PhoneBox - Recover Your Password',
            }}
          />
          <Stack.Screen
            name="JoinSuccess"
            component={JoinSuccessScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Success' }}
          />
          <Stack.Screen
            // @ts-ignore
            name="SelectLine"
            component={SelectLineScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Success' }}
          />
        </>
      ) : null}
      {
        <>
          {/* MAIN SCREENS */}
          <Stack.Screen
            name="Root"
            component={BottomTabNavigator}
            options={{
              ...pageOptions,
              title: isMobile ? '' : 'PhoneBox - Dashboard',
            }}
          />
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Dashboard' }}
          />
          <Stack.Screen
            name="Loading"
            component={LoadingScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Loading' }}
          />
          <Stack.Screen
            name="Settings"
            component={SettingsScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Settings' }}
          />
          <Stack.Screen
            name="Shop"
            component={ShopScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Shop' }}
          />
          <Stack.Screen
            name="Plans"
            component={PlansScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
              title: isMobile ? 'Plans' : 'Plans - PhoneBox',
            })}
          />
          <Stack.Screen
            name="PurchasePlanFlow"
            component={PurchasePlanFLowScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
              title: isMobile ? 'SIM Type' : 'SIM Type - PhoneBox',
            })}
          />
          <Stack.Screen
            name="PaymentWebView"
            component={PaymentWebViewScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: false,
              headerTitleAlign: 'center',
              title: isMobile ? 'Payment' : 'Payment - PhoneBox',
            })}
          />

          {/* UTILITY SCREENS */}
          <Stack.Screen
            name="StatusTracking"
            component={StatusTrackingScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
              title: isMobile ? 'Activity Tracking' : 'Status Tracking - PhoneBox',
              headerRight: () => (
                <Octicons name="sort-desc" size={24} color="black" style={{ marginRight: 6 }} />
              ),
            })}
          />
          <Stack.Screen
            name="ShippingTracking"
            component={ShippingTrackingScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
              title: isMobile ? 'Shipping Tracking' : 'Shipping Tracking - PhoneBox',
            })}
          />
          <Stack.Screen
            name="ServiceRequestTracking"
            component={ServiceRequestTrackingScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
              title: isMobile ? 'Service Request Tracking' : 'Service Request Tracking - PhoneBox',
            })}
          />
          <Stack.Screen
            name="FriendsReferral"
            component={FriendsReferralScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Friends Referral' }}
          />
          <Stack.Screen
            name="PortOption"
            component={PortOptionScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              title: isMobile ? 'Porting' : 'Porting - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="MyOrders"
            component={MyOrderScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              title: isMobile ? 'My Orders' : 'My Orders - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="Account"
            component={AccountScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              title: isMobile ? 'Profile' : 'Profile - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="EditName"
            component={EditNameScreen}
            options={({ navigation }) => ({
              ...pageOptions,
              headerShown: true,
              title: isMobile ? 'Edit Name' : 'Edit Name - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="EditAddress"
            component={EditAddressScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Edit Profile' : 'Edit Profile - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="EditEmail"
            component={EditEmailScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Change email' : 'Change email - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="EditPassword"
            component={EditPasswordScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Update Password' : 'Update Password - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="DeleteAccount"
            component={DeleteAccountScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Delete Account' : 'Delete Account - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="Invoices"
            component={InvoicesScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Invoice' : 'Invoice - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="ManagePayment"
            component={ManagePaymentScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Manage Payment' : 'Manage Payment - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="BillingPayBalance"
            component={BillingPayBalanceScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: false,
              title: isMobile ? 'Pay Bill' : 'Pay Bill - PhoneBox',
            })}
          />
          <Stack.Screen
            name="PaymentSuccess"
            component={PaymentSuccessScreen}
            options={{ ...flipPageOptions, title: isMobile ? '' : 'PhoneBox - Payment Success' }}
          />
          <Stack.Screen
            name="ChangePlan"
            component={ChangePlanScreen}
            options={{
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Change Plan' : 'Change Plan - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            }}
          />
          <Stack.Screen
            name="PrepaidPlanCheckout"
            component={PrepaidPlanCheckoutScreen}
            options={{
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Change Plan Checkout' : 'Change Plan Checkout - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            }}
          />
          <Stack.Screen
            name="AddMoreData"
            component={AddMoreDataScreen}
            options={{
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Add More Data' : 'Add More Data - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            }}
          />
          <Stack.Screen
            name="ChangePlanSuccess"
            component={ChangePlanSuccessScreen}
            options={{
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Plan Change Success' : 'Plan Change Success - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            }}
          />
          <Stack.Screen
            name="TemporaryHolds"
            component={TemporaryHoldsScreen}
            options={{ ...flipPageOptions, title: isMobile ? '' : 'PhoneBox - Temporary Holds' }}
          />
          <Stack.Screen
            name="VacationHold"
            component={VacationHoldScreen}
            options={{ ...flipPageOptions, title: isMobile ? '' : 'PhoneBox - Vacation Hold' }}
          />
          <Stack.Screen
            name="LostOrStolen"
            component={LostOrStolenScreen}
            options={{ ...flipPageOptions, title: isMobile ? '' : 'PhoneBox - Lost or Stolen' }}
          />
          <Stack.Screen
            name="CloseAccount"
            component={CloseAccountScreen}
            options={({ navigation }) => ({
              ...flipPageOptions,
              headerShown: true,
              title: isMobile ? 'Close Account' : 'Close Account - PhoneBox',
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
            })}
          />
          <Stack.Screen
            name="GenericSuccess"
            component={GenericSuccessScreen}
            options={{ ...flipPageOptions, title: isMobile ? '' : 'PhoneBox - Success' }}
          />

          {/* ONBOARDING SCREENS */}
          <Stack.Screen
            name="Onboarding"
            component={OnboardingScreen}
            options={{
              ...pageOptions,
              headerShown: true,
              headerLeft: () => (
                <Feather
                  name="chevron-left"
                  size={24}
                  color="black"
                  onPress={() => navigation.goBack()}
                />
              ),
              headerTitleAlign: 'center',
              title: isMobile ? 'Activation' : 'Activation - PhoneBox',
              headerRight: () => (
                <CustomText
                  onPress={() => {
                    navigation.navigate('Root');
                  }}
                >
                  Skip
                </CustomText>
              ),
            }}
          />
          {/* <Stack.Screen
            name="Welcome"
            component={WelcomeScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Welcome' }}
          />
          <Stack.Screen
            name="OnboardingSimSelect"
            component={OnboardingSelectSimCardScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Onboarding' }}
          />
          <Stack.Screen
            name="OnboardingAddressInfo"
            component={OnboardingAddressScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Onboarding' }}
          />
          <Stack.Screen
            name="OnboardingActivationInfo"
            component={OnboardingActivationInfoScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Onboarding' }}
          />
          <Stack.Screen
            name="OnboardingCart"
            initialParams={{ orderId: 0 }}
            component={OnboardingCartScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Onboarding' }}
          />
          <Stack.Screen
            name="OnboardingPayment"
            initialParams={{ orderId: 0 }}
            component={OnboardingPaymentScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Onboarding' }}
          />
          <Stack.Screen
            name="OnboardingPaymentSuccess"
            component={OnboardingPaymentSuccessScreen}
            options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Success!' }}
          /> */}
        </>
      }
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const { drawerVisible } = useSelector((state: RootState) => state.app);
  const isPrepaid = useSelector((state: RootState) => state.selfServeCA.accountInfoCA.Prepaid);

  return (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: Colors.white, // Selected Button
        tabBarInactiveTintColor: Colors.greyColor, // In Active Button
        // tabBarShowLabel: true,
        headerShown: false,
        tabBarLabelStyle: { fontSize: 10 },
        tabBarStyle: {
          height: 60,
          display: drawerVisible ? 'none' : 'flex',
          backgroundColor: Colors.black,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          paddingBottom: 8,
        },
        tabBarIconStyle: { marginTop: 4 },
      }}
      initialRouteName="Home"
    >
      {isPrepaid ? null : (
        <BottomTab.Screen
          name="Benefits"
          component={BenefitsScreen}
          options={{
            title: 'Benefits',
            tabBarIcon: ({ color, size }) => (
              <FontAwesome6 name="splotch" size={size} color={color} />
            ),
          }}
        />
      )}
      <BottomTab.Screen
        name="Shop"
        component={ShopScreen}
        options={{
          title: 'Shop',
          tabBarIcon: ({ color, size }) => (
            <AntDesign name="shoppingcart" size={size} color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Home',
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="home" color={color} size={size} />
          ),
        }}
      />
      {isPrepaid ? null : (
        <BottomTab.Screen
          name="Billing"
          component={BillingScreen}
          options={{
            title: 'Billing',
            tabBarIcon: ({ color, size }) => <Ionicons name="receipt" size={size} color={color} />,
          }}
        />
      )}
      <BottomTab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: 'Settings',
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="account" color={color} size={size} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}
