import { Image, SafeAreaView, StatusBar, View } from 'react-native';
import { RootStackScreenProps } from '../../types';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { useState } from 'react';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';
import { EvilIcons } from '@expo/vector-icons';
import Button from '../../components/Button';
import { StepType } from '../../types/enums/StepType';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { validate } from '../../utilities/utilities';
import { AppDrawer } from '../../components/AppDrawer';
import HorizontalLine from '../../components/HorizontalLine';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { tokenService } from '../../services/TokenService';
import { resetUser } from '../../store/user';

export default function DeleteAccountScreen({ navigation }: RootStackScreenProps<'DeleteAccount'>) {
  const dispatch = useDispatch();

  const { CurrentBalance } = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const { accounts } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<StepType>(StepType.First);
  const [error, setError] = useState<string | null>(null);

  const [password, setPassword] = useState<string>('');
  const [balanceModelShown, showBalanceModel] = useState<boolean>(false);
  const [confirmModelShown, showConfirmModel] = useState<boolean>(false);

  const onFinalConfirmDelete = async () => {
    setLoading(true);
    try {
      const deleteAccountResponse = await new Promise((resolve) => {
        return resolve('This is Dummy');
      });
      await tokenService.deleteAccessTokenAsync();
      await tokenService.deleteAccessTokenExpiryAsync();
      await tokenService.deleteRefreshTokenAsync();
      await tokenService.deleteRefreshTokenExpiryAsync();
      await tokenService.deleteCASelfServeTokenAsync();
      await tokenService.deleteRememberMe();
      dispatch(resetUser());
      setStep(StepType.Success);
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const stepFirst = (
    <>
      <CustomText
        style={{ fontSize: 24, textAlign: 'center', color: Colors.TextBlack }}
        weight="600"
      >
        Are you sure you want to delete your account?
      </CustomText>
      <View
        style={{
          padding: 16,
          borderRadius: 8,
          borderWidth: 1,
          marginTop: 32,
          borderColor: Colors.activePlanBorderColor,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
          <View
            style={{
              backgroundColor: Colors.errorRed10Percent,
              padding: 4,
              marginRight: 8,
              borderRadius: 10,
            }}
          >
            <EvilIcons name="exclamation" size={16} color={Colors.errorRed} />
          </View>
          <CustomText weight="600" style={{ fontSize: 16 }}>
            Be aware!
          </CustomText>
        </View>
        <CustomText style={{ fontSize: 14 }}>
          Account deletion means deleting the account used to log into the app, not canceling the
          plan. Even if you delete your account, the plan will remain active. To cancel the plan,
          please use the cancel plan menu.
        </CustomText>
      </View>
      <Image
        source={require('../../assets/icons/bell.png')}
        style={{
          width: '100%',
          height: 200,
        }}
        resizeMode="contain"
      />
    </>
  );

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <View
        style={{
          flex: 1,
          height: '100%',
          marginTop: StatusBar.currentHeight,
          backgroundColor: Colors.white,
          padding: 20,
        }}
      >
        {step === StepType.First ? (
          stepFirst
        ) : step === StepType.Second ? (
          <InputWithTitle
            title="Confirm Password"
            inputValue={password}
            secureInput
            onInputChange={setPassword}
          />
        ) : (
          <View>
            <CustomText
              weight="600"
              style={{
                fontSize: 24,
                color: Colors.errorRed,
                textAlign: 'center',
                marginBottom: 10,
              }}
            >
              Account Deleted
            </CustomText>
            <CustomText style={{ fontSize: 14, textAlign: 'center' }}>
              So sad to let you go, See you soon!
            </CustomText>
          </View>
        )}
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <Button
            color={Colors.errorRed}
            style={{ marginTop: 32 }}
            disabled={loading || step === StepType.Second ? !validate('password', password) : false}
            loadingStatus={loading}
            onPress={() => {
              step === StepType.First
                ? setStep(StepType.Second)
                : step === StepType.Second
                ? CurrentBalance
                  ? showBalanceModel(true)
                  : showConfirmModel(true)
                : navigation.navigate('Landing');
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            {step === StepType.First
              ? 'Delete Account'
              : step === StepType.Second
              ? 'Confirm Deletion'
              : 'Explore Main Home'}
          </Button>
          {step !== StepType.Success ? (
            <Button
              style={{ marginTop: 10 }}
              color={Colors.backgroundGrey}
              textColor={Colors.black}
              onPress={() => navigation.goBack()}
            >
              <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
                Cancel
              </CustomText>
            </Button>
          ) : null}
        </View>

        <AppDrawer
          visible={balanceModelShown}
          height={385}
          onClose={() => {
            showBalanceModel(false);
          }}
          showBackDrop
        >
          <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
            There is a remaining balance on your account.
          </CustomText>
          <HorizontalLine thinkness={1} />
          <CustomText style={{ fontSize: 16, marginTop: 16 }}>
            Before you delete your account, please make sure to clear any outstanding negative
            balance.
          </CustomText>
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              showConfirmModel(true);
              showBalanceModel(false);
            }}
            textStyle={{ fontSize: 14 }}
          >
            Clear Balance
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              showBalanceModel(false);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Cancel
          </Button>
        </AppDrawer>
        <AppDrawer
          visible={confirmModelShown}
          height={385}
          onClose={() => {
            showConfirmModel(false);
          }}
          showBackDrop
        >
          <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
            {accounts.length
              ? 'There is an active line under your account.'
              : 'You sure you want to delete your profile'}
          </CustomText>
          <HorizontalLine thinkness={1} />
          <CustomText style={{ fontSize: 16, marginTop: 16 }}>
            {accounts.length
              ? 'Even if you delete your account, the line will remain active and you will continue to be billed.'
              : 'You account will be delete, THIS IS NOT A REVESABLE ACTION'}
          </CustomText>
          <Button
            color={Colors.errorRed}
            style={{ marginTop: 32 }}
            onPress={() => {
              onFinalConfirmDelete();
            }}
            textStyle={{ fontSize: 14 }}
          >
            {accounts.length ? 'Delete Anyway' : 'Delete Account'}
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              showConfirmModel(false);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Cancel
          </Button>
        </AppDrawer>
      </View>
    </>
  );
}
