import { Text, TextStyle } from 'react-native';
import { Colors } from '../../constants/Colors';
import { ReactNode } from 'react';
import CustomText from '../utils/CustomText';

type Props = {
  children: ReactNode;
  style?: TextStyle;
};

const SectionHeading = (props: Props) => {
  return (
    <CustomText
      weight="500"
      style={{
        fontWeight: '500',
        fontSize: 18,
        color: Colors.HomePageHeadingColor,
        marginVertical: 8,
        ...props.style,
      }}
    >
      {props.children}
    </CustomText>
  );
};

export default SectionHeading;
