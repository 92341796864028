import { Image, ScrollView, StyleSheet, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import Button from '../../components/Button';
import { AntDesign } from '@expo/vector-icons';

const PortInDoneView = ({ onPress }: { onPress(option: string): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
        >
          Port In Requested!
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          You can track your progress in the activity tracking section.
        </CustomText>
        <CustomText
          style={{ fontSize: 14, marginTop: 16, textAlign: 'center', color: Colors.TextBlack }}
        >
          Once the process is complete, you can continue using your original number as before.
        </CustomText>
        <View style={style.listItem}>
          <View style={style.listItemIconContainer}>
            <AntDesign name="staro" size={16} color={Colors.ActionLinkColor} />
          </View>
          <View style={{ flex: 1 }}>
            <CustomText weight="500" style={style.listItemText}>
              Next Step
            </CustomText>
            <CustomText style={style.listItemText}>
              Please continue using your SIM card from your previous carrier and make sure to reply
              to the 90-minute SMS from your previous carrier within 90 minutes.
            </CustomText>
          </View>
        </View>
        <Image
          source={require('../../assets/icons/checkmark.png')}
          style={{ width: 150, height: 150, marginHorizontal: 'auto', marginTop: 20 }}
          resizeMode="contain"
        />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => onPress('STATUS')}
          textStyle={{ fontSize: 14 }}
        >
          See The Status
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => onPress('HOME')}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Back To The Main Home
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    padding: 10,
    marginTop: 24,
  },
  listItemIconContainer: {
    backgroundColor: Colors.ActionLinkColorBG,
    display: 'flex',
    width: 26,
    height: 26,
    marginRight: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
  },
  listItemText: { fontSize: 14 },
});

export default PortInDoneView;
