// Persist: https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/#:~:text=Persist%20state%20with%20Redux%20Persist%20using%20Redux%20Toolkit,of%20traditional%20React%20error%20reporting%20with%20LogRocket%20
// https://hybridheroes.de/blog/2021-01-08-redux-toolkit-react-native/

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import appReducer from './app';
import selfServeCAReducer from './selfServeCA';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    selfServeCA: selfServeCAReducer,
    app: appReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // compatibility check for redux-persist
    // see more: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;