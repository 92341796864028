import { View } from 'react-native';
import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';

type Props = {
  title: string;
  supTitle: string;
};

const TitleWithSupTitle = ({ title, supTitle, ...props }: Props) => {
  return (
    <View>
      <CustomText
        style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 4 }}
        weight="500"
      >
        {supTitle}
      </CustomText>
      <CustomText weight="500" style={{ fontSize: 16, color: Colors.TextBlack, marginBottom: 16 }}>
        {title}
      </CustomText>
    </View>
  );
};

export default TitleWithSupTitle;
