import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import ResponsiveWidths from '../constants/ResponsiveWidths';

export default function useHorizontalContainerMargin() {
  const [width, setWidth] = useState(0);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (dimensions.width < ResponsiveWidths.md) {
      setWidth(0)
      return;
    }
    if (dimensions.width < ResponsiveWidths.lg) {
      setWidth(50);
      return;
    }
    if (dimensions.width < ResponsiveWidths.xl) {
      setWidth(130);
      return;
    }
    if (dimensions.width < ResponsiveWidths.xxl) {
      setWidth(220);
      return;
    }
    setWidth(280);

  }, [dimensions.width]);

  return width;
}
