import { Pressable, View } from 'react-native';
import { Colors } from '../../../constants/Colors';
import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import CustomText from '../../utils/CustomText';

type Props = {
  title: string;
  frontIcon: any;
  backIcon: any;
  onPress?: () => void;
};

const MenuItem = ({ title, backIcon, frontIcon, onPress }: Props) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        backgroundColor: Colors.backgroundGrey,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        borderRadius: 8,
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            backgroundColor: Colors.ActionLinkColorBG,
            padding: 4,
            marginRight: 8,
            borderRadius: 10,
          }}
        >
          {frontIcon}
        </View>
        <CustomText>{title}</CustomText>
      </View>
      {backIcon}
    </Pressable>
  );
};

export default MenuItem;
