import { useEffect, useState } from 'react';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import {
  Alert,
  Platform,
  Pressable,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { activationService } from '../../services/MyAccount/ActivationService';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import Badge from '../../components/UI/Badge';
import { Entypo } from '@expo/vector-icons';
import {
  getProratedDates,
  mapBoxOnePostpaidPlans,
  mapBoxOnePrepaidPlans,
} from '../../utilities/utilities';
import { GetPostpaidPlansResponse } from '../../types/responses/MyAccount/GetPostpaidPlansResponse';
import { GetPrepaidPlansResponse } from '../../types/responses/MyAccount/GetPrepaidPlansResponse';
import PlanTypeBadges, { PlanType } from '../../components/UI/PlanTypeBadges';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { AppDrawer } from '../../components/AppDrawer';
import HorizontalLine from '../../components/HorizontalLine';
import Button from '../../components/Button';
import TitleWithSupTitle from '../../components/UI/TitleWithSupTitle';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { accountService } from '../../services/MyAccount/AccountService';
import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_FOR_API } from '../../constants/constants';

export default function ChangePlanScreen({ navigation }: RootStackScreenProps<'ChangePlan'>) {
  const {
    accountInfoCA: { Prepaid: isPrepaid, CurrentPlan_pfId, EndDate },
    accountProfileCA: { Suspended, SuspensionReason },
  } = useSelector((state: RootState) => state.selfServeCA);

  const [plans, setPlans] = useState<ParsedPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPlan, setSelectedPlan] = useState<ParsedPlan | null>(null);
  const [planDetailModelShown, setPlanDetailModelShown] = useState(false);

  const { upcomingSecond } = getProratedDates(new Date());

  const effectiveDate = isPrepaid
    ? moment(EndDate).add(1, 'days').format(DATE_FORMAT)
    : upcomingSecond.format(DATE_FORMAT);

  const [error, setError] = useState<string | null>(null);

  const getPlansAsync = async () => {
    setLoading(true);
    try {
      if (isPrepaid) {
        const { data: plans } = await activationService.getPrepaidPlans({
          data: { carrier: '', promo_code: '', currency: 'CAD' },
        });
        setPlans(mapBoxOnePrepaidPlans(plans as GetPrepaidPlansResponse));
      } else {
        const { data: plans } = await activationService.getPostpaidPlans({
          data: { carrier: '', promo_code: '', currency: 'CAD' },
        });
        setPlans(
          mapBoxOnePostpaidPlans(
            (plans as GetPostpaidPlansResponse).filter((item) => item.pfId !== CurrentPlan_pfId)
          )
        );
      }
    } catch (err) {
      setError('Unable to fetch plans, please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlansAsync().catch((err) => console.error(err));
  }, []);

  const changePlan = async () => {
    try {
      setLoading(true);
      const { data } = await accountService.getStatus({});
      if (Suspended && SuspensionReason === 'VC') {
        Platform.OS === 'web'
          ? alert('Sorry, you cannot change your plan because you are on vacation plan now.')
          : Alert.alert(
              'Error!',
              'Sorry, you cannot change your plan because you are on vacation plan now.'
            );
        return;
      }
      if (Suspended && SuspensionReason === 'LS') {
        Platform.OS === 'web'
          ? alert(
              'Sorry, you cannot change your plan because your plan is suspended now due to reported Lost/Stolen.'
            )
          : Alert.alert(
              'Error!',
              'Sorry, you cannot change your plan because your plan is suspended now due to reported Lost/Stolen.'
            );
        return;
      }
      if (
        data.PlanChangeStatus === 'Requested' ||
        data.SuspensionStatus === 'Requested' ||
        data.TerminationStatus === 'Requested'
      ) {
        Platform.OS === 'web'
          ? alert(
              'Sorry, you cannot change your plan due to a pending suspension/plan change/termination request.'
            )
          : Alert.alert(
              'Error!',
              'Sorry, you cannot change your plan due to a pending suspension/plan change/termination request.'
            );
        return;
      }

      const response = await accountService.changePlan({
        effDate: moment(effectiveDate).format(DATE_FORMAT_FOR_API),
        pfid: selectedPlan?.planId!,
        reason: '',
      });

      if (response.data[9] === 'I') {
        Platform.OS === 'web'
          ? alert('Sorry, You can not change the plan today.')
          : Alert.alert('Error', 'Sorry, You can not change the plan today.');
      }
      // "Failed: Request duplicating."
      else if (response.data[9] === 'R') {
        Platform.OS === 'web'
          ? alert(
              'You have already requested to change your plan. To make changes to this request, please contact our customer service.'
            )
          : Alert.alert(
              'Error',
              'You have already requested to change your plan. To make changes to this request, please contact our customer service.'
            );
      } else {
        navigation.navigate('ChangePlanSuccess');
      }

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const Plan = ({ plan }: { plan: ParsedPlan }) => {
    return (
      <Pressable
        style={{
          padding: 16,
          borderWidth: 1,
          borderColor: Colors.activePlanBorderColor,
          borderRadius: 12,
          backgroundColor:
            selectedPlan && selectedPlan.planId === plan.planId
              ? Colors.ActionLinkColorBG
              : Colors.noColor,
        }}
        onPress={() => {
          setSelectedPlan((selectedPlan) => (selectedPlan?.planId === plan.planId ? null : plan));
          setPlanDetailModelShown(true);
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 14,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Entypo name="mobile" size={24} color={Colors.canadaButtonColor} />
            <CustomText
              weight="500"
              style={{ fontSize: 12, color: Colors.QuickActionTextColor, marginLeft: 8 }}
            >
              Canadian Mobile Plan
            </CustomText>
          </View>
          <Badge
            title={plan.planType === 'monthly' ? 'Monthly' : `${plan.planTypeD} Days`}
            status="light-blue"
            fontweight="600"
          />
        </View>
        <View
          style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <CustomText weight="500" style={{ fontSize: 20, color: Colors.canadaButtonColor }}>
            {plan.data}
          </CustomText>
          <CustomText weight="600" style={{ fontSize: 20 }}>
            ${plan.plan_fee.toFixed(2)}
          </CustomText>
        </View>
      </Pressable>
    );
  };

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <SafeAreaView style={{ flex: 1, height: '100%' }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            padding: 20,
            backgroundColor: Colors.white,
          }}
          refreshControl={<RefreshControl refreshing={false} onRefresh={getPlansAsync} />}
        >
          <PlanTypeBadges
            showOnly={isPrepaid ? 'prepaid' : 'monthly'}
            setSelected={() => {}}
            selected={isPrepaid ? 'prepaid' : 'monthly'}
          />
          <View style={{ marginVertical: 16, gap: 16 }}>
            {plans.map((plan) => (
              <Plan plan={plan} key={plan.planId} />
            ))}
          </View>
        </ScrollView>
        <AppDrawer
          visible={selectedPlan !== null && planDetailModelShown}
          height={580}
          onClose={() => {
            setPlanDetailModelShown(false);
            setSelectedPlan(null);
          }}
          showBackDrop
        >
          <CustomText weight="500" style={{ fontSize: 24, marginBottom: 10 }}>
            Plan Details
          </CustomText>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <CustomText weight="600" style={{ fontSize: 24, color: Colors.canadaButtonColor }}>
              {selectedPlan?.data}
            </CustomText>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Badge title="CAD" status="light-blue" />
              <CustomText weight="500" style={{ fontSize: 16, marginLeft: 8 }}>
                ${selectedPlan?.plan_fee.toFixed(2)}
              </CustomText>
              <CustomText style={{ fontSize: 12 }}>
                /
                {selectedPlan?.planType === 'monthly' ? 'Month' : selectedPlan?.planTypeD + ' Days'}
              </CustomText>
            </View>
          </View>
          <HorizontalLine thinkness={1} />
          <View>
            <CustomText weight="500" style={styles.planDetailTitle}>
              Call & Text
            </CustomText>
            <CustomText style={styles.planDetailInfo}>Unlimited Canada wide Call & Text</CustomText>
            <CustomText style={styles.planDetailInfo}>Unlimited International Text</CustomText>
            <CustomText style={styles.planDetailInfo}>
              Up to 1,000 mins of International Calls
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: 150 }}>
              <CustomText weight="500" style={styles.planDetailTitle}>
                Data
              </CustomText>
              <CustomText style={styles.planDetailInfo}>{selectedPlan?.data}</CustomText>
              <CustomText style={styles.planDetailInfo}>4G LTE/5G Speed</CustomText>
            </View>
            <View style={{ width: 150 }}>
              <CustomText weight="500" style={styles.planDetailTitle}>
                Additional Feature
              </CustomText>
              <CustomText style={styles.planDetailInfo}>Regular SIM</CustomText>
              <CustomText style={styles.planDetailInfo}>Voicemail (35 mins)</CustomText>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: 150 }}>
              <CustomText weight="500" style={styles.planDetailTitle}>
                Network
              </CustomText>
              <CustomText style={styles.planDetailInfo}>{selectedPlan?.carrier}</CustomText>
            </View>
            <View style={{ width: 150 }}>
              <CustomText weight="500" style={styles.planDetailTitle}>
                Data Overage Fee
              </CustomText>
              <CustomText style={styles.planDetailInfo}>$15.00 per 1GB</CustomText>
            </View>
          </View>

          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              if (Platform.OS === 'web') {
                if (
                  confirm(
                    ` Are you sure you want to change your plan? Your effective date is ${effectiveDate}.`
                  )
                ) {
                  isPrepaid
                    ? navigation.navigate('PrepaidPlanCheckout', {
                        planId: selectedPlan?.planId!,
                      })
                    : changePlan();
                }
              } else {
                Alert.alert(
                  'Confirmation',
                  `Are you sure you want to change your plan? Your effective date is ${effectiveDate}.`,
                  [
                    {
                      text: 'Confirm',
                      onPress: () => {
                        isPrepaid
                          ? navigation.navigate('PrepaidPlanCheckout', {
                              planId: selectedPlan?.planId!,
                            })
                          : changePlan();
                      },
                      isPreferred: true,
                    },
                    {
                      text: 'Cancel',
                    },
                  ]
                );
              }
            }}
            textStyle={{ fontSize: 14 }}
          >
            Select this plan
          </Button>
          <Button
            style={{ marginTop: 16 }}
            color={Colors.backgroundGrey}
            textColor={Colors.inputTextPlaceholderColor}
            onPress={() => {
              setPlanDetailModelShown(false);
              setSelectedPlan(null);
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Select other plan
          </Button>
        </AppDrawer>
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  planDetailTitle: {
    fontSize: 12,
    color: Colors.inputTextPlaceholderColor,
    marginTop: 16,
    marginBottom: 8,
  },
  planDetailInfo: { fontSize: 14 },
});
