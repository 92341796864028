import { RefreshControl, SafeAreaView, ScrollView, View } from 'react-native';

import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import Badge from '../../components/UI/Badge';
import StatusTrackingListItem from '../../components/UI/ListItem/StatusTrackingListItem';
import { AppDrawer } from '../../components/AppDrawer';
import { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Octicons } from '@expo/vector-icons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../types';
import HorizontalLine from '../../components/HorizontalLine';
import { ShippingStatuses } from '../../constants/constants';
import FilterChip from '../../components/UI/FilterChip';
import Button from '../../components/Button';
import { ShippingStatus } from '../../types/types';
import { accountService } from '../../services/MyAccount/AccountService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  setCustomerServiceRequests,
  setSelectedCustomerServiceRequests,
} from '../../store/selfServeCA';
import { GetCustomerServiceRequestsResponse } from '../../types/responses/MyAccount/GetCustomerServiceRequestsResponse';
import ServiceRequestListItem from '../../components/UI/ListItem/ServiceRequestListItem';

const ServiceRequestTab = ({
  navigation,
}: {
  navigation: NativeStackNavigationProp<RootStackParamList, 'StatusTracking', undefined>;
}) => {
  const dispatch = useDispatch();

  const userEmail = useSelector((state: RootState) => state.user.email);
  const { serviceRequests } = useSelector((state: RootState) => ({
    serviceRequests: state.selfServeCA.serviceRequests,
  }));

  const fetchOrders = async () => {
    const response = await accountService.getCustomerServiceRequests(userEmail);
    dispatch(setCustomerServiceRequests(response.data));
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: Colors.white, height: '100%', flex: 1 }}>
      <CustomText
        weight="500"
        style={{ fontSize: 12, color: Colors.greyColor, marginVertical: 16, marginHorizontal: 20 }}
      >
        Your Request
      </CustomText>
      <ScrollView refreshControl={<RefreshControl refreshing={false} onRefresh={fetchOrders} />}>
        {serviceRequests.map((serviceRequest, index) => (
          <ServiceRequestListItem
            key={`${serviceRequest.requestId}-${index}`}
            title={serviceRequest.requestType}
            date={serviceRequest.RequestTime}
            activationDate={
              serviceRequest.requestType === 'ACTIVATION' ? serviceRequest.StartDate : null
            }
            status={serviceRequest.status!}
            isEven={(index + 1) % 2 === 0}
            onPress={() => {
              dispatch(setSelectedCustomerServiceRequests(serviceRequest));
              navigation.push('ServiceRequestTracking', {
                tracking: serviceRequest.requestId!,
                type: serviceRequest.requestType,
              });
            }}
          />
        ))}
      </ScrollView>
    </SafeAreaView>
  );
};

export default ServiceRequestTab;
