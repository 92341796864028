import { ImageBackground, Pressable, View } from 'react-native';
import CustomText from '../../utils/CustomText';
import { Colors } from '../../../constants/Colors';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const ExploreProductHero = () => {
  return (
    <View
      style={{
        borderRadius: 20,
        overflow: 'hidden',
        marginHorizontal: 20,
        marginVertical: 20,
      }}
    >
      <ImageBackground
        source={require('../../../assets/backgrounds/hero-bg.png')}
        resizeMode="cover"
        style={{ height: 300, justifyContent: 'space-between' }}
      >
        <View style={{ marginTop: 20, alignItems: 'center' }}>
          <CustomText
            weight="600"
            style={{
              fontSize: 28,
              fontWeight: '600',
              textAlign: 'center',
              marginBottom: 6,
            }}
          >
            Shop Products
          </CustomText>
          <CustomText
            style={{
              fontSize: 14,
              fontWeight: '400',
              textAlign: 'center',
              color: Colors.inputTextPlaceholderColor,
            }}
          >
            Explore High speed home internet
          </CustomText>
          <CustomText
            style={{
              fontSize: 12,
              fontWeight: '400',
              textAlign: 'center',
              color: Colors.white,
              backgroundColor: Colors.canadaButtonColor,
              borderRadius: 20,
              paddingVertical: 4,
              paddingHorizontal: 10,
              marginTop: 20,
            }}
          >
            Discount 10%
          </CustomText>
        </View>
        <Pressable
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            marginBottom: 15,
          }}
          onPress={() => {
            alert('Pressed');
          }}
        >
          <CustomText
            weight="600"
            style={{
              fontSize: 16,
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            Explore more products
          </CustomText>
          <MaterialCommunityIcons size={20} color={Colors.black} name="arrow-right" />
        </Pressable>
      </ImageBackground>
    </View>
  );
};

export default ExploreProductHero;
