import { instance } from "../../api/axios";
import { ApiRequest } from "../../types/requests/ApiRequest";
import { ApiResponse } from "../../types/responses/ApiResponse";
import { GetPlansRequest } from "../../types/requests/MyAccount/GetPlansRequest";
import { GetPostpaidPlansResponse } from "../../types/responses/MyAccount/GetPostpaidPlansResponse";
import { GetPrepaidPlansResponse } from "../../types/responses/MyAccount/GetPrepaidPlansResponse";
import { GetTotalPrepaidRequest } from "../../types/requests/MyAccount/GetTotalPrepaidRequest";
import { GetTotalPrepaidResponse } from "../../types/responses/MyAccount/GetTotalPrepaidResponse";
import { GetTotalPostpaidRequest } from "../../types/requests/MyAccount/GetTotalPostpaidRequest";
import { GetTotalPostpaidResponse } from "../../types/responses/MyAccount/GetTotalPostpaidResponse";
import { PostActivationInfoPostpaidRequest } from "../../types/requests/MyAccount/PostActivationInfoPostpaidRequest";
import { PostActivationInfoPostpaidResponse } from "../../types/responses/MyAccount/PostActivationInfoPostpaidResponse";
import { PostActivationInfoPrepaidRequest } from "../../types/requests/MyAccount/PostActivationInfoPrepaidRequest";
import { PostActivationInfoPrepaidResponse } from "../../types/responses/MyAccount/PostActivationInfoPrepaidResponse";
import { PostpaidPlanByIdRequest } from "../../types/requests/MyAccount/PostpaidPlanByIdRequest";
import { PostpaidPlanByIdResponse } from "../../types/responses/MyAccount/PostpaidPlanByIdResponse";
import { GetCountriesRequest } from "../../types/requests/MyAccount/GetCountriesRequest";
import { GetCountriesResponse } from "../../types/responses/MyAccount/GetCountriesResponse";
import { GetShippingOptionsRequest } from "../../types/requests/MyAccount/GetShippingOptionsRequest";
import { GetShippingOptionsResponse } from "../../types/responses/MyAccount/GetShippingOptionsResponse";
import { PostpaidActivationBillingRequest } from "../../types/requests/MyAccount/PostpaidActivationBillingRequest";
import { PostpaidActivationBillingResponse } from "../../types/responses/MyAccount/PostpaidActivationBillingResponse";
import { PrepaidActivationBillingRequest } from "../../types/requests/MyAccount/PrepaidActivationBillingRequest";
import { PrepaidActivationBillingResponse } from "../../types/responses/MyAccount/PrepaidActivationBillingResponse";
import { VerifySimCardRequest } from "../../types/requests/MyAccount/RequestsType";
import { VerifySimCardResponse } from "../../types/responses/MyAccount/ResponsesType";

export const activationService = {
  getPrepaidPlans: async (request: ApiRequest<GetPlansRequest>): Promise<ApiResponse<GetPrepaidPlansResponse>> => await instance.post<ApiRequest<GetPlansRequest>, ApiResponse<GetPrepaidPlansResponse>>("/Activation/PrepaidPlans", null, request),
  getPostpaidPlans: async (request: ApiRequest<GetPlansRequest>): Promise<ApiResponse<GetPostpaidPlansResponse>> => await instance.post<ApiRequest<GetPlansRequest>, ApiResponse<GetPostpaidPlansResponse>>("/Activation/PostpaidPlans", null, request),
  getTotalPrepaid: async (request: ApiRequest<GetTotalPrepaidRequest>): Promise<ApiResponse<[GetTotalPrepaidResponse]>> => await instance.get<ApiRequest<GetTotalPrepaidRequest>, ApiResponse<[GetTotalPrepaidResponse]>>("/Activation/GetTotalPrepaid", { params: request.data }),
  getTotalPostpaid: async (request: ApiRequest<GetTotalPostpaidRequest>): Promise<ApiResponse<[GetTotalPostpaidResponse]>> => await instance.get<ApiRequest<GetTotalPostpaidRequest>, ApiResponse<[GetTotalPostpaidResponse]>>("/Activation/GetTotalPostpaid", { params: request.data }),
  postActivationInfoPostpaid: async (request: ApiRequest<PostActivationInfoPostpaidRequest>): Promise<ApiResponse<PostActivationInfoPostpaidResponse>> => await instance.post<ApiRequest<PostActivationInfoPostpaidRequest>, ApiResponse<PostActivationInfoPostpaidResponse>>("/Activation/PostActivationInfoPostpaid", request.data),
  postActivationInfoPrepaid: async (request: ApiRequest<PostActivationInfoPrepaidRequest>): Promise<ApiResponse<PostActivationInfoPrepaidResponse>> => await instance.post<ApiRequest<PostActivationInfoPrepaidRequest>, ApiResponse<PostActivationInfoPrepaidResponse>>("/Activation/PostActivationInfoPrepaid", request.data),
  postpaidPlanById: async (request: ApiRequest<PostpaidPlanByIdRequest>): Promise<ApiResponse<PostpaidPlanByIdResponse>> => await instance.post<ApiRequest<PostpaidPlanByIdRequest>, ApiResponse<PostpaidPlanByIdResponse>>("/Activation/PostpaidPlanById?PlanId=" + request.data.PlanId),
  getCountries: async (): Promise<ApiResponse<GetCountriesResponse>> => await instance.get<GetCountriesRequest, ApiResponse<GetCountriesResponse>>("/Activation/GetCountries", {}),
  getShippingOptions: async (request: ApiRequest<GetShippingOptionsRequest>): Promise<ApiResponse<GetShippingOptionsResponse>> => await instance.get<ApiRequest<GetShippingOptionsRequest>, ApiResponse<GetShippingOptionsResponse>>("/Activation/GetShippingOptions?countryId=" + request.data.countryId),
  postpaidActivationBilling: async (request: ApiRequest<PostpaidActivationBillingRequest>): Promise<ApiResponse<PostpaidActivationBillingResponse>> => await instance.post<ApiRequest<PostpaidActivationBillingRequest>, ApiResponse<PostpaidActivationBillingResponse>>(`/Activation/PostpaidActivationBilling?billtype=${request.data.billtype}&return_url=${request.data.return_url}&cancel_url=${request.data.cancel_url}`, {}, { headers: request.data.headers }),
  prepaidActivationBilling: async (request: ApiRequest<PrepaidActivationBillingRequest>): Promise<ApiResponse<PrepaidActivationBillingResponse>> => await instance.post<ApiRequest<PrepaidActivationBillingRequest>, ApiResponse<PrepaidActivationBillingResponse>>(`/Activation/PrepaidActivationBilling?billtype=${request.data.billtype}&return_url=${request.data.return_url}&cancel_url=${request.data.cancel_url}&currency=${request.data.currency}`, {}, { headers: request.data.headers }),

  // New APIs

  prepaidPlanById: async (request: ApiRequest<{ PlanId: number }>): Promise<ApiResponse<GetPrepaidPlansResponse>> => await instance.post<ApiRequest<{ PlanId: number }>, ApiResponse<GetPrepaidPlansResponse>>("/Activation/PrepaidPlanById", null, {
    params: request.data
  }),
  verifySimCard: async (request: ApiRequest<VerifySimCardRequest>): Promise<ApiResponse<VerifySimCardResponse>> => await instance.post<ApiRequest<VerifySimCardRequest>, ApiResponse<VerifySimCardResponse>>(`/Activation/VerifySimcard`, {}, {
    params: request.data
  }),
}