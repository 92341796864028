import { View, StyleSheet, Text, Pressable } from "react-native";
import { useState } from "react";
import { AppTextInput } from "../components/AppTextInput";
import { AppButton } from "../components/AppButton";
import CommonStyles from "../constants/CommonStyles";
import useIsMobile from "../hooks/useIsMobile";
import { Colors } from "../constants/Colors";
import { authService } from "../services/SSO/AuthService";
import { SystemCodes } from "../types/enums/SystemCodes";
import { DataErrorType } from "../types/enums/DataErrorType";
import { StepType } from "../types/enums/StepType";
import { AppVerificationCodeInput } from "../components/AppVerificationCodeInput";
import { RootStackScreenProps } from "../types";
import AppContainer from "../components/AppContainer";
import AppHeader from "../components/AppHeader";
import { DevMessage } from "../types/enums/DevMessage";

export default function RecoverYourPasswordScreen({ navigation }: RootStackScreenProps<"RecoverPassword">) {
  const isMobile = useIsMobile();
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>([]);
  const [otpError, setOtpError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(StepType.First);
  const [verificationCode, setVerificationCode] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [retypedPassword, setRetypedPassword] = useState("");
  const disabledConfirmButton = verificationCode.length < 6;
  const disabledResetPasswordButton = !retypedPassword || !password;

  const onPressSend = () => {
    if (!email) {
      return;
    }
    
    const forgotPassword = async () => {
      setLoading(true);
      const forgotPasswordResponse = await authService.forgotPassword({
        data: {
          email: email,
          systemCode: SystemCodes.CA_SELFSERVE
        }
      })

      if (!forgotPasswordResponse || !forgotPasswordResponse.data.success) {
        if (forgotPasswordResponse.data && forgotPasswordResponse.data.error && forgotPasswordResponse.data.error.message) {
          setError(forgotPasswordResponse.data.error.message);
        }
      }

      if (forgotPasswordResponse && forgotPasswordResponse.data.success) {
        setError("");
        setEmailErrors([]);
        setStep(StepType.Second);
        setUserId(forgotPasswordResponse.data.data.userId);
      }
    }

    forgotPassword().catch(err => {
      if (err && err.response && err.response.data && err.response.data.errors) {
        if (err.response.data.errors[DataErrorType.Email]) {
          setEmailErrors(err.response.data.errors[DataErrorType.Email]);
        }
      }
      console.error(err);
    }).finally(() => {
      setLoading(false);
    })
  }

  const onPressConfirm = () => {
    if (!verificationCode) {
      return;
    }

    setLoading(true);

    const validateOtpForResetPasswordAsync = async () => {
      const validateOtpForResetPasswordResponse = await authService.validateOtpForResetPassword({
        data: {
          userId: userId,
          otpCode: verificationCode,
          systemCode: SystemCodes.CA_SELFSERVE
        }
      });

      if (!validateOtpForResetPasswordResponse || !validateOtpForResetPasswordResponse.data?.success) {
        if (validateOtpForResetPasswordResponse?.data?.devMessage && validateOtpForResetPasswordResponse.data.devMessage === DevMessage.InvalidOTP) {
          setOtpError("Invalid code. Please try again.");
        }
        return;
      }

      if (validateOtpForResetPasswordResponse.data.success) {
        setOtpError("");
        setStep(StepType.Third);
      }
    }

    validateOtpForResetPasswordAsync().catch(err => { 
      console.error(err)
    }).finally(() => {
      setLoading(false);
    })
  }

  const onPressResetPassword = () => {

    if (!password || !retypedPassword) {
      return;
    }

    if (password !== retypedPassword) {
      setNewPasswordErrors(["Passwords must match."]);
      return;
    }

    const resetPasswordAsync = async () => {
      setLoading(true);

      const resetPasswordResponse = await authService.resetPassword({
        data: {
          userId: userId,
          newPassword: password,
          otpCode: verificationCode,
          systemCode: SystemCodes.CA_SELFSERVE
        }
      });

      if (!resetPasswordResponse || !resetPasswordResponse.data?.success) {
        console.log("Something went wrong with resetPasswordAsync(): ", resetPasswordResponse);
        return;
      }

      if (resetPasswordResponse?.data?.success) {
        setStep(StepType.Success);
      }
    }


    resetPasswordAsync().catch(err => {
      console.error(err);
      if (err && err.response && err.response.data && err.response.data.errors) {
        if (err.response.data.errors[DataErrorType.NewPassword]) {
          setNewPasswordErrors(err.response.data.errors[DataErrorType.NewPassword]);
        }
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const onPressBackToLogIn = () => {
    navigation.navigate("LogIn");
    setStep(StepType.First);
  }

  const onPressBack = () => {
    if (step === StepType.First) {
      navigation.goBack();
    } else {
      let prevStep = StepType.previous(step);
      setStep(prevStep);
    }
  }

  return (
    <>
      {isMobile &&
        <AppContainer>
          <AppHeader onPressBack={onPressBack} />
          {step === StepType.First &&
            <>
              <Text style={CommonStyles.title}>Recover Your Account</Text>
              <View style={styles.marginTop}>
                <AppTextInput onChangeText={(text) => setEmail(text)} placeholder="Your email"/>
              </View>
              <View style={styles.marginTop}>
                <AppButton onPress={onPressSend} disabled={!email} text="Send" loading={loading}/>
              </View>
              <View style={styles.marginTop}>
                {emailErrors.map((err, i) => {
                  return (
                    <View key={i}>
                      <Text style={CommonStyles.errorText}>{err}</Text>
                    </View>
                  )
                })}
                {error.length > 0 &&
                  <View>
                    <Text style={CommonStyles.errorText}>{error}</Text>
                  </View>
                }
              </View>
            </>
          }
          {step === StepType.Second &&
            <>
              <Text style={CommonStyles.title}>Verification</Text>
              <Text style={styles.marginTop}>Please enter the verification code you received at {email}.</Text>
              <AppVerificationCodeInput onChangeText={(text) => setVerificationCode(text)}/>
              <View style={{ ...styles.marginTop, ...CommonStyles.justifyRow, marginHorizontal: "auto" }}>
                <Text>Didn't receive a code?</Text>
                <Pressable onPress={onPressSend} style={{ paddingLeft: 4 }}>
                  <Text style={{ color: Colors.green }}>Resend now.</Text>
                </Pressable>
              </View>
              <View style={styles.marginTop}>
                <AppButton onPress={onPressConfirm} text="Confirm" loading={loading} disabled={disabledConfirmButton}/>
              </View>
              <View style={styles.marginTop}>
                {otpError.length > 0 &&
                  <View>
                    <Text style={CommonStyles.errorText}>{otpError}</Text>
                  </View>
                }
              </View>
            </>
          }
          {step === StepType.Third &&
            <>
              <Text style={styles.marginTop}>Enter your new password below.</Text>
              <View style={styles.marginTop}>
                <AppTextInput onChangeText={(text) => setPassword(text)} placeholder="New password" secureTextEntry/>
              </View>
              <View style={styles.inputContainer}>
                <AppTextInput onChangeText={(text) => setRetypedPassword(text)} placeholder="Retype password" secureTextEntry/>
              </View>
              <View style={styles.marginTop}>
                <AppButton onPress={onPressResetPassword} text="Reset Password" loading={loading} disabled={disabledResetPasswordButton}/>
              </View>
              <View style={styles.marginTop}>
                {newPasswordErrors.map((err, i) => {
                  return (
                    <View key={i}>
                      <Text style={CommonStyles.errorText}>{err}</Text>
                    </View>
                  )
                })}
              </View>
            </>
          }
          {step === StepType.Success &&
            <>
              <Text style={CommonStyles.title}>Success!</Text>
              <Text style={styles.marginTop}>Your password has successfully been reset.</Text>
              <View style={styles.marginTop}>
                <AppButton onPress={onPressBackToLogIn} text="Back to Log In"/>
              </View>
            </>
          }
        </AppContainer>
      }
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25
  },
  inputContainer: {
    marginTop: 25,
    marginBottom: 25
  }
});