import { View, SafeAreaView, ScrollView, Dimensions } from 'react-native';
import { ReactNode } from 'react';

interface AppContainerProps {
  children: ReactNode;
  minHeight?: number;
}

export default function ScrollViewWithSafeAreaContainer(props: AppContainerProps) {
  const { width, height } = Dimensions.get('window');

  return (
      <ScrollView style={{ width: width, height: '100%', flex: 1 }}>
        <View
          style={{ display: 'flex', minHeight: props.minHeight ?? 812, height: height }}
        >
          {props.children}
        </View>
      </ScrollView>
  );
}
