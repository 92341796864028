import { SafeAreaView, ScrollView } from 'react-native';
import { Colors } from '../../constants/Colors';
import InitialView from './InitialView';
import { ImageBackground, Pressable, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Feather, MaterialIcons, Octicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import { AppDrawer } from '../../components/AppDrawer';
import { useState } from 'react';
import AddPaymentMethodView, { BankPayment, CardPayment } from './AddPaymentMethodView';

export enum FlowStep {
  Initial = 'initial',
  AddPaymentCard = 'add-payment-card',
  EditPaymentCard = 'edit-payment-card',
  AddPaymentBank = 'add-payment-bank',
  EditPaymentBank = 'edit-payment-bank',
}

const ManagePaymentScreen = () => {
  const { CreditCardExpirationMonth, CreditCardExpirationYear, CreditCardLast4Digits } =
    useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  const isValidCardPayment =
    CreditCardLast4Digits && CreditCardExpirationYear && CreditCardExpirationMonth;
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showDeletePaymentModal, setShowDeletePaymentModal] = useState(false);

  const [step, setStep] = useState<FlowStep>(FlowStep.Initial);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          marginBottom: 150,
          flex: 1,
          height: '100%',
        }}
      >
        {step === FlowStep.Initial ? (
          <InitialView
            onAction={(action) => {
              switch (action) {
                case 'SHOW_DELETE_MODAL':
                  setShowAddPaymentModal(true);
                  break;
                case 'DELETE_CREDIT':
                case 'DELETE_BANK':
                  setShowDeletePaymentModal(true);
                  break;
                case 'ADD_BANK':
                  setStep(FlowStep.AddPaymentBank);
                  break;
                case 'ADD_CREDIT':
                  setStep(FlowStep.AddPaymentCard);
                  break;
                case 'EDIT_BANK':
                  setStep(FlowStep.EditPaymentBank);
                  break;
                case 'EDIT_CREDIT':
                  setStep(FlowStep.EditPaymentCard);
                  break;
                default:
                  break;
              }
              console.log(action);
            }}
          />
        ) : (
          <AddPaymentMethodView
            step={step}
            onAction={(action, _data) => {
              let data = _data;
              if (action === 'SAVE_CARD') {
                data = _data as CardPayment;
              } else if (action === 'SAVE_BANK') {
                data = _data as BankPayment;
              } else {
                setStep(FlowStep.Initial);
                return;
              }

              console.log({ action, data });
            }}
          />
        )}
      </ScrollView>
      <AppDrawer
        visible={showAddPaymentModal}
        height={350}
        onClose={() => {
          setShowAddPaymentModal(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Please delete card information first
        </CustomText>
        <CustomText style={{ fontSize: 16, marginTop: 16 }}>
          {isValidCardPayment
            ? 'If you would like to register bank account as a default payment. Please delete registered card information first.'
            : 'If you would like to register credit card as a default payment. Please delete registered bank account information first.'}
        </CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setShowAddPaymentModal(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Back
        </Button>
      </AppDrawer>
      <AppDrawer
        visible={showDeletePaymentModal}
        height={400}
        onClose={() => {
          setShowDeletePaymentModal(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Delete Information
        </CustomText>
        <CustomText style={{ fontSize: 16, marginTop: 16 }}>
          Once information is deleted, it can't be recovered. Also, You can not get auto extension
          benefit.
        </CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />

        <Button
          style={{ marginTop: 16 }}
          color={Colors.errorRed}
          textColor={Colors.white}
          onPress={() => {
            setShowDeletePaymentModal(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Delete Information
        </Button>
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setShowDeletePaymentModal(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Cancel
        </Button>
      </AppDrawer>
    </SafeAreaView>
  );
};

export default ManagePaymentScreen;
