import { ImageBackground, Pressable, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';
import { Feather, MaterialIcons, Octicons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Button from '../../components/Button';
import HorizontalLine from '../../components/HorizontalLine';
import { AppDrawer } from '../../components/AppDrawer';
import { useState } from 'react';

const InitialView = ({
  onAction,
}: {
  onAction(
    action:
      | 'ADD_CREDIT'
      | 'EDIT_CREDIT'
      | 'ADD_BANK'
      | 'EDIT_BANK'
      | 'SHOW_DELETE_MODAL'
      | 'DELETE_CREDIT'
      | 'DELETE_BANK'
  ): void;
}) => {
  const {
    CreditCardExpirationMonth,
    CreditCardExpirationYear,
    CreditCardHolderName,
    CreditCardLast4Digits,
    CreditCardType,
    AccountNumberLast4Digits,
    BankInstitution,
    TransitNumber,
  } = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  const isValidCardPayment =
    CreditCardLast4Digits && CreditCardExpirationYear && CreditCardExpirationMonth;
  const isValidBankPayment = AccountNumberLast4Digits && BankInstitution && TransitNumber;

  return (
    <>
      <CustomText weight="600" style={{ fontSize: 14, color: Colors.greyColor }}>
        Payment Methods
      </CustomText>
      {isValidCardPayment ? (
        <>
          <View
            style={{
              borderRadius: 8,
              marginTop: 16,
            }}
          >
            <ImageBackground
              source={require('../../assets/backgrounds/card-bg.png')}
              style={{
                width: 335,
                height: 220,
                position: 'relative',
              }}
              resizeMode="contain"
            >
              <CustomText
                weight="600"
                style={{
                  color: Colors.white,
                  position: 'absolute',
                  top: '37%',
                  left: 28,
                  fontSize: 24,
                }}
              >
                **** **** **** {CreditCardLast4Digits}
              </CustomText>
              <View
                style={{
                  position: 'absolute',
                  bottom: 28,
                  left: 28,
                  flexDirection: 'row',
                  gap: 24,
                }}
              >
                <View>
                  <CustomText weight="400" style={{ fontSize: 8, color: Colors.white }}>
                    Card Holder name
                  </CustomText>
                  <CustomText
                    weight="600"
                    style={{ fontSize: 14, color: Colors.white, maxWidth: 150 }}
                  >
                    {CreditCardHolderName}
                  </CustomText>
                </View>
                <View>
                  <CustomText weight="400" style={{ fontSize: 8, color: Colors.white }}>
                    Expiry Date
                  </CustomText>
                  <CustomText weight="600" style={{ fontSize: 14, color: Colors.white }}>
                    {CreditCardExpirationMonth}/{CreditCardExpirationYear.slice(-2)}
                  </CustomText>
                </View>
                <View>
                  <CustomText weight="400" style={{ fontSize: 8, color: Colors.white }}>
                    Type
                  </CustomText>
                  <CustomText weight="600" style={{ fontSize: 14, color: Colors.white }}>
                    {CreditCardType === 'AmericanExpress' ? 'AMEX' : CreditCardType}
                  </CustomText>
                </View>
              </View>
            </ImageBackground>
          </View>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              justifyContent: 'center',
              marginTop: 28,
            }}
          >
            <Button
              style={{ backgroundColor: Colors.backgroundGrey, width: '50%' }}
              onPress={() => onAction('DELETE_CREDIT')}
            >
              <MaterialIcons name="delete-outline" size={16} color={Colors.red} />
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.red, marginLeft: 8 }}>
                Delete Card
              </CustomText>
            </Button>
            <Button style={{ width: '50%' }} onPress={() => onAction('EDIT_CREDIT')}>
              <Octicons name="pencil" size={16} color={Colors.white} />
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.white, marginLeft: 8 }}>
                Change Card
              </CustomText>
            </Button>
          </View>
        </>
      ) : isValidBankPayment ? (
        <>
          <CustomText>Bank Account</CustomText>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              justifyContent: 'center',
              marginTop: 28,
            }}
          >
            <Button
              style={{ backgroundColor: Colors.backgroundGrey, width: '50%' }}
              onPress={() => onAction('DELETE_BANK')}
            >
              <MaterialIcons name="delete-outline" size={16} color={Colors.red} />
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.red, marginLeft: 8 }}>
                Delete Bank
              </CustomText>
            </Button>
            <Button style={{ width: '50%' }} onPress={() => onAction('EDIT_BANK')}>
              <Octicons name="pencil" size={16} color={Colors.white} />
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.white, marginLeft: 8 }}>
                Change Bank
              </CustomText>
            </Button>
          </View>
        </>
      ) : (
        <Pressable
          style={{
            borderWidth: 1,
            borderColor: Colors.HorizonalLineDefaultColor,
            borderRadius: 8,
            width: 300,
            height: 150,
            backgroundColor: Colors.backgroundGrey,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16,
            alignSelf: 'center',
          }}
          onPress={() => {
            onAction('ADD_CREDIT');
            console.log('Add Card');
          }}
        >
          <Feather name="plus-circle" size={32} color={Colors.PaginationDotColor} />
          <CustomText
            weight="600"
            style={{ fontSize: 12, color: Colors.PaginationDotColor, marginTop: 16 }}
          >
            Add payment method
          </CustomText>
        </Pressable>
      )}
      <Button
        style={{ backgroundColor: Colors.backgroundGrey, marginTop: 16 }}
        onPress={() => {
          if (isValidCardPayment || isValidBankPayment) {
            onAction('SHOW_DELETE_MODAL');
            return;
          }
          onAction('ADD_BANK');
        }}
      >
        <Feather name="plus-circle" size={16} color={Colors.grey} />
        <CustomText weight="500" style={{ fontSize: 14, color: Colors.grey, marginLeft: 8 }}>
          {isValidCardPayment
            ? 'Add New Bank Account'
            : isValidBankPayment
            ? 'Add New Credit Card'
            : 'Add New Bank Account'}
        </CustomText>
      </Button>
    </>
  );
};

export default InitialView;
