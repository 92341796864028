import * as AppleAuthentication from 'expo-apple-authentication';

import { View, Image, SafeAreaView, Platform, StyleSheet } from 'react-native';
import { IntroSvgImage } from '../../components/IntroSvgImage';
import { AppButton } from '../../components/AppButton';
import { Colors } from '../../constants/Colors';
import { Sizes } from '../../constants/Sizes';
import CommonStyles from '../../constants/CommonStyles';
import AppGradientContainer from '../../components/AppGradientContainer';
import { PropType } from './index';

export default ({ onButtonPress }: PropType) => {
  return (
    <AppGradientContainer>
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ width: 200, height: 50 }}>
            <Image
              source={require('../../assets/images/logo_white.png')}
              style={styles.logo}
              resizeMode="contain"
            />
          </View>
        </View>
        <View style={{ ...CommonStyles.flexCenter, height: 350 }}>
          <IntroSvgImage />
        </View>
        <View style={{ ...CommonStyles.flexCenter, marginHorizontal: Sizes.large }}>
          <AppButton
            onPress={() => {}}
            textColor={Colors.green}
            color="#ffffff"
            text="Sign In with Email"
          />
        </View>
        <View
          style={{
            ...CommonStyles.flexCenter,
            marginHorizontal: Sizes.large,
            marginTop: Sizes.medium,
          }}
        >
          <AppButton
            onPress={() => {}}
            textColor={Colors.green}
            color="#ffffff"
            text="Sign In with Google"
          />
        </View>
        <View
          style={{
            ...CommonStyles.flexCenter,
            marginHorizontal: Sizes.large,
            marginTop: Sizes.medium,
          }}
        >
          {Platform.OS === 'ios' && (
            <AppleAuthentication.AppleAuthenticationButton
              buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
              buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
              cornerRadius={5}
              style={{ width: 310, height: 45 }}
              onPress={() => {}}
            />
          )}
          {Platform.OS === 'web' && (
            <div
              style={{ width: '100%', height: 40 }}
              data-color="white"
              data-borders="false"
              id="appleid-signin"
            ></div>
          )}
        </View>
        <View
          style={{
            ...CommonStyles.flexCenter,
            marginHorizontal: Sizes.large,
            marginTop: Sizes.medium,
          }}
        >
          <AppButton
            onPress={() => {}}
            textColor={Colors.white}
            color={Colors.green}
            text="New User? Create an Account"
          />
        </View>
      </SafeAreaView>
    </AppGradientContainer>
  );
};

const styles = StyleSheet.create({
  logo: {
    flex: 1,
    width: 200,
    height: 50,
  },
});
