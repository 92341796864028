import { Pressable, ScrollView, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import HorizontalLine from '../../components/HorizontalLine';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import Button from '../../components/Button';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { useState } from 'react';
import { AppDrawer } from '../../components/AppDrawer';
import { carriers } from '../../constants/constants';
import { PortCarrier } from '../../types/types';
import { PortOutData } from '.';

const PortInDetailView = ({ onPress }: { onPress(): void }) => {
  const [showCarrierModel, setShowCarrierModel] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<PortCarrier>();
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 80, paddingBottom: 20 }}
      >
        <Pressable
          onPress={() => {
            setShowCarrierModel(true);
          }}
        >
          <InputWithTitle
            inputValue={carrier ?? 'Select the carrier*'}
            title="From which provider?*"
          />
        </Pressable>
        <InputWithTitle
          inputValue={accountNumber}
          onInputChange={setAccountNumber}
          title="Account Number"
        />
        <InputWithTitle
          inputValue={phoneNumber}
          maxLength={10}
          onInputChange={setPhoneNumber}
          title="Phone Number"
        />

        <HorizontalLine
          thinkness={1}
          style={{
            marginVertical: 24,
          }}
        />

        <InfoDescriptionToggle
          title="What is Port in?"
          height={150}
          style={{
            marginHorizontal: 0,
            marginBottom: 16,
          }}
          desc="Lorem ipsum dolor sit amet consectetur. Lacus faucibus pulvinar dignissim adipiscing ultrices ipsum. Erat donec pellentesque gravida ultrices viverra arcu amet."
        />
      </ScrollView>

      <AppDrawer
        onClose={() => {
          setShowCarrierModel(false);
        }}
        height={500}
        visible={showCarrierModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Carrier
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {carriers.map((carrier) => (
            <CustomText
              onPress={() => {
                setCarrier(carrier);
                setShowCarrierModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={carrier}
            >
              {carrier}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            onPress();
          }}
          textStyle={{ fontSize: 14 }}
          disabled={!carrier || !accountNumber || !phoneNumber}
        >
          Request Port in
        </Button>
      </View>
    </View>
  );
};

export default PortInDetailView;
