import { StyleSheet } from "react-native";
import { Colors } from "./Colors";
import FontFamilys from "./FontFamilys";

const CommonStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center"
  },
  topRow: {
    flexDirection: "row"
  },
  justifyRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexCenter: {
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center" 
  },
  blackText: {
    color: "#000000"
  },
  futuraFont: {
    fontFamily: "Futura"
  },
  greenText: {
    color: Colors.green
  },
  whiteText: {
    color: "#ffffff",
    fontFamily: "Futura"
  },
  container: {
    marginHorizontal: 20
  },
  title: {
    fontFamily: "Futura",
    fontSize: 30
  },
  subtitle: {
    fontSize: 20,
    fontFamily: "Futura"
  },
  errorText: {
    color: Colors.red
  },
  card: {
    padding: 15,
    borderRadius: 10
  },
  input: {
    height: 50,
    backgroundColor: Colors.lightGrey,
    width: '100%',
    borderRadius: 10,
    fontSize: 15,
    paddingHorizontal: 16,
    fontWeight: "300"
  },
  boldText: {
    fontFamily: FontFamilys.bold
  },
  inputLabel: {
    fontWeight: "600",
    fontSize: 20
  },
  bottomContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 25
  }
});

export default CommonStyles;