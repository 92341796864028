import { useEffect, useState } from 'react';
import { SafeAreaView, StatusBar } from 'react-native';

import { RootStackScreenProps } from '../../types';
import { Colors } from '../../constants/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { accountService } from '../../services/MyAccount/AccountService';
import {
  setCustomerShippingOrders,
  setSelectedCustomerShippingOrders,
} from '../../store/selfServeCA';
import { RootState } from '../../store';
import { CustomerShippingOrder } from '../../types/models/models';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import InitialView from './InitialView';
import MarkReceivedView from './MarkReceivedView';
import UpdateAddressView from './UpdateAddressView';

enum FlowStep {
  Initial = 'initial',
  MarkReceived = 'mark-received',
  ChangeAddress = 'change-address',
}

const ShippingTrackingScreen = ({
  navigation,
  route,
}: RootStackScreenProps<'ShippingTracking'>) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<FlowStep>(FlowStep.Initial);

  const [loading, setLoading] = useState<boolean>(false);

  const userEmail = useSelector((state: RootState) => state.user.email);
  const { selectingShippingOrder } = useSelector((state: RootState) => ({
    selectingShippingOrder: state.selfServeCA.selectingShippingOrder,
  }));

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await accountService.getCustomerShippingOrders(userEmail);
      const orders = response.data;
      dispatch(setCustomerShippingOrders(orders));
      const findOrder = orders.find((o) => o.ShippingOrderId == route.params.tracking);
      if (!findOrder) {
        navigation.navigate('Root');
        return;
      }
      dispatch(setSelectedCustomerShippingOrders(findOrder));
    };
    if (
      !selectingShippingOrder ||
      selectingShippingOrder.ShippingOrderId !== route.params.tracking
    ) {
      fetchOrders();
    }
  }, [route.params.tracking]);

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <SafeAreaView
        style={{
          flex: 1,
          height: '100%',
          marginTop: StatusBar.currentHeight,
          backgroundColor: Colors.white,
          paddingVertical: 24,
          paddingHorizontal: 20,
          position: 'relative',
        }}
      >
        {selectingShippingOrder ? (
          step === FlowStep.Initial ? (
            <InitialView
              selectingShippingOrder={selectingShippingOrder!}
              onMarkedReceived={() => {
                setStep(FlowStep.MarkReceived);
              }}
              onUpdateAddress={() => {
                setStep(FlowStep.ChangeAddress);
              }}
            />
          ) : step === FlowStep.MarkReceived ? (
            <MarkReceivedView
              selectingShippingOrder={selectingShippingOrder}
              onComplete={() => {
                navigation.navigate('StatusTracking');
              }}
              setLoading={setLoading}
              onCancel={() => {
                navigation.goBack();
              }}
            />
          ) : (
            <UpdateAddressView
              selectingShippingOrder={selectingShippingOrder}
              loading={loading}
              setLoading={setLoading}
              onCancel={() => {
                setStep(FlowStep.Initial);
              }}
              onDone={() => {
                navigation.navigate('StatusTracking');
              }}
            />
          )
        ) : null}
      </SafeAreaView>
    </>
  );
};

export default ShippingTrackingScreen;
