import { useState } from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { PortCarrier } from '../../types/types';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import HorizontalLine from '../../components/HorizontalLine';
import { AppDrawer } from '../../components/AppDrawer';
import CustomText from '../../components/utils/CustomText';
import { carriers } from '../../constants/constants';
import Button from '../../components/Button';

const UpdateInformationView = ({ onPress }: { onPress(): void }) => {
  const [showCarrierModel, setShowCarrierModel] = useState<boolean>(false);
  const [showConfirmModel, setShowConfirmModel] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<PortCarrier>();
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        // backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
        borderStartColor: 'red'
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 80, paddingBottom: 20 }}
      >
        <Pressable
          onPress={() => {
            setShowCarrierModel(true);
          }}
        >
          <InputWithTitle
            inputValue={carrier ?? 'Select the carrier*'}
            title="From which provider?*"
          />
        </Pressable>
        <InputWithTitle
          inputValue={accountNumber}
          onInputChange={setAccountNumber}
          title="Account Number"
        />
        <InputWithTitle
          inputValue={phoneNumber}
          maxLength={10}
          onInputChange={setPhoneNumber}
          title="Phone Number"
        />

        <HorizontalLine
          thinkness={1}
          style={{
            marginVertical: 24,
          }}
        />
      </ScrollView>

      <AppDrawer
        onClose={() => {
          setShowCarrierModel(false);
        }}
        height={500}
        visible={showCarrierModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Carrier
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {carriers.map((carrier) => (
            <CustomText
              onPress={() => {
                setCarrier(carrier);
                setShowCarrierModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={carrier}
            >
              {carrier}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <AppDrawer
        visible={showConfirmModel}
        height={385}
        onClose={() => {
          setShowConfirmModel(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Would you like to resubmit port in?
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText weight="400" style={{ fontSize: 16, textAlign: 'justify', marginVertical: 16 }}>
          Confirm that you would like to resubmit port in request?
        </CustomText>
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            onPress();
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Confirm
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => {
            setShowConfirmModel(false);
          }}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Cancel
          </CustomText>
        </Button>
      </AppDrawer>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            setShowConfirmModel(true);
          }}
          textStyle={{ fontSize: 14 }}
          disabled={!carrier || !accountNumber || !phoneNumber}
        >
          Resubmit Port in
        </Button>
      </View>
    </View>
  );
};

export default UpdateInformationView;
