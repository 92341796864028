import { StyleSheet, View, Text, SafeAreaView, ActivityIndicator } from "react-native";
import CommonStyles from "../constants/CommonStyles";
import useIsMobile from "../hooks/useIsMobile";
import AppGradientContainer from "../components/AppGradientContainer";
import { AppLogoSvgIcon } from "../components/AppLogoSvgIcon";
import { AppGap } from "../components/AppGap";
import { Colors } from "../constants/Colors";
import FontFamilys from "../constants/FontFamilys";

export default function LoadingScreen() {
  const isMobile = useIsMobile();
  
  return (
    <>
      {isMobile &&
        <AppGradientContainer>
          <SafeAreaView style={styles.loadingWrapper}>
            <AppLogoSvgIcon />
            <AppGap direction="vertical" size={20} />
            <ActivityIndicator size="large" color={Colors.white} />
            <Text style={styles.loadingText}>Hang tight.. We're getting everything ready for you.</Text>
          </SafeAreaView>
        </AppGradientContainer>
      }
    </>
  )
}

const styles = StyleSheet.create({
  loadingText: {
    fontFamily: FontFamilys.regular,
    color: Colors.white,
    textAlign: "center",
    marginTop: 20
  },
  loadingWrapper: {
    display: "flex", 
    height: "100%", 
    width: "100%", 
    justifyContent: "center",
    alignItems: "center"
  }
});