import * as Google from 'expo-auth-session/providers/google';
import * as AppleAuthentication from 'expo-apple-authentication';

import { SignInResponse } from '../types/responses/SSO/SignInResponse';
import { authService } from '../services/SSO/AuthService';
import { tokenService } from '../services/TokenService';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from '../store/user';
import { setIsLoading } from '../store/app';

import { View, Image, Platform } from 'react-native';

import { Colors } from '../constants/Colors';
import Button from '../components/Button';
import { Helmet } from 'react-helmet';
import { AppGap } from '../components/AppGap';
import { SystemCodes } from '../types/enums/SystemCodes';
import { useEffect } from 'react';
import { validateToken } from '../utilities/functions';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../types';
import CustomText from './utils/CustomText';

export type AppleGoogleProps = {
  setIsLoadingAPI: React.Dispatch<React.SetStateAction<boolean>>;
  propsKey?: React.Key | null | undefined;
};

export default ({ setIsLoadingAPI, propsKey }: AppleGoogleProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const dispatch = useDispatch();

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    responseType: 'id_token',
    androidClientId: '857655250828-f7lnt384lss83c77p1777mgme1lathek.apps.googleusercontent.com',
    webClientId: '857655250828-u9isngd49fkas58l8tmg1r65g5afmp3t.apps.googleusercontent.com',
    iosClientId: '857655250828-su69qd2kk4gnl322b5klhgg2kd5847tf.apps.googleusercontent.com',
  });

  const handleAppleLoginAsync = async () => {
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [AppleAuthentication.AppleAuthenticationScope.EMAIL],
      });
      console.log('Success? : ', credential);
    } catch (e: any) {
      console.error(e);
      if (e.code === 'ERR_REQUEST_CANCELED') {
        // error code
      } else {
        // handle error
      }
    }
  };

  const handleAppleLoginWeb = async (event: any) => {
    console.log('event loaded: ', event);
    if (event.detail?.authorization?.code) {
      setIsLoadingAPI(true);
      const signInAppleResponse = await authService.signInApple({
        data: {
          code: event.detail.authorization.code,
          isMobile: Platform.OS !== 'web',
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (signInAppleResponse && signInAppleResponse.data && signInAppleResponse.data.success) {
        setTokensAndContentBasedOnSignInResponse(signInAppleResponse.data.data);
      }
    }
  };

  const setTokensAndContentBasedOnSignInResponse = async (data: SignInResponse) => {
    await validateToken(data, dispatch, navigation);

    // dispatch(setIsLoading(true));
    // dispatch(setLoggedIn(true));
    setIsLoadingAPI(false);
  };

  useEffect(() => {
    const handleReceivedGoogleToken = async (idToken: string) => {
      setIsLoadingAPI(true);
      try {
        const signInGoogleResponse = await authService.signInGoogle({
          data: {
            token: idToken,
            systemCode: SystemCodes.CA_SELFSERVE,
          },
        });

        if (signInGoogleResponse?.data?.data && signInGoogleResponse.data.success) {
          console.log('sign in response from phonebox id server: ', signInGoogleResponse.data.data);
          if (
            signInGoogleResponse.data.data.idToken &&
            signInGoogleResponse.data.data.idRefreshToken
          ) {
            setTokensAndContentBasedOnSignInResponse(signInGoogleResponse.data.data);
          }
        }
      } catch (error) {
        console.error('Something went wrong with Auth/google_signin: ', error);
        setIsLoadingAPI(false);
      }
    };

    if (response?.type === 'success') {
      // for some reason, depending on the platform, "response" either has
      // "params" with the needed id_token or
      // "authentication" with the needed idToken.
      // functionally they are the same to our backend.

      if (response.params && response.params.id_token) {
        handleReceivedGoogleToken(response.params.id_token);
      } else if (response.authentication && response.authentication.idToken) {
        handleReceivedGoogleToken(response.authentication.idToken);
      }
    }
  }, [response]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const script = document.createElement('script');
      script.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.onload = () => {
        console.log('Script loaded');
        // @ts-ignore
        window.initAppleAuth = function () {
          const myhost = window.location.href.split('/login')[0];
          // @ts-ignore
          window.AppleID.auth.init({
            clientId: 'com.phonebox.client',
            scope: 'email',
            redirectURI: myhost,
            usePopup: true,
          });
        };
        // Perform actions after the script is loaded, if needed
        // @ts-ignore
        window.initAppleAuth();
      };
      document.head.appendChild(script);
      document.addEventListener('AppleIDSignInOnSuccess', handleAppleLoginWeb);

      return () => {
        console.log('Removing Everytinh');
        // Clean up by removing the script when the component unmounts
        document.head.removeChild(script);
        document.removeEventListener('AppleIDSignInOnSuccess', handleAppleLoginWeb);
      };
    }
  }, []);

  const onPressLoginWithGoogle = () => {
    promptAsync();
  };

  return (
    <>
      <Helmet key={`${propsKey}-helmet`}>
        <style type="text/css">{`
        #appleid-signin div {
            background-color: ${Colors.backgroundGrey};
            border-radius: 8px;
        }
    `}</style>
      </Helmet>
      <View
        key={`${propsKey}-view`}
        style={{
          marginTop: 15,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {Platform.OS === 'ios' && (
          <>
            <AppleAuthentication.AppleAuthenticationButton
              buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
              buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
              cornerRadius={5}
              style={{ width: 310, height: 45 }}
              onPress={handleAppleLoginAsync}
            />
            <AppGap size={20} />
          </>
        )}
        {Platform.OS === 'web' && (
          <>
            <Button
              size="NORMAL"
              color={Colors.backgroundGrey}
              style={{ width: 48, height: 48, padding: 0 }}
              isChildNonText={true}
            >
              <div
                style={{ width: '48px', height: '48px' }}
                data-color="white"
                data-border="false"
                id="appleid-signin"
                data-mode="logo-only"
                data-logo-size="large"
              ></div>
            </Button>
            <AppGap size={20} />
          </>
        )}
        <Button
          color={Colors.white}
          style={{
            width: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={onPressLoginWithGoogle}
        >
          <Image
            source={require('../assets/icons/google.png')}
            style={{
              width: 16,
              height: 16,
            }}
            resizeMode="contain"
          />
          <CustomText weight="500" style={{ fontSize: 16, marginLeft: 8 }}>
            Sign in with Google
          </CustomText>
        </Button>
      </View>
    </>
  );
};
