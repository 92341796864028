import { useState, useRef, useEffect } from "react";
import { TextInput, TextInputKeyPressEventData, NativeSyntheticEvent, StyleSheet, View } from "react-native";
import CommonStyles from "../constants/CommonStyles";
import  { Colors } from "../constants/Colors";
import { numbersOnly } from "../utilities/utilities";

type AppVerificationCodeInputProps = {
  onChangeText: ((text: string) => void);
}

export function AppVerificationCodeInput(props: AppVerificationCodeInputProps) {
  const inputRefs = useRef<Array<TextInput | null>>([]);
  const [charCodes, setCharCodes] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    let fullCode = charCodes.join("");
    props.onChangeText(fullCode);
  }, [charCodes]);

  const onChangeCode = (text: string, index: number) => {
    text = numbersOnly(text);
    const newCharCodes = [...charCodes];
    newCharCodes[index] = text;
    setCharCodes(newCharCodes);

    // Move focus to the next input field if available
    if (text && index < charCodes.length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>, index: number) => {
    if (e.nativeEvent.key === "Backspace" && charCodes[index] === "" && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
      const newCharCodes = [...charCodes];
      newCharCodes[index - 1] = "";
      setCharCodes(newCharCodes);
    }
  }
  
  return (
    <View style={{ ...styles.inputContainer, ...CommonStyles.justifyRow }}>
      {charCodes.map((charCode, index) => (
        <TextInput
          key={index}
          ref={(ref) => inputRefs.current[index] = ref}
          style={styles.textInput}
          value={charCode}
          onChangeText={(text) => onChangeCode(text, index)}
          maxLength={1}
          placeholderTextColor={Colors.mediumGrey}
          placeholder="-"
          onKeyPress={(event) => onKeyPress(event, index)}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    marginTop: 25
  },
  textInput: {
    borderColor: Colors.mediumGrey,
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 1,
    width: 50,
    height: 50,
    padding: 18,
    fontSize: 20,
    textAlign: "center"
  }
});