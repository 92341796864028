import { Image, Pressable, ScrollView } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import HorizontalLine from '../../components/HorizontalLine';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';

const InitialView = ({ onPress }: { onPress(option: string): void }) => {
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        padding: 20,
        backgroundColor: Colors.white,
      }}
    >
      <CustomText weight="600" style={{ fontSize: 14, color: Colors.TextBlack, marginBottom: 24 }}>
        Which Service you would like to proceed?
      </CustomText>
      <Pressable
        onPress={() => {
          console.log('Port Out');
          onPress('PORT_IN');
        }}
      >
        <Image
          source={require('../../assets/images/port-in.png')}
          style={{
            width: 335,
            height: 160,
            marginBottom: 16,
          }}
          resizeMode="cover"
        />
      </Pressable>
      <Pressable
        onPress={() => {
          console.log('Port In');
          onPress('PORT_OUT');
        }}
      >
        <Image
          source={require('../../assets/images/port-out.png')}
          style={{
            width: 335,
            height: 62,
            marginBottom: 16,
          }}
          resizeMode="cover"
        />
      </Pressable>
      <HorizontalLine
        thinkness={1}
        style={{
          marginBottom: 24,
          marginTop: 32,
        }}
      />
      <InfoDescriptionToggle
        style={{
          marginBottom: 20,
        }}
        height={130}
        title="What is Port in?"
        desc="Lorem ipsum dolor sit amet consectetur. Lacus faucibus pulvinar dignissim adipiscing ultrices ipsum. Erat donec pellentesque gravida ultrices viverra arcu amet."
      />
      <InfoDescriptionToggle
        height={130}
        title="What is Port out?"
        desc="Lorem ipsum dolor sit amet consectetur. Lacus faucibus pulvinar dignissim adipiscing ultrices ipsum. Erat donec pellentesque gravida ultrices viverra arcu amet."
      />
    </ScrollView>
  );
};

export default InitialView;
