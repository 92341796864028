import { useState } from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput as ReactNativeTextInput,
  TextInputKeyPressEventData,
  ViewStyle,
  Text,
  TextInputFocusEventData,
  InputModeOptions,
  TextStyle,
  TextInputProps,
  KeyboardTypeOptions,
  View,
} from 'react-native';
import { Colors } from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import useIsMobile from '../hooks/useIsMobile';
import { numbersOnly } from '../utilities/utilities';
import CustomText from './utils/CustomText';
import { FontAwesome6, Ionicons } from '@expo/vector-icons';

interface _TextInputProps extends TextInputProps {
  placeholder?: string;
  secureTextEntry?: boolean;
  value?: string | '';
  keyboardType?: KeyboardTypeOptions;
  maxLength?: number | undefined;
  numbersOnly?: boolean;
  expiryDate?: boolean;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  onChangeText: (text: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  enablePasswordToggle?: boolean;
  style?: ViewStyle;
  error?: {
    error?: string;
    errorColor?: string;
    style?: TextStyle;
  };
  eyeTopMargin?: number;
}

const TextInput = (props: _TextInputProps) => {
  const [passwordVisible, setPasswordVisibility] = useState<boolean | undefined>(
    props.secureTextEntry
  );

  let enablePasswordToggle = props.enablePasswordToggle;
  if (enablePasswordToggle === undefined && props.secureTextEntry === true) {
    enablePasswordToggle = true;
  }

  const styles = StyleSheet.create({
    textInput: {
      backgroundColor: props.editable === false ? Colors.inputTextPlaceholderColor : Colors.backgroundGrey,
      paddingVertical: 8,
      borderRadius: 8,
      height: 48,
      width: '100%',
      paddingHorizontal: 16,
      paddingRight: enablePasswordToggle ? 40 : 16,
      color: props.editable === false ? Colors.white : Colors.black,
      ...props.style,
    },
  });

  return (
    <View style={{ position: 'relative' }}>
      <ReactNativeTextInput
        {...props}
        onBlur={props.onBlur}
        placeholderTextColor={Colors.inputTextPlaceholderColor}
        keyboardType={props.keyboardType}
        value={props.value}
        style={styles.textInput}
        onChangeText={props.onChangeText}
        placeholder={props.placeholder}
        secureTextEntry={passwordVisible}
        maxLength={props.maxLength}
        onKeyPress={props.onKeyPress}
      />
      {props.secureTextEntry && enablePasswordToggle ? (
        <View
          style={{
            position: 'absolute',
            right: 10,
            top: props.eyeTopMargin ?? 20,
          }}
        >
          <Ionicons
            name={passwordVisible ? 'eye' : 'eye-off'}
            size={20}
            color={Colors.inputTextPlaceholderColor}
            onPress={() => setPasswordVisibility((currentValue) => !currentValue)}
          />
        </View>
      ) : null}
      {props.error?.error ? (
        <CustomText
          weight={props.error.style?.fontWeight}
          style={{
            color: props.error?.errorColor ?? Colors.errorRed,
            marginTop: 4,
            ...props.error?.style,
          }}
        >
          {props.error.error}
        </CustomText>
      ) : null}
    </View>
  );
};

export default TextInput;
