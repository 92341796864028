import { instance } from '../../api/axios';
import { ApiResponse } from '../../types/responses/ApiResponse';
import { GetProfileRequest } from '../../types/requests/MyAccount/GetProfileRequest';
import { GetProfileResponse } from '../../types/responses/MyAccount/GetProfileResponse';
import { GetAccountInfoRequest } from '../../types/requests/MyAccount/GetAccountInfoRequest';
import { GetAccountInfoResponse } from '../../types/responses/MyAccount/GetAccountInfoResponse';
import { ChangePaymentInfoRequest } from '../../types/requests/MyAccount/ChangePaymentInfoRequest';
import { ChangePaymentInfoResponse } from '../../types/responses/MyAccount/ChangePaymentInfoResponse';
import { NonActivatedAccountInfoRequest } from '../../types/requests/MyAccount/NonActivatedAccountInfoRequest';
import { NonActivatedAccountInfoResponse } from '../../types/responses/MyAccount/NonActivatedAccountInfoResponse';
import { RecentAccountTransactionRequest } from '../../types/requests/MyAccount/RecentAccountTransactionRequest';
import { RecentAccountTransactionResponse } from '../../types/responses/MyAccount/RecentAccountTransactionResponse';
import { CustomerBillingRequest } from '../../types/requests/MyAccount/CustomerBillingRequest';
import { CustomerBillingResponse } from '../../types/responses/MyAccount/CustomerBillingResponse';
import { ChangePlanRequest } from '../../types/requests/MyAccount/ChangePlanRequest';
import { ChangePlanResponse } from '../../types/responses/MyAccount/ChangePlanResponse';
import { GetStatusRequest } from '../../types/requests/MyAccount/GetStatusRequest';
import { GetStatusResponse } from '../../types/responses/MyAccount/GetStatusResponse';
import { PostSuspensionRequest } from '../../types/requests/MyAccount/PostSuspensionRequest';
import { PostSuspensionResponse } from '../../types/responses/MyAccount/PostSuspensionResponse';
import { PostTerminationRequest } from '../../types/requests/MyAccount/PostTerminationRequest';
import { PostTerminationResponse } from '../../types/responses/MyAccount/PostTerminationResponse';
import { ValidateTokenRequest } from '../../types/requests/MyAccount/ValidateTokenRequest';
import { ValidateTokenResponse } from '../../types/responses/MyAccount/ValidateTokenResponse';
import { MultiLineAccountInfo } from '../../types/models/MultiLineAccountInfo';
import { AccountProfileCA } from '../../types/models/AccountProfileCA';
import { CustomerShippingOrder } from '../../types/models/models';
import { GetMultiLineProductResponse } from '../../types/responses/MyAccount/GetMultiLineProductResponse';
import { GetCustomerServiceRequestsResponse } from '../../types/responses/MyAccount/GetCustomerServiceRequestsResponse';
import { InvoiceInfo } from '../../types/models/InvoiceInfo';
import { ChangePaymentMethodRequest } from '../../types/requests/MyAccount/ChangePaymentMethodRequest';

export const accountService = {
  getProfile: async (request: GetProfileRequest): Promise<ApiResponse<AccountProfileCA>> =>
    await instance.get<GetProfileRequest, ApiResponse<AccountProfileCA>>(
      '/Account/GetProfile',
      request
    ),
  getAccountInfo: async (
    request: GetAccountInfoRequest
  ): Promise<ApiResponse<GetAccountInfoResponse>> =>
    await instance.get<GetAccountInfoRequest, ApiResponse<GetAccountInfoResponse>>(
      '/Account/AccountInfo',
      request
    ),
  changeAddress: async (address: string): Promise<ApiResponse<string>> => await instance.post('/Account/ChangeAddress', {}, {
    params: {
      address: address
    }
  }), // TODO
  recentAccountTransaction: async (
    currentDate: string
  ): Promise<ApiResponse<RecentAccountTransactionResponse>> =>
    await instance.get('/Account/RecentAccountTransaction', {
      params: {
        curDate: currentDate
      }
    }),
  invoiceInfo: async (
    year: number
  ): Promise<ApiResponse<InvoiceInfo[]>> =>
    await instance.get('/Account/InvoiceInfo', {
      params: {
        year
      }
    }),
  invoiceInfoByYearMonth: async (
    month: number, year: number
  ): Promise<ApiResponse<InvoiceInfo>> =>
    await instance.get('/Account/InvoiceInfoByYearMonth', {
      params: {
        year, month
      }
    }),
  changePaymentInfo: async (
    request: ChangePaymentInfoRequest
  ): Promise<ApiResponse<ChangePaymentInfoResponse>> =>
    await instance.post<ChangePaymentInfoRequest, ApiResponse<ChangePaymentInfoResponse>>(
      '/Account/ChangePaymentInfo',
      request
    ),
  changePaymentMethod: async (
    request: ChangePaymentMethodRequest
  ): Promise<ApiResponse<string>> =>
    await instance.post(
      '/Account/ChangePaymentMethod',
      request
    ),
  nonActivatedAccountInfo: async (
    request: NonActivatedAccountInfoRequest
  ): Promise<ApiResponse<NonActivatedAccountInfoResponse>> =>
    await instance.get<
      NonActivatedAccountInfoRequest,
      ApiResponse<NonActivatedAccountInfoResponse>
    >('/Account/NonActivatedAccountInfo', { headers: request.headers }),
  customerBilling: async (
    request: CustomerBillingRequest
  ): Promise<ApiResponse<CustomerBillingResponse>> =>
    await instance.post<CustomerBillingRequest, ApiResponse<CustomerBillingResponse>>(
      `/Account/CustomerBilling?amount=${request.amount}&billtype=${request.billtype}&systag=${request.systag}&return_url=${request.return_url}&cancel_url=${request.cancel_url}`
    ),
  changePlan: async (request: ChangePlanRequest): Promise<ApiResponse<ChangePlanResponse>> =>
    instance.post<ChangePaymentInfoRequest, ApiResponse<ChangePlanResponse>>(
      `/Account/ChangePlan?pfid=${request.pfid}&reason=?&effDate=${request.effDate}`
    ),
  getStatus: async (request: GetStatusRequest): Promise<ApiResponse<GetStatusResponse>> =>
    instance.get<GetStatusRequest, ApiResponse<GetStatusResponse>>('/Account/GetStatus'),
  postSuspension: async (
    request: PostSuspensionRequest
  ): Promise<ApiResponse<PostSuspensionResponse>> =>
    instance.post<PostSuspensionRequest, ApiResponse<PostSuspensionResponse>>(
      `/Account/PostSuspension?sdate=${request.sdate}&edate=${request.edate}&reason=${request.reason}&notes=${request.notes}`
    ),
  postTermination: async (
    request: PostTerminationRequest
  ): Promise<ApiResponse<PostTerminationResponse>> =>
    instance.post<PostSuspensionRequest, ApiResponse<PostSuspensionResponse>>(
      `/Account/PostTermination?terDate=${request.terDate}}&reasonCode=${request.reasonCode}&note=${request.note}`
    ),
  validateToken: async (
    request: ValidateTokenRequest
  ): Promise<ApiResponse<ValidateTokenResponse>> =>
    instance.get<ValidateTokenRequest, ApiResponse<ValidateTokenResponse>>(
      '/Account/ValidateToken',
      { headers: { Authtoken: request.authtoken } }
    ),
  getMultiLineAccountInfo: async (request: {
    cnumAcctOwner: string;
  }): Promise<ApiResponse<MultiLineAccountInfo[]>> =>
    instance.get<{}, ApiResponse<MultiLineAccountInfo[]>>('/Account/MultiLineAllAccountsInfo', {
      headers: { cnumAcctOwner: request.cnumAcctOwner },
    }),
  getCustomerShippingOrders: async (email: string): Promise<ApiResponse<CustomerShippingOrder[]>> =>
    instance.get<{}, ApiResponse<CustomerShippingOrder[]>>('/Account/GetCustomerShippingOrders', {
      headers: { email },
    }),
  getCustomerServiceRequests: async (email: string): Promise<ApiResponse<GetCustomerServiceRequestsResponse>> =>
    instance.get<{}, ApiResponse<GetCustomerServiceRequestsResponse>>('/Account/GetCustomerServiceRequests', {
      headers: { email },
    }),
  getMultiLineProduct: async (email: string): Promise<ApiResponse<GetMultiLineProductResponse>> =>
    instance.get('/Account/GetMultilineProduct', {
      headers: {
        email,
      },
    }),
  confirmShippingOrderReceipt: async (request: {
    type: string,
    shippingOrderId: number,
    simCardNumber: string
  }) => await instance.post("/Account/ConfirmShippingOrderReceipt", {
    Type: request.type,
    ShippingOrderId: request.shippingOrderId,
    SimCardNumber: request.simCardNumber,
  }),
  updateShippingOrderAddress: async (request: {
    type: string,
    shippingOrderId: number,
    streetAddress: string,
    city: string,
    province: string,
    postalCode: string,
    countryId: number,
  }) => await instance.post("/Account/UpdateShippingOrderAddress", {
    Type: request.type,
    ShippingOrderId: request.shippingOrderId,
    StreetAddress: request.streetAddress,
    City: request.city,
    Province: request.province,
    PostalCode: request.postalCode,
    CountryId: request.countryId,
  })
};
