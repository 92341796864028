import { InputModeOptions, KeyboardTypeOptions, View, ViewStyle } from 'react-native';
import { Colors } from '../../../constants/Colors';
import CustomText from '../../utils/CustomText';
import TextInput from '../../TextInput';

type Props = {
  title: string;
  inputValue: string;
  onInputChange?: (text: string) => void;
  keyboardType?: KeyboardTypeOptions;
  style?: ViewStyle;
  secureInput?: boolean;
  editable?: boolean;
  maxLength?: number;
  inputMode?: InputModeOptions;
};

const InputWithTitle = ({
  inputValue,
  onInputChange,
  title,
  keyboardType,
  style,
  secureInput,
  editable,
  maxLength,
  inputMode,
}: Props) => {
  return (
    <View
      style={{
        marginTop: 16,
        ...style,
      }}
    >
      <CustomText style={{ fontSize: 12, color: Colors.greyColor }} weight="500">
        {title}
      </CustomText>
      <TextInput
        maxLength={maxLength}
        editable={editable}
        keyboardType={keyboardType}
        placeholder={title}
        value={inputValue}
        secureTextEntry={secureInput}
        inputMode={inputMode}
        onChangeText={onInputChange ? onInputChange : () => {}}
        style={{ marginTop: 6 }}
      />
    </View>
  );
};

export default InputWithTitle;
