import { SafeAreaView, StatusBar, View } from 'react-native';
import { RootStackScreenProps } from '../../types';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { StepType } from '../../types/enums/StepType';
import HorizontalLine from '../../components/HorizontalLine';
import OTPInput from '../../components/OTPInput';
import { authService } from '../../services/SSO/AuthService';
import { SystemCodes } from '../../types/enums/SystemCodes';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { validate } from '../../utilities/utilities';
import { updateEmail } from '../../store/user';
import { signUpService } from '../../services/SSO/SignUpService';

export default function EditEmailScreen({ navigation }: RootStackScreenProps<'EditEmail'>) {
  const { email, firstName } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const [step, setStep] = useState<StepType>(StepType.First);

  const [newEmail, setNewEmail] = useState('');
  const [newConfirmEmail, setNewConfirmEmail] = useState('');

  const [otp, setOtp] = useState<string>('');
  const [otpShowError, setOtpShowError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [emailMatched, setEmailMatched] = useState<boolean>(false);

  useEffect(() => {
    setOtpShowError(false);
  }, [otp]);

  useEffect(() => {
    if (newEmail && validate('email', newEmail)) {
      setEmailMatched(newEmail === newConfirmEmail);
    }
  }, [newEmail, newConfirmEmail]);

  const requestVerificationCode = async () => {
    setLoading(true);

    try {
      const sendUpdateEmailCodeResponse = await authService.sendUpdateEmailCode({
        data: {
          name: firstName,
          email: newEmail,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!sendUpdateEmailCodeResponse || !sendUpdateEmailCodeResponse.data?.success) {
        setError('Sorry, something went wrong.');
        console.log('Something went wrong with sendUpdateEmailCode: ', sendUpdateEmailCodeResponse);
      }

      if (sendUpdateEmailCodeResponse?.data?.success) {
        console.log(sendUpdateEmailCodeResponse.data.data);
        setError('');
        setStep(StepType.Second);
      }
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const confirmVerificationCodeAndUpdateEmail = async () => {
    setLoading(true);

    try {
      const updateEmailResponse = await authService.updateEmail({
        data: {
          email: newEmail,
          code: otp,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!updateEmailResponse?.data?.success) {
        if (updateEmailResponse.data.error?.code === '1015') {
          setError(updateEmailResponse.data.error.message);
          return;
        }
        if (updateEmailResponse.data.error?.code === '400') {
          setError('Verification code entered is incorrect.');
          return;
        }
        setError('Sorry, something went wrong.');
        console.error(updateEmailResponse.data);
      }

      if (updateEmailResponse?.data?.success) {
        console.log(updateEmailResponse.data.data);
        dispatch(updateEmail(newEmail));
        setError('');
        setStep(StepType.Success);
      }
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const resendActivationCodeAsync = async () => {
    setLoading(true);
    try {
      const resendActivationCodeResponse = await signUpService.resendActivationCode({
        data: {
          email: newEmail,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });
      if (!resendActivationCodeResponse?.data?.success) {
        console.error(
          'Something went wrong with resendActivationCodeAsync():',
          resendActivationCodeResponse
        );
        setError('Sorry, something went wrong.');
      }
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <View
        style={{
          flex: 1,
          height: '100%',
          backgroundColor: Colors.white,
          padding: 20,
        }}
      >
        {step === StepType.First ? (
          <>
            <InputWithTitle
              title="Current Email"
              inputValue={email}
              keyboardType="email-address"
              editable={false}
            />
            <InputWithTitle
              title="New Email"
              inputValue={newEmail}
              keyboardType="email-address"
              onInputChange={setNewEmail}
            />
            <InputWithTitle
              title="Confirm New Email"
              inputValue={newConfirmEmail}
              keyboardType="email-address"
              onInputChange={setNewConfirmEmail}
            />
          </>
        ) : step === StepType.Second ? (
          <View style={{ width: '100%' }}>
            <CustomText
              weight="500"
              style={{ fontSize: 20, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
            >
              Verification Code
            </CustomText>
            <View style={{ marginBottom: 20 }}>
              <CustomText style={{ fontSize: 12 }}>
                A verification email has been sent to you
              </CustomText>
            </View>
            <HorizontalLine style={{ marginBottom: 20 }} />
            <OTPInput onChangeText={setOtp} isError={otpShowError} />
            <CustomText
              style={{
                marginVertical: 15,
                fontSize: 12,
              }}
            >
              Didn't receive the code?{' '}
              <CustomText
                onPress={() => resendActivationCodeAsync()}
                weight="600"
                style={{
                  color: Colors.canadaButtonColor,
                  fontWeight: '600',
                  textDecorationLine: 'underline',
                }}
              >
                Resend Code
              </CustomText>
            </CustomText>
          </View>
        ) : (
          <View>
            <CustomText
              weight="700"
              style={{ fontSize: 32, textAlign: 'center', color: Colors.greyColor }}
            >
              Your email address has been successfully changed.
            </CustomText>
          </View>
        )}
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            disabled={
              step === StepType.First
                ? !emailMatched
                : step === StepType.Second
                ? otp.length !== 6
                : false
            }
            onPress={() => {
              step === StepType.First
                ? requestVerificationCode()
                : step === StepType.Second
                ? confirmVerificationCodeAndUpdateEmail()
                : navigation.goBack();
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            {step === StepType.First
              ? 'Request Verification Code'
              : step === StepType.Second
              ? 'Change Email'
              : 'Go Back'}
          </Button>
        </View>
      </View>
    </>
  );
}
