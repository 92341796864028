import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

// SplashScreen.preventAutoHideAsync();

export default function useCachedResources() {

  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const [fontsLoaded] = Font.useFonts({
    ...FontAwesome.font,
    'FuturaCyrillicBold'       : require('../assets/fonts/FuturaCyrillicBold.ttf'),
    'FuturaCyrillicExtraBold'  : require('../assets/fonts/FuturaCyrillicExtraBold.ttf'),
    'FuturaCyrillicBook'       : require('../assets/fonts/FuturaCyrillicBook.ttf'),
    'FuturaCyrillicDemi'       : require('../assets/fonts/FuturaCyrillicDemi.ttf'),
    'FuturaCyrillicMedium'     : require('../assets/fonts/FuturaCyrillicMedium.ttf'),
    'FuturaCyrillicLight'      : require('../assets/fonts/FuturaCyrillicLight.ttf'),
    'FuturaCyrillicHeavy'      : require('../assets/fonts/FuturaCyrillicHeavy.ttf'),

    // Poppins
    // 100
    'Poppins-Thin'             : require("../assets/fonts/poppins/Poppins-Thin.ttf"), // 100
    'Poppins-ThinItalic'        : require("../assets/fonts/poppins/Poppins-ThinItalic.ttf"), // 100
    // 200
    'Poppins-ExtraLight'       : require("../assets/fonts/poppins/Poppins-ExtraLight.ttf"), // 200
    'Poppins-ExtraLightItalic' : require("../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf"), // 200
    // 300
    'Poppins-Light'            : require("../assets/fonts/poppins/Poppins-Light.ttf"), // 300
    'Poppins-LightItalic'      : require("../assets/fonts/poppins/Poppins-LightItalic.ttf"), // 300
    // 400
    'Poppins-Regular'          : require("../assets/fonts/poppins/Poppins-Regular.ttf"), // 400
    'Poppins-Italic'           : require("../assets/fonts/poppins/Poppins-Italic.ttf"), // 400
    // 500
    'Poppins-Medium'           : require("../assets/fonts/poppins/Poppins-Medium.ttf"), // 500
    'Poppins-MediumItalic'     : require("../assets/fonts/poppins/Poppins-MediumItalic.ttf"), // 500
    // 600
    'Poppins-SemiBold'         : require("../assets/fonts/poppins/Poppins-SemiBold.ttf"), // 600
    'Poppins-SemiBoldItalic'   : require("../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf"), // 600
    // 700
    'Poppins-Bold'             : require("../assets/fonts/poppins/Poppins-Bold.ttf"), // 700
    'Poppins-BoldItalic'       : require("../assets/fonts/poppins/Poppins-BoldItalic.ttf"), // 700
    // 800
    'Poppins-ExtraBold'        : require("../assets/fonts/poppins/Poppins-ExtraBold.ttf"), // 800
    'Poppins-ExtraBoldItalic'  : require("../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf"), // 800
    // 900
    'Poppins-Black'            : require("../assets/fonts/poppins/Poppins-Black.ttf"), // 900
    'Poppins-BlackItalic'      : require("../assets/fonts/poppins/Poppins-BlackItalic.ttf"), // 900

  })

  useEffect(() => {

    async function loadResourcesAndDataAsync() {
      if (fontsLoaded) {
        await SplashScreen.hideAsync();
        setLoadingComplete(true);
      }

      if (!fontsLoaded) {
        return null
      }
    }

    loadResourcesAndDataAsync();
  }, [fontsLoaded])


  return isLoadingComplete;
}
