// store file for user information that comes from the SSO project.
// note: "signInContentCA" is deprecated but I have left it here
// for future reference.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { AccountProfileCA } from '../types/models/AccountProfileCA';
import { ProfileType } from '../types/models/ProfileType';
import { MultiLineAccountInfo } from '../types/models/MultiLineAccountInfo';
// import { ParsedPlan } from '../types/models/ParsedPlan';
// import { SignInContentCA } from '../types/models/SignInContentCA';

const initialState = {
  email: '',
  firstName: '',
  activeUserFirstName: '',
  activeUserLastName: '',
  lastName: '',
  id: '',
  cnum: '',
  activeCnum: '',
  apiToken: '',
  profileImage: -1,
  loggedIn: false,
  authorizedBy: '',
  notifications: [],
  accounts: [] as MultiLineAccountInfo[] | string[],
  isMultiLineAccount: false,
  // !REMOVE THIS AFTER FIXING IT EVERY WHERE
  profile: {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
  } as ProfileType,
  userReset: false,
  // signInContentCA: {
  //   type: "",
  //   firstName: "",
  //   lastName: "",
  //   cnum: "",
  //   token: "",
  //   pnum: null,
  //   balance: 0,
  //   tmpLogin: false,
  //   isTmpPwd: null,
  //   email: "",
  //   terDate: null,
  //   isPrepaid: false
  // } as SignInContentCA
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = action.payload;
    },
    // setSignInContentCA(state, action: PayloadAction<SignInContentCA>) {
    //   state.signInContentCA = action.payload;
    // },
    resetUser(state) {
      Object.assign(state, { ...initialState, userReset: true });
    },
    resetComplete(state) {
      state.userReset = false;
    },
    setAccounts(state, action: PayloadAction<{ accounts: MultiLineAccountInfo[] | string[], isMultiLineAccount: boolean }>) {
      state.accounts = action.payload.accounts;
      state.isMultiLineAccount = action.payload.isMultiLineAccount;
    },
    setProfile(state, action: PayloadAction<ProfileType>) {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.id = action.payload.id;
      state.profileImage = action.payload.profileImage ?? -1;
      state.activeUserFirstName = action.payload.firstName;
      state.activeUserLastName = action.payload.lastName;
      console.info(
        '%cWARNING: Fix Payload Profile, Some screens are using profile info as profile.INFO, need to fix it.',
        'color: red; font-size: 20px; font-weight: bold; background-color: #fdd; padding: 10px; border: 1px solid red; border-radius: 5px;'
      );
      state.profile = action.payload;
    },
    updateProfile(state, action: PayloadAction<ProfileType>) {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.id = action.payload.id;
      state.profileImage = action.payload.profileImage ?? -1;
      state.profile = action.payload;
    },
    setActiveUser(
      state,
      action: PayloadAction<{
        user: {
          firstName: string;
          lastName: string;
          cnum: string;
        };
      }>
    ) {
      state.activeUserFirstName = action.payload.user.firstName;
      state.activeUserLastName = action.payload.user.lastName;
      state.activeCnum = action.payload.user.cnum;
    },
    setCnum(state, action: PayloadAction<string>) {
      state.cnum = action.payload;
      state.activeCnum = action.payload;
    },
    setApiToken(state, action: PayloadAction<string>) {
      state.apiToken = action.payload;
    },
    updateEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setAuthorizedBy(state, action: PayloadAction<string>) {
      state.authorizedBy = action.payload;
    }
  },
});

export const {
  setLoggedIn,
  setProfile,
  setAccounts,
  setCnum,
  setApiToken,
  resetUser,
  setActiveUser,
  resetComplete,
  updateEmail,
  setAuthorizedBy,
  updateProfile,
  // setSignInContentCA,
} = userSlice.actions;

export default userSlice.reducer;
