import { Colors } from '../../constants/Colors';
import { Image, Pressable, SafeAreaView, ScrollView, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import Button from '../../components/Button';
import { SIMType } from '.';
import { useState } from 'react';

export type ShippingType = 'express' | 'regular';

type Props = {
  shippingType: ShippingType | null;
  onNext(shippingType: ShippingType): void;
};

const shippingImage = {
  express: {
    light: require('../../assets/images/express-shipping-light.png'),
    dark: require('../../assets/images/express-shipping-dark.png'),
  },
  regular: {
    light: require('../../assets/images/regular-shipping-light.png'),
    dark: require('../../assets/images/regular-shipping-dark.png'),
  },
};

export default function ShippingOptionsView(props: Props) {
  const [shippingType, setShippingType] = useState<ShippingType>(props.shippingType ?? 'express');

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
          marginBottom: 100,
        }}
      >
        <CustomText weight="500" style={{ fontSize: 14, marginBottom: 16 }}>
          Select your shipping option!
        </CustomText>
        <Pressable
          onPress={() => {
            setShippingType('express');
          }}
        >
          <Image
            source={
              shippingType === 'express' ? shippingImage.express.light : shippingImage.express.dark
            }
            style={{
              width: '100%',
              height: 160,
              marginBottom: 16,
            }}
            resizeMode="contain"
          />
        </Pressable>
        <Pressable
          onPress={() => {
            setShippingType('regular');
          }}
        >
          <Image
            source={
              shippingType === 'regular' ? shippingImage.regular.light : shippingImage.regular.dark
            }
            style={{
              width: '100%',
              height: 160,
              marginBottom: 32,
            }}
            resizeMode="contain"
          />
        </Pressable>
        {shippingType === 'express' ? (
          <>
            <CustomText weight="600" style={{ fontSize: 16, marginBottom: 8 }}>
              Express Shipping
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>&bull; $00.00 + Tax</CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; Shipping cost based on your country
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; Canada Shipping (3-7 business days)
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; International Shipping (10+ business days)
            </CustomText>
          </>
        ) : (
          <>
            <CustomText weight="600" style={{ fontSize: 16, marginBottom: 8 }}>
              Regular Shipping
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>&bull; $00.00 + Tax</CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; Shipping cost based on your country
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; Canada Shipping (3-7 business days)
            </CustomText>
            <CustomText style={{ fontSize: 14, marginLeft: 4 }}>
              &bull; International Shipping (10+ business days)
            </CustomText>
          </>
        )}
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            props.onNext(shippingType);
          }}
          textStyle={{ fontSize: 14 }}
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
}
