import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { StyleSheet } from 'react-native';
import Navigation from './navigation';
import { store } from './store';

import * as SplashScreen from 'expo-splash-screen';

import setupAPIInterceptors from './api/setupInterceptor';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import useCachedResources from './hooks/useCachedResources';

SplashScreen.preventAutoHideAsync();

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <GestureHandlerRootView style={styles.gestureHandlerRootView}>
          <SafeAreaProvider>
            <Navigation />
            <StatusBar style="auto" />
          </SafeAreaProvider>
        </GestureHandlerRootView>
      </Provider>
    );
  }
}

setupAPIInterceptors(store);

const styles = StyleSheet.create({
  gestureHandlerRootView: {
    flex: 1,
  },
});
