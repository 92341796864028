import { RootStackScreenProps } from "../types";
import { View, Text, StyleSheet, Pressable, Platform } from "react-native";
import AppContainer from "../components/AppContainer";
import AppHeader from "../components/AppHeader";
import CommonStyles from "../constants/CommonStyles";
import { AppButton } from "../components/AppButton";
import { AppGap } from "../components/AppGap";
import { Colors } from "../constants/Colors";
import { useState } from "react";
import { AppDrawer } from "../components/AppDrawer";
import { FontAwesome6 } from "@expo/vector-icons";
import FontFamilys from "../constants/FontFamilys";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AppTextInput } from "../components/AppTextInput";
import { DatePickerModal } from "react-native-paper-dates";
import { accountService } from "../services/MyAccount/AccountService";

type CancellationOptionCode = "LC" | "CP" | "EF" | "U" | "O" | "";

type CancellationOption = {
  label: string,
  value: CancellationOptionCode
}

export default function CloseAccountScreen({ navigation }: RootStackScreenProps<'CloseAccount'>) {
  const { CurrentBalance } = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [reasonCode, setReasonCode] = useState<CancellationOptionCode>("");
  const [error, setError] = useState("");
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  const isAndroid = Platform.OS === "android";

  const TIMESTAMP = " 00:00:00";
  const now = new Date();
  const minimumDateMs = now.setDate(now.getDate());
  const minimumDate = new Date(minimumDateMs);
  const [date, setDate] = useState(new Date(minimumDateMs));

  const cancellationOptions = [
    {
      label: "Leaving Canada",
      value: "LC",
    },
    {
      label: "Completed port-out",
      value: "CP",
    },
    {
      label: "Extra or excessive fees",
      value: "EF",
    },
    {
      label: "Unsatisfied with the service or network",
      value: "U",
    },
    {
      label: "Others",
      value: "O",
    },
  ] as Array<CancellationOption>;

  const onPressBack = () => {
    navigation.goBack();
  }

  const onPressShow = () => {
    if (!isAndroid) {
      setShowModal(true);
    }
  }

  const onDismissDate = () => {
    setShowModal(false);
  }

  const onConfirmDate = (params: any) => {
    setShowModal(false);
    setDate(params.date);
  }

  const onPressCancel = () => {
    if (drawerVisible) {
      setDrawerVisible(false);
      return;
    }
    navigation.goBack();
  }

  const onPressContinue = () => {
    if (CurrentBalance > 0) {
      setError("Please pay your remaining balance.");      
      return;
    } else if (!reasonCode) {
      setError("Please choose a reason for your cancellation.");
      return;
    } else if (!note) {
      setError("Please provide a brief note explaining your cancellation.");
      return;
    } else {
      setError("");
      setDrawerVisible(true);
    }
  }

  const onPressContinueWithTermination = () => {

    let dateString = [date.getFullYear(),(date.getMonth() + 1),date.getDate()].join("-");

    const postTerminationAsync = async () => {
      setLoading(true);
      const postTerminationResponse = await accountService.postTermination({
        terDate: dateString,
        reasonCode: reasonCode,
        note: note
      });

      if (!postTerminationResponse || !postTerminationResponse.data) {
        console.error("Something went wrong with postTerminationAsync() ");
      }

      if (postTerminationResponse && postTerminationResponse.data) {
        console.log("resolved: ", postTerminationResponse.data);
        // navigation.navigate("GenericSuccess", { message: "Your account has been terminated." })
      }
    }

    postTerminationAsync().catch(err => { 
      console.error(err);
      setError("Failed to terminate your plan. Please contact support.");
    }).finally(() => {
      setLoading(false);
    })
  }

  const CancellationOption = (props: { option: CancellationOption }) => {
    const isSelected = props.option.value === reasonCode;
    return (
      <Pressable style={styles.cancellationOption} onPress={() => setReasonCode(props.option.value)}>
        {isSelected ? <FontAwesome6 name="circle-dot" size={24} color="black" /> : <FontAwesome6 name="circle" size={24} color="black" />}
        <Text style={styles.cancellationText}>
          {props.option.label}
        </Text>
      </Pressable>
    )
  }

  return (
    <>
      <AppContainer>
        <AppHeader onPressBack={onPressBack} />
        <Text style={CommonStyles.title}>Terminate Plan</Text>
        <Text style={[ CommonStyles.futuraFont, styles.marginTop ]}>
          Are you sure you would like to terminate your service? Please note that you will not be able to port-out your number to another carrier if you terminate your service through your account area.
        </Text>
        <View style={styles.marginTop}>
          {cancellationOptions.map((option, index) => 
            <CancellationOption key={index} option={option} />
          )}
        </View>
        <View style={styles.marginTop}>
          <Text style={CommonStyles.inputLabel}>Note</Text>
          <View style={styles.smallMarginTop}>
            <AppTextInput value={note} onChangeText={(text) => setNote(text)}/>
          </View>
        </View>
        <View style={styles.smallMarginTop}>
          <Text style={CommonStyles.inputLabel}>Termination Date</Text> 
        </View>
        <View style={styles.datePickerContainer}>
          <Pressable onPress={onPressShow}>
            <Text style={styles.disabledTextInput}>{date.toLocaleDateString()}</Text>
          </Pressable>
          <DatePickerModal 
            validRange={{
              startDate: minimumDate
            }}
            locale="en"
            mode="single"
            visible={showModal && !isAndroid}
            date={date}
            onConfirm={onConfirmDate}
            onDismiss={onDismissDate}
          />
        </View>
        {error.length > 0 &&
          <Text style={[ CommonStyles.errorText, styles.marginTop ]}>{error}</Text>
        }
        <View style={CommonStyles.bottomContainer}>
          <AppButton text="Continue" color={Colors.red} textColor={Colors.white} onPress={onPressContinue}/>
          <AppGap size={20} direction="vertical"/>
          <AppButton text="Cancel" outline color={Colors.red} onPress={onPressCancel}/>
        </View>
      </AppContainer>
      <AppDrawer visible={drawerVisible} height={320} onClose={() => setDrawerVisible(false)}>
        <Text>Are you sure you want to report your phone as missing and suspend your account?</Text>
        <View style={styles.marginTop}>
          <AppButton text="Terminate Account" color={Colors.red} textColor={Colors.white} onPress={onPressContinueWithTermination} loading={loading}/>
        </View>
        <View style={styles.marginTop}>
          <AppButton text="Cancel" onPress={onPressCancel} outline color={Colors.red} />
        </View>
      </AppDrawer>
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 15
  },
  smallMarginTop: {
    marginTop: 10
  },
  cancellationOption: {
    ...CommonStyles.row,
    padding: 10
  },
  cancellationText: {
    fontFamily: FontFamilys.regular,
    paddingLeft: 4
  },
  datePickerContainer: {
    marginVertical: 10,
    alignContent: "flex-start"
  },
  disabledTextInput: {
    height: 50,
    backgroundColor: Colors.lightGrey,
    width: '100%',
    borderRadius: 15,
    margin: "auto",
    fontSize: 15,
    fontFamily: FontFamilys.regular,
    padding: 15,
    color: Colors.black,
    fontWeight: "300"
  },
})