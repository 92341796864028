import { View, Image, Pressable, Text, StyleSheet } from "react-native"
import CommonStyles from "../constants/CommonStyles"

interface AppHeaderProps {
  onPressLink?: () => void,
  onPressBack: () => void,
  linkText?: string,
  transparent?: boolean
}

export default function AppHeader(props: AppHeaderProps) {
  const hasLink = (props.linkText && props.onPressLink) ? true : false;

  return (
    <View style={{ ...CommonStyles.justifyRow, paddingVertical: 20 }}>
      <Pressable style={styles.backArrowContainer} onPress={props.onPressBack}>
        <Image source={props.transparent ? require("../assets/icons/white-back-arrow.png") : require("../assets/icons/back-arrow.png")} style={styles.backArrow}/>
      </Pressable>
      {hasLink ? (
        <View style={{ height: 20 }}>
          <Pressable onPress={props.onPressLink} style={styles.pressableText}>
            <Text style={props.transparent ? CommonStyles.whiteText : CommonStyles.greenText}>{props.linkText}</Text>
          </Pressable>
        </View>
      ) : (
        <View style={{ height: 20 }}/>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  backArrow: {
    flex: 1,
    width: 20,
    height: 10,
    resizeMode: "contain"
  },
  backArrowContainer: {
    width: 20,
    height: 10
  },
  pressableText:{
    flex: 1,
    alignItems: "center" 
  }
})