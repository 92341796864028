import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Colors } from '../../constants/Colors';

type Props = {
  loading: boolean;
};

const FullScreenLoader = ({ loading, ...props }: Props) => {
  return loading ? (
    <View style={styles.loaderContainer}>
      <ActivityIndicator color={Colors.white} size={'large'} />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  loaderContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 1,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FullScreenLoader;
