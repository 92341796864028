import { ScrollView, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import Button from '../../components/Button';

const PortOutDoneView = ({ onPress }: { onPress(option: string): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText
          weight="600"
          style={{ fontSize: 24, textAlign: 'center', color: Colors.errorRed }}
        >
          Port out requested
        </CustomText>
        <CustomText style={{ fontSize: 14, marginTop: 16, textAlign: 'center' }}>
          Your port-out request has been submitted.
        </CustomText>
        <CustomText style={{ fontSize: 14, marginTop: 16, textAlign: 'center' }}>
          The port-out account number will be sent to your email. It may take 2-3 business days for
          the account number to be issued.
        </CustomText>
        <CustomText style={{ fontSize: 14, marginTop: 16, textAlign: 'center' }}>
          Additionally, data usage may be restricted to calculate your usage.
        </CustomText>
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => onPress('BACK')}
          textStyle={{ fontSize: 14 }}
          color={Colors.errorRed}
        >
          Back to Main Menu
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.black}
          onPress={() => onPress('PLANS')}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Explore Other Plans
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

export default PortOutDoneView;
