import Checkbox from 'expo-checkbox';
import { SafeAreaView, View, Image, Text, Pressable, Platform } from 'react-native';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import { PropType } from './index';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import TextInput from '../../components/TextInput';
import HorizontalLine from '../../components/HorizontalLine';
import { validate } from '../../utilities/utilities';
import Button from '../../components/Button';

import AppleGoogleSSO from '../../components/AppleGoogleSSO';
import CountryToggle from '../../components/CountryToggle';
import AuthBackground from '../../components/utils/AuthBackgroud';
import { AppGap } from '../../components/AppGap';
import CustomText from '../../components/utils/CustomText';

export default ({
  onPressLogIn,
  navigation,
  emailText,
  setEmailTextError,
  setEmailText,
  // showError,
  emailTextError,
  passwordText,
  setPasswordText,
  setPasswordTextError,
  passwordTextError,
  rememberMe,
  setRememberMe,
  // setShowError,
  loading,
  logInError,
  appleGoogleFunctions,
}: PropType) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <AuthBackground />
        <AppGap direction="vertical" size={530} />
        <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
          <View
            style={{
              position: 'absolute',
              bottom: Platform.select({ android: 560, default: 540, ios: 580 }),
              zIndex: 8,
            }}
          >
            <Image
              source={require('../../assets/illustrations/Blue9.png')}
              style={{
                width: 120,
                height: Platform.select({ android: 180, default: 180, ios: 140 }),
              }}
              resizeMode="contain"
            />
          </View>
          <View style={{ width: '100%' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 50,
                marginBottom: 20,
              }}
            >
              <CustomText weight="500" style={{ fontSize: 24, fontWeight: '500' }}>
                Welcome Back!
              </CustomText>
              <CountryToggle />
            </View>
            <HorizontalLine style={{ marginBottom: 20 }} />
            <TextInput
              placeholder="Enter your email"
              value={emailText}
              onChangeText={(email: string) => {
                setEmailText(email);
                if (email) {
                  setEmailTextError('');
                }
              }}
              keyboardType="email-address"
              error={{
                error: emailTextError,
              }}
            />
            <TextInput
              placeholder="Password"
              eyeTopMargin={29}
              value={passwordText}
              onChangeText={(password: string) => {
                setPasswordText(password);
                if (password) {
                  setPasswordTextError('');
                }
              }}
              error={{
                error: passwordTextError,
              }}
              secureTextEntry={true}
              style={{ marginTop: 15 }}
            />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  value={rememberMe}
                  onValueChange={setRememberMe}
                  color={rememberMe ? Colors.canadaButtonColor : undefined}
                />
                <CustomText style={{ marginLeft: 5 }}>Remember me</CustomText>
              </View>
              <Pressable onPress={() => navigation.navigate('RecoverPassword')}>
                <CustomText
                  weight="500"
                  style={{ color: Colors.canadaButtonColor, fontWeight: '500' }}
                >
                  Forgot password?
                </CustomText>
              </Pressable>
            </View>
            <Button
              style={{ marginTop: 25 }}
              onPress={() => {
                let isError = false;
                if (emailText === '') {
                  setEmailTextError('Email can not be empty.');
                  isError = true;
                } else if (!validate('email', emailText)) {
                  setEmailTextError('Please Enter valid email address');
                  isError = true;
                }
                if (passwordText === '') {
                  setPasswordTextError('Password can not be empty.');
                  isError = true;
                }
                if (emailTextError || passwordTextError) {
                  isError = true;
                }
                if (isError) {
                  return;
                }
                onPressLogIn(emailText, passwordText, rememberMe);
              }}
              loadingStatus={loading}
              disabled={loading}
              textStyle={{ fontSize: 14 }}
            >
              Sign in
            </Button>
            {logInError !== '' && (
              <CustomText
                style={{
                  color: Colors.errorRed,
                  marginTop: 4,
                  textAlign: 'center',
                }}
              >
                {logInError}
              </CustomText>
            )}
            <Button
              style={{ marginTop: 10 }}
              color={Colors.backgroundGrey}
              textColor={Colors.black}
              onPress={() => navigation.replace('Join')}
            >
              <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
                Sign Up
              </CustomText>
            </Button>
            <CustomText
              style={{
                textAlign: 'center',
                color: Colors.inputTextPlaceholderColor,
                marginTop: 10,
                fontSize: 14,
              }}
            >
              or continue with
            </CustomText>
            <AppleGoogleSSO
              setIsLoadingAPI={appleGoogleFunctions.setIsLoadingAPI}
              propsKey={'sign-in'}
            />
            <Button
              size={'FULL'}
              color={Colors.noColor}
              textColor={Colors.greyColor}
              style={{
                padding: 5,
                marginTop: 20,
                marginBottom: 15,
                width: 'auto',
              }}
              onPress={() => {
                navigation.navigate('Root');
              }}
            >
              Continue as a guest
            </Button>
          </View>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};
