import { RootStackScreenProps } from '../../types';
import { useEffect, useState } from 'react';
import { ParsedPlan } from '../../types/models/ParsedPlan';
import { PlanCategory } from '../../types/enums/PlanCategory';
import { PlanLengthType } from '../../types/enums/PlanLengthType';
import { Image, ImageBackground, Pressable, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import PageHeader from '../../components/UI/Header/PageHeader';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { HERO_GRADIENT, SUSPENSION_TYPE } from '../../constants/constants';
import CustomText from '../../components/utils/CustomText';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import ExploreProductHero from '../../components/UI/Hero/ExploreProductHero';

export default function ShopScreen({ navigation }: RootStackScreenProps<'Shop'>) {
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  let gradientInfo = HERO_GRADIENT.default;
  if (accountProfileCA.Suspended && accountProfileCA.SuspensionReason) {
    gradientInfo = HERO_GRADIENT[SUSPENSION_TYPE[accountProfileCA.SuspensionReason]];
  }

  const [prepaidPlans, setPrepaidPlans] = useState<ParsedPlan[]>([]);
  const [postpaidPlans, setPostpaidPlans] = useState<ParsedPlan[]>([]);
  const [selectedPlanCategory, setSelectedPlanCategory] = useState<PlanCategory>(
    PlanCategory.Monthly
  );
  const [selectedPlanLength, setSelectedPlanLength] = useState<PlanLengthType>(
    PlanLengthType.ThirtyDays
  );
  const selectedPlans =
    selectedPlanCategory === PlanCategory.Monthly
      ? postpaidPlans
      : prepaidPlans.filter((plan) => {
          if (selectedPlanLength === PlanLengthType.NinetyDays) {
            return plan.planTypeD === '90';
          } else if (selectedPlanLength === PlanLengthType.ThirtyDays) {
            return plan.planTypeD === '30';
          } else if (selectedPlanLength === PlanLengthType.Biweekly) {
            return plan.planTypeD === '14';
          } else if (selectedPlanLength === PlanLengthType.Weekly) {
            return plan.planTypeD === '7';
          }
        });

  return (
    <SafeAreaView style={{ flex: 1, paddingTop: StatusBar.currentHeight }}>
      <PageHeader />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: Colors.white,
        }}
      >
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: 80,
          }}
        >
          <ExploreProductHero />
        </AppGradientContainer>
        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
            paddingHorizontal: 20,
            paddingTop: 20,
          }}
        >
          <Pressable
            onPress={() => {
              navigation.push('Plans');
            }}
          >
            <Image
              source={require('../../assets/images/canada-plans.png')}
              style={{ width: '100%', height: 130, borderRadius: 8 }}
              resizeMode="cover"
            />
          </Pressable>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
