import { View } from 'react-native';
import { Colors } from '../../constants/Colors';
import { MaterialCommunityIcons, Octicons } from '@expo/vector-icons';
import CustomText from '../../components/utils/CustomText';
import { DATE_FORMAT } from '../../constants/constants';
import moment from 'moment';

const SentInTransitTimeline = ({ date }: { date: Date }) => {
  return (
    <View style={{ borderRadius: 8, backgroundColor: Colors.backgroundGrey }}>
      {/* Current-Status First Item - Start */}
      <View
        style={{
          padding: 16,
          position: 'relative',
          flexDirection: 'row',
        }}
      >
        <View style={{ position: 'relative' }}>
          <Octicons
            name="dot-fill"
            size={16}
            color={Colors.canadaButtonColor}
            style={{ marginLeft: 4 }}
          />
          <View
            style={{
              position: 'absolute',
              height: 64,
              width: 2,
              top: 12,
              left: 7,
              backgroundColor: Colors.canadaButtonColor,
            }}
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <CustomText weight="500" style={{ color: Colors.TextBlack, fontSize: 12 }}>
            {moment(date).format(DATE_FORMAT)}
          </CustomText>
          <CustomText weight="500" style={{ color: Colors.TextBlack, fontSize: 14 }}>
            Queued
          </CustomText>
        </View>
      </View>
      {/* Current-Status First Item - End   */}
      {/* Current-Status Second Item - Start   */}
      <View
        style={{
          padding: 16,
          position: 'relative',
          flexDirection: 'row',
        }}
      >
        <View style={{ position: 'relative' }}>
          <View
            style={{
              position: 'absolute',
              height: 53,
              width: 2,
              top: 5,
              left: 7,
              backgroundColor: Colors.canadaButtonColor,
            }}
          />
          <Octicons
            name="dot-fill"
            size={16}
            color={Colors.canadaButtonColor}
            style={{ marginLeft: 4 }}
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <CustomText weight="500" style={{ color: Colors.TextBlack, fontSize: 14 }}>
            Ready
          </CustomText>
        </View>
      </View>
      {/* Current-Status Second Item - End   */}
      {/* Current-Status Third Item - Start   */}
      <View
        style={{
          padding: 16,
          position: 'relative',
          flexDirection: 'row',
        }}
      >
        <View style={{ position: 'relative' }}>
          <View
            style={{
              position: 'absolute',
              height: 53,
              width: 2,
              top: 5,
              left: 7,
              backgroundColor: Colors.canadaButtonColor,
            }}
          />
          <MaterialCommunityIcons
            name="check-circle"
            size={16}
            color={Colors.canadaButtonColor}
            style={{ backgroundColor: Colors.backgroundGrey, borderRadius: 50 }}
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <CustomText weight="500" style={{ color: Colors.TextBlack, fontSize: 14 }}>
            Sent/In-Transit
          </CustomText>
          <CustomText style={{ color: Colors.TextBlack, fontSize: 14 }}>
            Your order is on your way
          </CustomText>
        </View>
      </View>
      {/* Current-Status Third Item - End   */}
      {/* Current-Status Last Item - Start   */}
      <View
        style={{
          padding: 16,
          position: 'relative',
          flexDirection: 'row',
        }}
      >
        <View style={{ marginLeft: 4 }}>
          <Octicons name="dot-fill" size={16} color={Colors.HorizonalLineDefaultColor} />
          <View
            style={{
              position: 'absolute',
              height: 22,
              width: 2,
              top: -16,
              left: 3,
              backgroundColor: Colors.HorizonalLineDefaultColor,
            }}
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <CustomText
            weight="500"
            style={{ color: Colors.HorizonalLineDefaultColor, fontSize: 14 }}
          >
            Completed
          </CustomText>
        </View>
      </View>
      {/* Current-Status Last Item - End   */}
    </View>
  );
};

export default SentInTransitTimeline;
