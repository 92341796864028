import LottieView from 'lottie-react-native';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import {
  SafeAreaView,
  Image,
  View,
  Dimensions,
  Pressable,
  ScrollView,
  ImageSourcePropType,
  Platform,
  Alert,
} from 'react-native';
import HorizontalLine from '../../components/HorizontalLine';
import Button from '../../components/Button';
import { AppGap } from '../../components/AppGap';
import { PaymentType } from '../../types/types';
import { PaymentMethods } from '../../types/enums/enums';
import { useEffect, useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { activationService } from '../../services/MyAccount/ActivationService';
import { BoxOnePrepaidPlan } from '../../types/models/BoxOnePrepaidPlan';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_FORMAT_FOR_API,
  MOBILE_CANCEL_URL,
  MOBILE_RETURN_URL,
} from '../../constants/constants';
import { setIsLoading } from '../../store/app';
import { prepaidService } from '../../services/MyAccount/PrepaidService';

const { width, height } = Dimensions.get('window');

const CheckMarkIcon = (
  <View
    style={{
      position: 'absolute',
      top: -10,
      right: -8,
      backgroundColor: Colors.white,
      borderRadius: 50,
    }}
  >
    <AntDesign name="checkcircle" size={24} color={Colors.canadaButtonColor} />
  </View>
);

type PaymentOptionProps = {
  selected: boolean;
  onPress(): void;
  image: ImageSourcePropType;
  title: string;
};

const PaymentOption = ({ selected, onPress, image, title }: PaymentOptionProps) => {
  return (
    <Pressable
      style={{
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 14,
        marginHorizontal: 10,
        position: 'relative',
        backgroundColor: selected ? Colors.SelectLineItemHighlightColor : Colors.white,
        borderRadius: 8,
      }}
      onPress={onPress}
    >
      {selected ? CheckMarkIcon : null}
      <Image source={image} resizeMode="contain" style={{ width: 44, height: 44 }} />
      <CustomText
        weight="500"
        style={{
          textAlign: 'center',
          marginTop: 5,
          fontSize: 14,
          fontWeight: '500',
          color: Colors.QuickActionTextColor,
          width: 60,
        }}
      >
        {title}
      </CustomText>
    </Pressable>
  );
};

export default function PrepaidPlanCheckoutScreen({
  navigation,
  route,
}: RootStackScreenProps<'PrepaidPlanCheckout'>) {
  const dispatch = useDispatch();
  const { planId, update, data } = route.params;

  const { CurrentGst, CurrentPst, CurrentPlan_pfId, CurrentPlan_Days, CurrentBalance, EndDate } =
    useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const { activeCnum } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [plan, setPlan] = useState<BoxOnePrepaidPlan>();
  const [error, setError] = useState<null | string>(null);
  const actionType: 'PLAN CHANGE' | 'PLAN EXTENSION' | 'DATA TOP UP' =
    update === 'extension'
      ? 'PLAN EXTENSION'
      : update === 'data-top-up'
      ? 'DATA TOP UP'
      : 'PLAN CHANGE';

  //   CurrentGst
  // CurrentPst
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    paymentTypeSlug: PaymentType;
    paymentType: PaymentMethods;
  } | null>(null);

  const effectiveDate = moment(EndDate).add(1, 'days').format(DATE_FORMAT);
  const endDate = moment(effectiveDate)
    .add(Number(plan?.PlanTypeD) - 1, 'days')
    .format(DATE_FORMAT); // -1 because date is inclusive

  const PaymentOptionList = [
    {
      title: 'Credit Card',
      image: require('../../assets/icons/credit-card.png'),
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'CREDIT_CARD',
          paymentType: PaymentMethods.CREDIT_CARD,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'CREDIT_CARD',
    },
    {
      title: 'Google Pay',
      image: require('../../assets/icons/google-pay.png'),
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'GOOGLE_PAY',
          paymentType: PaymentMethods.GOOGLE_PAY,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'GOOGLE_PAY',
    },
    // {
    //   title: 'WeChat Pay',
    //   image: require('../../assets/icons/wechat-pay.png'),
    //   onPress: () => {
    //     setSelectedPaymentMethod({
    //       paymentTypeSlug: 'WECHAT_PAY',
    //       paymentType: PaymentMethods.WECHAT_PAY,
    //     });
    //   },
    //   selected: selectedPaymentMethod?.paymentTypeSlug === 'WECHAT_PAY',
    // },
    {
      title: 'PayPal',
      image: require('../../assets/icons/paypal.png'),
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'PAYPAL',
          paymentType: PaymentMethods.PAYPAL,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'PAYPAL',
    },
    // {
    //   title: 'AliPay',
    //   image: require('../../assets/icons/alipay.jpg'),
    //   onPress: () => {
    //     setSelectedPaymentMethod({
    //       paymentTypeSlug: 'ALIPAY',
    //       paymentType: PaymentMethods.ALIPAY,
    //     });
    //   },
    //   selected: selectedPaymentMethod?.paymentTypeSlug === 'ALIPAY',
    // },
  ];

  useEffect(() => {
    if (
      !planId &&
      (!update ||
        !['extension', 'data-top-up'].includes(update) ||
        (update === 'data-top-up' && (Number(data) <= 0 || Number(data) > 5)))
    ) {
      navigation.navigate('Root');
      return;
    }
    let _planId = planId ?? 0;
    if (actionType === 'PLAN EXTENSION') {
      _planId = CurrentPlan_pfId;
    }
    const getPlan = async () => {
      try {
        setLoading(true);
        const response = await activationService.prepaidPlanById({
          data: {
            PlanId: _planId,
          },
        });
        if (response.data.length === 0) {
          Platform.OS === 'web'
            ? alert('Please select a valid plan.')
            : Alert.alert('Invalid Plan', 'Please select a valid plan.');
          navigation.navigate('Root');
          return;
        }
        const plan = response.data[0];
        const price = plan.Fee * (1 + CurrentGst + CurrentPst) + CurrentBalance;
        setTotalPrice(price);
        setPlan(plan);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (actionType === 'DATA TOP UP') {
      const price = data! * 15 * (1 + CurrentGst + CurrentPst);
      setTotalPrice(price);
      setLoading(false);
    } else {
      getPlan().catch((err) => console.error(err));
    }
  }, [planId, update]);

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <SafeAreaView style={{ flex: 1, height: '100%' }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            padding: 20,
            backgroundColor: Colors.white,
            marginBottom: 150,
          }}
        >
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 16 }}
          >
            Payment Info
          </CustomText>
          {actionType !== 'DATA TOP UP' ? (
            <View style={{ marginBottom: 30 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  Effective Date:{' '}
                </CustomText>
                <CustomText> {effectiveDate}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  New Expiration Date:{' '}
                </CustomText>
                <CustomText> {endDate}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  Plan Duration:{' '}
                </CustomText>
                <CustomText> {plan?.PlanTypeD} days </CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  Plan Fee:{' '}
                </CustomText>
                <CustomText> ${plan?.Fee.toFixed(2)}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  HST/GST:{' '}
                </CustomText>
                <CustomText> {CurrentGst * 100}%</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  PST/QST:{' '}
                </CustomText>
                <CustomText> {CurrentPst * 100}%</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  Current Balance:{' '}
                </CustomText>
                <CustomText> ${CurrentBalance.toFixed(2)}</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  Subtotal:{' '}
                </CustomText>
                <CustomText> ${(plan?.Fee ?? 0 + CurrentBalance).toFixed(2)}</CustomText>
              </View>
            </View>
          ) : (
            <View style={{ marginBottom: 30 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  HST/GST:{' '}
                </CustomText>
                <CustomText> {CurrentGst * 100}%</CustomText>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  PST/QST:{' '}
                </CustomText>
                <CustomText> {CurrentPst * 100}%</CustomText>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <CustomText weight="500" style={{}}>
                  Subtotal:{' '}
                </CustomText>
                <CustomText> ${(Number(data) * 15).toFixed(2)}</CustomText>
              </View>
            </View>
          )}
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 16 }}
          >
            Payment Methods
          </CustomText>

          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 5,
            }}
          >
            {PaymentOptionList.map((paymentOption) => (
              <PaymentOption {...paymentOption} key={paymentOption.title} />
            ))}
          </View>
          {error ? (
            <CustomText
              weight="500"
              style={{
                backgroundColor: 'rgba(255,0,0,0.5)',
                textAlign: 'center',
                color: Colors.white,
                padding: 14,
                borderRadius: 14,
                margin: 'auto',
                fontSize: 18,
                marginTop: 20,
              }}
            >
              {error}
            </CustomText>
          ) : null}
        </ScrollView>

        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <CustomText weight="600" style={{ fontSize: 16, color: Colors.TextBlack }}>
              Total Amount:
            </CustomText>
            <CustomText weight="600" style={{ fontSize: 16, color: Colors.canadaButtonColor }}>
              ${totalPrice.toFixed(2)}
            </CustomText>
          </View>
          <Button
            style={{ marginTop: 32 }}
            onPress={async () => {
              try {
                dispatch(setIsLoading(true));
                setError(null);
                const response = await prepaidService.topUpExtension({
                  data: {
                    returnUrl: MOBILE_RETURN_URL,
                    cancelUrl: MOBILE_CANCEL_URL,
                    payload: {
                      amount: totalPrice.toFixed(2),
                      cnum: activeCnum,
                      currency: 'CAD',
                      extensionStartDate:
                        actionType === 'DATA TOP UP'
                          ? ''
                          : moment(effectiveDate).format(DATE_FORMAT_FOR_API),
                      extensionEndDate:
                        actionType === 'DATA TOP UP'
                          ? ''
                          : moment(endDate).format(DATE_FORMAT_FOR_API),
                      extensionSubtotal:
                        actionType === 'DATA TOP UP' ? 0 : plan!.Fee + CurrentBalance,
                      paymentMethod:
                        selectedPaymentMethod?.paymentType === PaymentMethods.GOOGLE_PAY
                          ? PaymentMethods.CREDIT_CARD
                          : selectedPaymentMethod?.paymentType!,
                      systag: 'mobileapp/topup',
                      topUpCapacity: actionType === 'DATA TOP UP' ? data! : 0,
                      duration: actionType === 'PLAN EXTENSION' ? Number(plan?.PlanTypeD) : null,
                      newPfID: actionType === 'PLAN CHANGE' ? plan?.PlanId : null,
                    },
                  },
                });
                if (response.data.substring(0, 6) === 'Failed') {
                  return setError('Failed to complete payment');
                }

                if (Platform.OS !== 'web') {
                  navigation.navigate('PaymentWebView', {
                    url: response.data,
                    paymentType: selectedPaymentMethod?.paymentType!,
                  });
                } else {
                  alert('This need to update so that, it will work on web as well');
                }
              } catch (e: any) {
              } finally {
                dispatch(setIsLoading(false));
              }
            }}
            textStyle={{ fontSize: 14 }}
            disabled={selectedPaymentMethod === null}
          >
            Pay
          </Button>
        </View>
      </SafeAreaView>
    </>
  );
}
