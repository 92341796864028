import { RootStackScreenProps } from "../types";
import { View, Text, StyleSheet } from "react-native";
import AppContainer from "../components/AppContainer";
import AppHeader from "../components/AppHeader";
import CommonStyles from "../constants/CommonStyles";
import { AppTextInput } from "../components/AppTextInput";
import { AppGap } from "../components/AppGap";
import { useState } from "react";
import { AppButton } from "../components/AppButton";
import { Colors } from "../constants/Colors";
import { AppDrawer } from "../components/AppDrawer";
import { accountService } from "../services/MyAccount/AccountService";

export default function LostOrStolenScreen({ navigation }: RootStackScreenProps<'LostOrStolen'>) {
  const [phoneNum, setPhoneNum] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  const onPressBack = () => {
    navigation.goBack();
  }

  const onPressCancel = () => {
    navigation.goBack();
  }

  const onPressSuspend = () => {
    setDrawerVisible(true);
  }

  const onPressYes = () => {

    const postSuspensionAsync = async () => {
      setLoading(true);

      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let date = now.getDate();
      let hours = now.getHours();
      if (hours >= 18) {
        date++;
      }
      let query = year + "-" + month + "-" + date;

      const postSuspensionResponse = await accountService.postSuspension({
        sdate: query,
        edate: query,
        reason: "lost"
      });

      if (!postSuspensionResponse || !postSuspensionResponse.data) {
        console.error("Something went wrong with postSuspensionAsync()");
      }

      if (postSuspensionResponse && postSuspensionResponse.data) {
        console.log("TEST PASSED: ", postSuspensionResponse.data);
        setDrawerVisible(false);
        navigation.navigate("GenericSuccess", { message: "Your service has been suspended." });
      }
    }

    postSuspensionAsync().catch(err => { 
      console.error(err);
      setError("Failed to suspend your plan. Please contact support.");
    }).finally(() => {
      setLoading(false);
    })
  }

  const onPressNo = () => {
    setDrawerVisible(false);
  }
  
  return (
    <>
      <AppContainer>
        <AppHeader onPressBack={onPressBack} />
        <Text style={CommonStyles.title}>Lost Or Stolen</Text>
        <AppGap size={25} direction="vertical"/>
        <Text style={CommonStyles.subtitle}>Lost your phone?</Text>
        <Text style={[ CommonStyles.subtitle, styles.marginTop ]}>Suspend your service and keep your number secure.</Text>
        {/* <View style={styles.inputContainer}>
          <AppTextInput maxLength={10} numbersOnly onChangeText={(text) => setPhoneNum(text)} value={phoneNum}/>
        </View> */}
        {error.length > 0 &&
          <Text style={[ CommonStyles.errorText, styles.marginTop ]}>{error}</Text>
        }
        <View style={CommonStyles.bottomContainer}>
          <AppButton text="Suspend" onPress={onPressSuspend}/>
          <AppGap size={20} direction="vertical"/>
          <AppButton text="Cancel" outline color={Colors.red} onPress={onPressCancel}/>
        </View>
      </AppContainer>
      <AppDrawer visible={drawerVisible} height={320} onClose={() => setDrawerVisible(false)}>
        <Text>Are you sure you want to report your phone as missing and suspend your account?</Text>
        <View style={styles.marginTop} >
          <AppButton text="Yes" onPress={onPressYes} loading={loading}/>
        </View>
        <View style={styles.marginTop}>
          <AppButton text="No" onPress={onPressNo} outline color={Colors.red} />
        </View>
      </AppDrawer>
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25
  },
  smallMarginTop: {
    marginTop: 10
  },
  inputContainer: {
    marginVertical: 10
  }
})