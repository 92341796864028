import { View, Text, StyleSheet, Pressable } from "react-native";
import CommonStyles from "../constants/CommonStyles";
import { Colors } from "../constants/Colors";
import AppContainer from "../components/AppContainer";
import AppHeader from "../components/AppHeader";
import { RootStackParamList, RootStackScreenProps } from "../types";

interface HoldsLink {
  name: string,
  dest: keyof RootStackParamList['Root']
}

export default function TemporaryHoldsScreen({ navigation }: RootStackScreenProps<'TemporaryHolds'>) {
  const onPressBack = () => {
    navigation.goBack();
  }

  const holdsLinks = [
    {
      name: "Vacation Hold",
      dest: "VacationHold"
    },
    {
      name: "Lost or Stolen",
      dest: "LostOrStolen"
    },
    {
      name: "Close Account",
      dest: "CloseAccount"
    },
  ] as Array<HoldsLink>

  // for setting background colors of holds cards
  const getColorFromIndex = (index: number) => {
    switch (index) {
      case 0:
        return Colors.gradientBlue;
      default:
        return Colors.green;
    }
  }

  const HoldsCard = (props: { index: number, holdsLink: HoldsLink  }) => {
    const handleOnPress = () => {
      navigation.navigate(props.holdsLink.dest)
    }



    return (
      <Pressable style={[ styles.holdsCard, { backgroundColor: getColorFromIndex(props.index) } ]} onPress={handleOnPress}>
        <Text style={[CommonStyles.whiteText, CommonStyles.subtitle]}>{props.holdsLink.name}</Text>
      </Pressable>
    )
  }
  
  return (
    <AppContainer>
      <AppHeader onPressBack={onPressBack}/>
      <Text style={CommonStyles.title}>Temporary Holds</Text>
      <View style={[ styles.marginTop, { flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" } ]}>
        {holdsLinks.map((link, index) => <HoldsCard key={index} index={index} holdsLink={link}/> )}
      </View>
    </AppContainer>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25
  },
  holdsCard: {
    ...CommonStyles.card,
    height: 140,
    width: 140,
    marginBottom: 15
  }
})