import { Pressable, SafeAreaView, StatusBar } from 'react-native';
import { RootStackScreenProps } from '../../types';
import GuestView from './GuestView';
import NoPlanView from './NoPlanView';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ActiveUserView from './ActiveUserView';
import { MaterialIcons } from '@expo/vector-icons';
import { Colors } from '../../constants/Colors';
import { CrispChatAction } from '../../utilities/functions';

export default function HomeScreen({ navigation }: RootStackScreenProps<'Home'>) {
  const { loggedIn, accounts, id, email, firstName, lastName } = useSelector(
    (state: RootState) => state.user
  );
  const { PhoneNumber } = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  return (
    <SafeAreaView style={{ flex: 1, paddingTop: StatusBar.currentHeight }}>
      {!loggedIn ? <GuestView /> : accounts.length === 0 ? <NoPlanView /> : <ActiveUserView />}
      <Pressable
        style={{
          borderRadius: 20,
          height: 40,
          width: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.canadaButtonColor,
          position: 'absolute',
          right: 14,
          bottom: 14,
        }}
        onPress={() => {
          CrispChatAction(loggedIn, id, email, firstName, lastName, PhoneNumber);
        }}
      >
        <MaterialIcons name="support-agent" size={24} color={Colors.white} />
      </Pressable>
    </SafeAreaView>
  );
}
