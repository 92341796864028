import { SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { Colors } from '../../constants/Colors';
import DropDownPicker from 'react-native-dropdown-picker';
import { DATE_FORMAT, InvoiceDateMonth, MONTHS } from '../../constants/constants';
import { Picker } from '@react-native-picker/picker';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setIsLoading } from '../../store/app';
import { accountService } from '../../services/MyAccount/AccountService';
import CustomText from '../../components/utils/CustomText';
import { Feather } from '@expo/vector-icons';
import { InvoiceInfo } from '../../types/models/InvoiceInfo';

const InvoicesScreen = () => {
  const date = moment();
  const dispatch = useDispatch();
  const [selectedMonth, setSelectedMonth] = useState(
    date.month() + (date.isSameOrAfter(moment(date).date(15)) ? 1 : 0)
  );
  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();
  const [selectedYear, setSelectedYear] = useState(date.year());
  const [error, setError] = useState<string | null>(null);
  const [invoice, setInvoice] = useState<InvoiceInfo | null>(null);

  const years = Array.from({ length: date.year() - 2013 + 1 }, (_, index) => 2013 + index);

  const fetchInvoice = async () => {
    try {
      dispatch(setIsLoading(true));
      const response = await accountService.invoiceInfoByYearMonth(selectedMonth, selectedYear);
      if (response.data.InvoiceDetail.length === 0) {
        setInvoice(null);
        return setError('No Invoice Found');
      }
      setError(null);
      setMonth(selectedMonth);
      setYear(selectedYear);
      setInvoice(response.data);
    } catch (error) {
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        height: '100%',
        marginTop: StatusBar.currentHeight,
        backgroundColor: Colors.white,
        padding: 20,
      }}
    >
      <ScrollView>
        <View
          style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 8 }}
        >
          <Picker
            style={{
              backgroundColor: Colors.backgroundGrey,
              paddingVertical: 6,
              paddingHorizontal: 8,
              borderRadius: 4,
              borderWidth: 0,
            }}
            selectedValue={selectedYear}
            onValueChange={(itemValue) => setSelectedYear(itemValue)}
          >
            {years.map((y) => (
              <Picker.Item label={y.toString()} value={y} key={y} />
            ))}
          </Picker>
          <Picker
            style={{
              backgroundColor: Colors.backgroundGrey,
              paddingVertical: 6,
              paddingHorizontal: 8,
              borderRadius: 4,
              borderWidth: 0,
            }}
            selectedValue={selectedMonth}
            onValueChange={(itemValue) => setSelectedMonth(itemValue)}
          >
            {MONTHS.map((m, i) => (
              <Picker.Item label={m} value={i + 1} key={m} />
            ))}
          </Picker>
          <Button
            style={{
              paddingVertical: 3,
              paddingHorizontal: 6,
              borderRadius: 4,
            }}
            textStyle={{
              fontSize: 14,
            }}
            onPress={fetchInvoice}
          >
            Get
          </Button>
        </View>
        {error ? (
          <CustomText
            weight="500"
            style={{
              color: Colors.errorRed,
              fontSize: 24,
              textAlign: 'center',
              marginTop: 100,
            }}
          >
            {error}
          </CustomText>
        ) : invoice ? (
          <View
            style={{
              marginTop: 20,
            }}
          >
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                backgroundColor: Colors.backgroundGrey,
                color: Colors.TextBlack,
                paddingVertical: 10,
                paddingHorizontal: 20,
              }}
            >
              {year}
            </CustomText>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 16,
                marginTop: 16,
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.backgroundGrey,
                  padding: 12,
                  borderRadius: 50,
                }}
              >
                <Feather name="file-text" size={20} color={Colors.ActionLinkColor} />
              </View>
              <View style={{ flex: 1 }}>
                <CustomText weight="500" style={{ fontSize: 16 }}>
                  {MONTHS[month! - 1]} Invoice
                </CustomText>
                <CustomText weight="300" style={{ fontSize: 12 }}>
                  {moment(`${year}-${month}-15`).format(DATE_FORMAT)} Issued
                </CustomText>
              </View>
              <CustomText weight="500" style={{ fontSize: 14, color: Colors.canadaButtonColor }}>
                ${invoice.TotalAmount.toFixed(2)}
              </CustomText>
            </View>
            <View
              style={{
                backgroundColor: Colors.backgroundGrey,
                paddingVertical: 12,
                paddingHorizontal: 20,
                gap: 12,
                borderRadius: 16,
                marginTop: 16,
              }}
            >
              {invoice.InvoiceDetail.map((id, i) => (
                <View
                  key={i}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <CustomText
                    weight="500"
                    style={{ fontSize: 12, textTransform: 'capitalize', color: Colors.greyColor }}
                  >
                    {id.ItemName}
                  </CustomText>
                  <CustomText weight="500" style={{ fontSize: 14, color: Colors.TextBlack }}>
                    ${id.ItemAmount.toFixed(2)}
                  </CustomText>
                </View>
              ))}
              <Button
                style={{
                  paddingVertical: 8,
                }}
                textStyle={{
                  fontWeight: '600',
                  fontSize: 14,
                }}
              >
                Download Full PDF Invoice
              </Button>
            </View>
          </View>
        ) : null}
      </ScrollView>
    </SafeAreaView>
  );
};

export default InvoicesScreen;
