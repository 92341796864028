import {
  View,
  StyleSheet,
  SafeAreaView,
  StatusBar,
  ScrollView,
  Image,
  Pressable,
} from 'react-native';
import { RootStackScreenProps } from '../../types';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { useState } from 'react';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import Button from '../../components/Button';
import { authService } from '../../services/SSO/AuthService';
import { SystemCodes } from '../../types/enums/SystemCodes';
import { updateProfile } from '../../store/user';

export default function EditNameScreen({ navigation }: RootStackScreenProps<'EditName'>) {
  const dispatch = useDispatch();
  const { firstName, lastName, profileImage } = useSelector((state: RootState) => state.user);

  const [newFirstname, setNewFirstname] = useState<string>(firstName);
  const [newLastname, setNewLastname] = useState<string>(lastName);
  const [newProfileImage, setNewProfileImage] = useState<number>(profileImage);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const updateProfileInfo = async () => {
    setLoading(true);
    try {
      const updateProfileResponse = await authService.updateProfile({
        data: {
          firstName: newFirstname,
          lastName: newLastname,
          systemCode: SystemCodes.CA_SELFSERVE,
          profileImage: newProfileImage,
        },
      });
      if (!updateProfileResponse?.data?.success) {
        console.error(
          'Something went wrong with resendActivationCodeAsync():',
          updateProfileResponse
        );
        setError('Sorry, something went wrong.');
      } else {
        dispatch(updateProfile(updateProfileResponse.data.data.profile));
        setError('');
      }
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <SafeAreaView
        style={{
          flex: 1,
          height: '100%',
          marginTop: StatusBar.currentHeight,
          backgroundColor: Colors.white,
        }}
      >
        <ScrollView style={{ padding: 20, marginBottom: 100 }}>
          <CustomText
            style={{ color: Colors.greyColor, fontSize: 12, fontWeight: '500' }}
            weight="500"
          >
            Profile Image
          </CustomText>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 18, marginTop: 16 }}>
            <Pressable onPress={() => setNewProfileImage(0)}>
              <Image
                source={require('../../assets/icons/profile/profile-balloon.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 0
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(1)}>
              <Image
                source={require('../../assets/icons/profile/profile-star.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 1 || newProfileImage === -1
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(2)}>
              <Image
                source={require('../../assets/icons/profile/profile-diamond.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 2
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(3)}>
              <Image
                source={require('../../assets/icons/profile/profile-green-star.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 3
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(4)}>
              <Image
                source={require('../../assets/icons/profile/profile-money.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 4
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(5)}>
              <Image
                source={require('../../assets/icons/profile/profile-heart.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 5
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(6)}>
              <Image
                source={require('../../assets/icons/profile/profile-person.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 6
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
            <Pressable onPress={() => setNewProfileImage(7)}>
              <Image
                source={require('../../assets/icons/profile/profile-ribbon.png')}
                style={[
                  styles.profileImage,
                  newProfileImage === 7
                    ? { borderColor: Colors.MainOrange }
                    : { borderColor: Colors.noColor },
                ]}
                resizeMode="contain"
              />
            </Pressable>
          </View>
          <InputWithTitle
            title="First Name"
            inputValue={newFirstname}
            onInputChange={setNewFirstname}
          />
          <InputWithTitle
            title="Last Name"
            inputValue={newLastname}
            onInputChange={setNewLastname}
          />
          {error ? (
            <View style={{ marginVertical: 14 }}>
              <CustomText
                weight="500"
                style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
              >
                {error}
              </CustomText>
            </View>
          ) : null}
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            disabled={loading}
            loadingStatus={loading}
            onPress={() => {
              updateProfileInfo();
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            Update Name
          </Button>
        </View>
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  profileImage: {
    height: 70,
    width: 70,
    borderWidth: 3,
    borderRadius: 70,
  },
});
