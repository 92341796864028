import { Image, StyleSheet, View } from 'react-native';

const AuthBackground = () => {
  return (
    <>
      <View
        style={{
          alignItems: 'center',
          height: 100,
          display: 'flex',
          position: 'absolute',
          top: 40,
          left: 0,
          right: 0,
          minHeight: 300,
        }}
      >
        <Image
          source={require('../../assets/backgrounds/auth-bg.png')}
          style={styles.authBg}
          resizeMode="cover"
        />
        <Image
          source={require('../../assets/images/logo_white.png')}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  authBg: {
    position: 'absolute',
    top: -60,
    width: '100%',
    zIndex: 0,
    aspectRatio: 1.4,
  },
  logo: {
    marginTop: 20,
    width: 150,
    height: 75,
  },
});

export default AuthBackground;
