import { Colors } from '../../constants/Colors';
import { Image, Platform, Pressable, SafeAreaView, ScrollView, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import Button from '../../components/Button';
import { SIMType } from '.';
import { useEffect, useState } from 'react';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { DateTimePickerAndroid, DateTimePickerEvent } from '@react-native-community/datetimepicker';
import { DatePickerModal, enGB, registerTranslation } from 'react-native-paper-dates';
import { Entypo, Feather } from '@expo/vector-icons';
import moment from 'moment';
import { validate } from '../../utilities/utilities';
import { AppDrawer } from '../../components/AppDrawer';
import { DATE_FORMAT, provinces } from '../../constants/constants';
import { ProvinceInfo } from '../../types/types';
import { ParsedPlan } from '../../types/models/ParsedPlan';

export type ActivationInfo = {
  firstname: string;
  lastname: string;
  activationDate: Date;
  endDate?: Date;
  emailAddress: string;
  province: ProvinceInfo;
  city: string;
};

type Props = {
  plan: ParsedPlan;
  activationInfo: ActivationInfo;
  onNext(activationInfo: ActivationInfo): void;
};

export default function ActivationInfoView(props: Props) {
  registerTranslation('en', enGB);

  const [firstname, setFirstname] = useState<string>(props.activationInfo.firstname ?? '');
  const [lastname, setLastname] = useState<string>(props.activationInfo.lastname ?? '');
  const [activationDate, setActivationDate] = useState<Date>(
    props.activationInfo.activationDate ?? new Date()
  );
  const [endDate, setEndDate] = useState<Date>(props.activationInfo.endDate ?? new Date());
  const [emailAddress, setEmailAddress] = useState<string>(props.activationInfo.emailAddress ?? '');
  const [confirmEmailAddress, setConfirmEmailAddress] = useState<string>(
    props.activationInfo.emailAddress ?? ''
  );
  const [province, setProvince] = useState<ProvinceInfo>(props.activationInfo.province);
  const [city, setCity] = useState<string>(props.activationInfo.city);

  const [showDateModal, setShowDateModal] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showProvinceModel, setShowProvinceModel] = useState<boolean>(false);

  useEffect(() => {
    if (!firstname) {
      return setIsValid(false);
    }
    if (!lastname) {
      return setIsValid(false);
    }
    if (!validate('email', emailAddress) || emailAddress !== confirmEmailAddress) {
      return setIsValid(false);
    }
    return setIsValid(true);
  }, [firstname, lastname, emailAddress, confirmEmailAddress]);

  const onConfirmDate = (params: any) => {
    setShowDateModal(false);
    setActivationDate(params.date);
    if (props.plan.planType === 'prepaid') {
      setEndDate(
        moment(params.date)
          .add(Number(props.plan.planTypeD) - 1)
          .toDate()
      );
    }
  };

  const onDismissDate = () => {
    setShowDateModal(false);
  };

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          padding: 20,
          backgroundColor: Colors.white,
          flex: 1,
          height: '100%',
          marginBottom: 100,
        }}
        automaticallyAdjustKeyboardInsets={true}
      >
        <InputWithTitle title="First Name" inputValue={firstname} onInputChange={setFirstname} />
        <InputWithTitle title="Last Name" inputValue={lastname} onInputChange={setLastname} />
        <View
          style={{
            position: 'relative',
          }}
        >
          <InputWithTitle
            title="Activation Date"
            inputValue={moment(activationDate).format(DATE_FORMAT)}
          />
          <View
            style={{
              position: 'absolute',
              bottom: 13,
              right: 13,
            }}
          >
            <Feather
              name="calendar"
              size={20}
              color={Colors.canadaButtonColor}
              onPress={() => {
                if (Platform.OS === 'android') {
                  DateTimePickerAndroid.open({
                    value: activationDate,
                    onChange: (_: DateTimePickerEvent, selectedDate?: Date) => {
                      if (selectedDate) {
                        setActivationDate(selectedDate);
                        if (props.plan.planType === 'prepaid') {
                          setEndDate(
                            moment(selectedDate)
                              .add(Number(props.plan.planTypeD) - 1)
                              .toDate()
                          );
                        }
                      }
                    },
                    mode: 'date',
                    is24Hour: true,
                    minimumDate: new Date(),
                  });
                } else {
                  setShowDateModal(true);
                }
              }}
            />
          </View>
        </View>
        <Pressable
          onPress={() => {
            setShowProvinceModel(true);
          }}
          style={{
            position: 'relative',
          }}
        >
          <InputWithTitle
            title="Where will you be using our service?"
            inputValue={province.name ?? 'Select One'}
          />
          <View
            style={{
              position: 'absolute',
              bottom: 13,
              right: 13,
            }}
          >
            <Entypo name="chevron-down" size={20} color={Colors.canadaButtonColor} />
          </View>
        </Pressable>
        <InputWithTitle title="City" inputValue={city} onInputChange={setCity} />
        <InputWithTitle
          title="Email Address"
          inputValue={emailAddress}
          onInputChange={setEmailAddress}
          inputMode="email"
          keyboardType="email-address"
        />
        <InputWithTitle
          title="Confirm Email Address"
          inputValue={confirmEmailAddress}
          onInputChange={setConfirmEmailAddress}
          inputMode="email"
          keyboardType="email-address"
        />
        <DatePickerModal
          validRange={{
            startDate: new Date(),
          }}
          locale="en"
          mode="single"
          visible={showDateModal && Platform.OS !== 'android'}
          date={activationDate}
          onConfirm={onConfirmDate}
          onDismiss={onDismissDate}
        />
      </ScrollView>
      <AppDrawer
        onClose={() => {
          setShowProvinceModel(false);
        }}
        height={350}
        visible={showProvinceModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Province
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {provinces.map((p) => (
            <CustomText
              onPress={() => {
                setProvince({ code: p.code, name: p.name });
                setShowProvinceModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={p.key}
            >
              {p.name}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          disabled={!isValid}
          onPress={() => {
            props.onNext({
              firstname,
              lastname,
              activationDate,
              endDate,
              emailAddress,
              province,
              city,
            });
          }}
          textStyle={{ fontSize: 14 }}
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
}
