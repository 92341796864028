import AppContainer from "../components/AppContainer"
import { View, Text, Linking, Alert, StyleSheet, Pressable } from "react-native"
import { RootStackScreenProps } from "../types";
import AppHeader from "../components/AppHeader";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import * as Clipboard from "expo-clipboard";
import { useState } from "react";
import CommonStyles from "../constants/CommonStyles";
import { AppButton } from "../components/AppButton";
import { AppDrawer } from "../components/AppDrawer";
import { Colors } from "../constants/Colors";
import FontFamilys from "../constants/FontFamilys";

export default function FriendsReferralScreen({ navigation }: RootStackScreenProps<'FriendsReferral'>) {
  const user = useSelector((state: RootState) => state.user);
  const fullName = user.profile.firstName + " " + user.profile.lastName;
  const referralLink = `https://gophonebox.com/plans/?referral=${user.cnum}`;
  const [linkCopied, setLinkCopied] = useState(false);
  const [visible, setVisible] = useState(false);

  const onPressSendInvites = () => {
    setVisible(true);
  }

  const onPressReferByEmail = () => {
    if (!user.cnum) {
      return;
    }
    let emailContents = encodeURI(`mailto:?to=&subject=Congrats! You've received your Referral from ${fullName}!&body=Your friend has invited you to join PhoneBox! Complete your Referral using the link below:\n${referralLink}`);
    handleEmailContents(emailContents);
  }

  const onPressCopy = async () => {
    setLinkCopied(true);
    await Clipboard.setStringAsync(referralLink);
  }

  const handleEmailContents = async (emailURI: string) => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(emailURI);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(emailURI);
    } else {
      Alert.alert(`Don't know how to open this URL: ${emailURI}`);
    }
  }

  const onPressBack = () => {
    navigation.goBack();
  }

  return (
    <>
      <AppContainer>
        <AppHeader onPressBack={onPressBack}/>
        <View style={styles.referralContainer}>
          <View style={styles.marginTop}>
            <Text style={[CommonStyles.title, { paddingRight: 20 }]}>Earn $30 when you refer a friend</Text>
          </View>
          <View style={styles.marginTop}>
            <Text style={CommonStyles.futuraFont}>Invite a friend to use PhoneBox and get $30 in credits when your friend has signed up for a plan.</Text>
          </View>
          <View style={styles.stepContainer}>
            <View style={styles.stepCircle}>
              <Text style={styles.stepNumber}>1</Text>
            </View>
            <View style={styles.stepTextContainer}>
              <Text style={styles.stepTextLabel}>Invite a friend</Text>
              <Text style={styles.stepText}>Send invites to your friends, via text or as a post on social media</Text>
            </View>
          </View>
          <View style={styles.stepContainer}>
            <View style={styles.stepCircle}>
              <Text style={styles.stepNumber}>2</Text>
            </View>
            <View style={styles.stepTextContainer}>
              <Text style={styles.stepTextLabel}>Friends sign up for a plan</Text>
              <Text style={styles.stepText}>We'll track when your friends have signed on for one of our plans</Text>
            </View>
          </View>
          <View style={styles.stepContainer}>
            <View style={styles.stepCircle}>
              <Text style={styles.stepNumber}>3</Text>
            </View>
            <View style={styles.stepTextContainer}>
              <Text style={styles.stepTextLabel}>Get paid in credits</Text>
              <Text style={styles.stepText}>Once your friend has made their first payment, a $30 credit is added to your balance</Text>
            </View>
          </View>
        </View>
        <View style={CommonStyles.bottomContainer}>
          <AppButton onPress={onPressSendInvites} text="Send Invites"/>
        </View>
      </AppContainer>
      <AppDrawer onClose={() => setVisible(false)} visible={visible} height={400}>
        <View style={styles.referralHeader}>
          <Text style={CommonStyles.title}>Send an invite</Text>
        </View>
        <Pressable style={styles.referralLink} onPress={onPressCopy}>
          <Text>Copy link</Text>
        </Pressable>
        <Pressable style={styles.referralLink} onPress={onPressReferByEmail}>
          <Text>Refer by email</Text>
        </Pressable>
      </AppDrawer>
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25
  },
  referralContainer: {
    marginHorizontal: 10
  },
  referralLink: {
    paddingVertical: 10, 
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderBottomColor: Colors.mediumGrey
  },
  referralHeader: {
    marginVertical: 15
  },
  stepContainer: {
    marginVertical: 20,
    flexDirection: "row"
  },
  stepCircle: {
    height: 40,
    width: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: "solid",
    paddingHorizontal: 13,
    paddingTop: 6
  },
  stepNumber: {
    fontFamily: FontFamilys.regular,
    fontSize: 20
  },
  stepTextContainer: {
    marginLeft: 20,
    width: 230
  },
  stepTextLabel: {
    fontFamily: FontFamilys.bold,
    fontSize: 16
  },
  stepText: {
    fontFamily: FontFamilys.regular,
    fontSize: 16
  }
})