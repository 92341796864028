import { Alert, Image, Platform, Pressable, ScrollView, Text, View } from 'react-native';
import { RootStackParamList } from '../../types';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import { AppGap } from '../../components/AppGap';
import SectionHeading from '../../components/UI/SectionHeading';

import QuickActions from '../../components/QuickAction/QuickActions';
import { Action } from '../../components/QuickAction/QuickAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ActiveUserHero from '../../components/UI/Hero/ActiveUserHero';
import { getDetailedDate } from '../../utilities/utilities';
import Plan from '../../components/UI/Plans/Plan';
import PageHeader from '../../components/UI/Header/PageHeader';
import PlanDetails from '../../components/UI/Plans/PlanDetails';
import CustomText from '../../components/utils/CustomText';
import Offering from '../../components/Section/Offering';
import { DATE_FORMAT, HERO_GRADIENT, SUSPENSION_TYPE } from '../../constants/constants';
import { SuspensionReason, SuspensionStatus } from '../../types/types';
import DataUsage from '../../components/UI/DataUsage';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { CrispChatAction } from '../../utilities/functions';

type Props = {};

const prePaidActions: Action[][] = [
  [
    {
      image: require('../../assets/icons/action/4x/profile.png'),
      title: 'Profile Change',
      tag: 'PROFILE_CHANGE',
    },
    {
      image: require('../../assets/icons/action/4x/phone.png'),
      title: 'Plan Change',
      tag: 'PLAN_CHANGE',
    },
    {
      image: require('../../assets/icons/action/4x/data-top-up.png'),
      title: 'Data Top Up',
      tag: 'DATA_TOP_UP',
    },
    {
      image: require('../../assets/icons/action/4x/card.png'),
      title: 'Auto Payment',
      tag: 'AUTO_PAYMENT',
    },
  ],
  [
    {
      image: require('../../assets/icons/action/4x/help-center.png'),
      title: 'Help Center',
      tag: 'HELP_CENTER',
    },
    {
      image: require('../../assets/icons/action/4x/shop.png'),
      title: 'Shop Plans',
      tag: 'SHOP_PLANS',
    },
    {
      image: require('../../assets/icons/action/4x/referral.png'),
      title: 'Friends Referral',
      tag: 'FRIENDS_REFERRAL',
    },
    {
      image: require('../../assets/icons/action/4x/invoice.png'),
      title: 'View Invoice',
      tag: 'VIEW_INVOICE',
    },
  ],
  [
    {
      image: require('../../assets/icons/action/4x/tracking.png'),
      title: 'Status Tracking',
      tag: 'STATUS_TRACKING',
    },
    {
      image: require('../../assets/icons/action/4x/ticket.png'),
      title: 'Support Ticket',
      tag: 'SUPPORT_TICKET',
    },
    {
      image: require('../../assets/icons/action/4x/port-in.png'),
      title: 'Port In',
      tag: 'PORT_IN',
    },
    {
      image: require('../../assets/icons/action/4x/port-out.png'),
      title: 'Port Out',
      tag: 'PORT_OUT',
    },
  ],
];

const postPaidActions: Action[][] = [
  [
    {
      image: require('../../assets/icons/action/4x/profile.png'),
      title: 'Profile Change',
      tag: 'PROFILE_CHANGE',
    },
    {
      image: require('../../assets/icons/action/4x/phone.png'),
      title: 'Plan Change',
      tag: 'PLAN_CHANGE',
    },
    {
      image: require('../../assets/icons/action/4x/invoice.png'),
      title: 'View Invoice',
      tag: 'VIEW_INVOICE',
    },
    {
      image: require('../../assets/icons/action/4x/card.png'),
      title: 'Auto Payment',
      tag: 'AUTO_PAYMENT',
    },
  ],
  [
    {
      image: require('../../assets/icons/action/4x/tracking.png'),
      title: 'Status Tracking',
      tag: 'STATUS_TRACKING',
    },
    {
      image: require('../../assets/icons/action/4x/shop.png'),
      title: 'Shop Plans',
      tag: 'SHOP_PLANS',
    },
    {
      image: require('../../assets/icons/action/4x/port-in.png'),
      title: 'Port In',
      tag: 'PORT_IN',
    },
    {
      image: require('../../assets/icons/action/4x/referral.png'),
      title: 'Friends Referral',
      tag: 'FRIENDS_REFERRAL',
    },
  ],
  [
    {
      image: require('../../assets/icons/action/4x/vacation.png'),
      title: 'Vacation Hold',
      tag: 'VACATION_HOLD',
    },
    {
      image: require('../../assets/icons/action/4x/ticket.png'),
      title: 'Support Ticket',
      tag: 'SUPPORT_TICKET',
    },
    {
      image: require('../../assets/icons/action/4x/chat.png'),
      title: 'Support Chat',
      tag: 'SUPPORT_CHAT',
    },
    {
      image: require('../../assets/icons/action/4x/port-out.png'),
      title: 'Port Out',
      tag: 'PORT_OUT',
    },
  ],
  [
    {
      image: require('../../assets/icons/action/4x/lost.png'),
      title: 'Lost or Stolen',
      tag: 'LOST_OR_STOLEN',
    },
    {
      image: require('../../assets/icons/action/4x/blog.png'),
      title: 'Blog',
      tag: 'ACTION_BLOG',
    },
  ],
];

export default function ActiveUserView({}: Props) {
  const navigation = useNavigation();

  const { Prepaid: isPrepaid, EndDate } = useSelector(
    (state: RootState) => state.selfServeCA.accountInfoCA
  );
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);
  const { loggedIn, id, email, firstName, lastName } = useSelector(
    (state: RootState) => state.user
  );

  let gradientInfo = HERO_GRADIENT.default;
  if (accountProfileCA.Suspended && accountProfileCA.SuspensionReason) {
    gradientInfo = HERO_GRADIENT[SUSPENSION_TYPE[accountProfileCA.SuspensionReason]];
  }

  return (
    <>
      <PageHeader />
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: Platform.select({ default: 80, ios: 60 }),
          }}
        >
          <ActiveUserHero />
        </AppGradientContainer>

        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
          }}
        >
          <QuickActions
            quickActions={isPrepaid ? prePaidActions : postPaidActions}
            onActionSelected={(action) => {
              switch (action) {
                case 'PROFILE_CHANGE':
                  navigation.navigate('Account');
                  break;
                case 'PLAN_CHANGE':
                  navigation.navigate('ChangePlan');
                  break;
                case 'STATUS_TRACKING':
                  navigation.navigate('StatusTracking');
                  break;
                case 'SHOP_PLANS':
                  navigation.navigate('Shop');
                  break;
                case 'DATA_TOP_UP':
                  const endDateString = moment(EndDate).format(DATE_FORMAT);
                  if (Platform.OS === 'web') {
                    if (
                      confirm(
                        `Your Current term end date is ${endDateString}. Do you still want to Top Up`
                      )
                    ) {
                      navigation.navigate('AddMoreData');
                    }
                  } else {
                    Alert.alert(
                      'Confirmation',
                      `Your Current term end date is ${endDateString}. Do you still want to Top Up`,
                      [
                        {
                          isPreferred: true,
                          onPress: () => {
                            navigation.navigate('AddMoreData');
                          },
                          text: 'Yes',
                        },
                        {
                          text: 'Cancel',
                        },
                      ]
                    );
                  }
                  break;
                case 'PORT_OUT':
                case 'PORT_IN':
                  navigation.navigate('PortOption');
                  break;
                case 'VIEW_INVOICE':
                  navigation.navigate('Invoices');
                  break;
                case 'SUPPORT_CHAT':
                  CrispChatAction(
                    loggedIn,
                    id,
                    email,
                    firstName,
                    lastName,
                    accountProfileCA.PhoneNumber
                  );
                  break;
                case 'AUTO_PAYMENT':
                  navigation.navigate('ManagePayment');
                  break;
                default:
                  break;
              }
              console.log(action);
            }}
          />

          <View>
            <SectionHeading style={{ marginLeft: 20 }}>Plan Details</SectionHeading>
            <PlanDetails />
          </View>

          <View style={{ marginTop: 32 }}>
            <SectionHeading style={{ marginLeft: 20 }}>Best Offering</SectionHeading>
            <Offering />
          </View>

          {/* No final yet, hidden till it get finalize */}
          {/* <PBPointCard points={2700} /> */}

          <View style={{ marginTop: 32 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginHorizontal: 20,
                marginBottom: 16,
              }}
            >
              <SectionHeading>My Plans</SectionHeading>
              <Pressable
                style={{}}
                onPress={() => {
                  navigation.navigate('Plans');
                }}
              >
                <CustomText
                  weight="600"
                  style={{ color: Colors.ActionLinkColor, fontWeight: '600', fontSize: 12 }}
                >
                  See All Plans
                </CustomText>
              </Pressable>
            </View>
            <ScrollView
              contentContainerStyle={{ marginRight: 15 }}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              scrollEventThrottle={16}
              pagingEnabled={true}
            >
              <Plan />
              {/* Not Considering Terminated plans as its not part of the system yet */}
              {/* <Plan status="Terminated" /> */}
            </ScrollView>
          </View>
          {/* Data Usage for Staatus = VC, LS */}
          {accountProfileCA.Suspended &&
          ['VC', 'LS', 'UP'].includes(accountProfileCA.SuspensionReason!) ? (
            <View style={{ marginTop: 10 }}>
              <SectionHeading style={{ marginLeft: 20 }}>Data Usage</SectionHeading>
              <View
                style={{
                  borderColor: Colors.HorizonalLineDefaultColor,
                  borderWidth: 1,
                  marginHorizontal: 20,
                  borderRadius: 8,
                  padding: 16,
                }}
              >
                {/* Data Usage */}
                <DataUsage />
              </View>
            </View>
          ) : null}
          <AppGap direction="vertical" size={20} />
        </View>
      </ScrollView>
    </>
  );
}
