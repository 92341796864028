import LottieView from 'lottie-react-native';
import AppGradientContainer from '../../components/AppGradientContainer';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { SafeAreaView, Image, View, Dimensions } from 'react-native';
import HorizontalLine from '../../components/HorizontalLine';
import Button from '../../components/Button';
import { AppGap } from '../../components/AppGap';

const { width, height } = Dimensions.get('window');

export default function ChangePlanSuccessScreen({
  navigation,
}: RootStackScreenProps<'ChangePlanSuccess'>) {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <AuthBackground />
        <View
          style={{
            position: 'relative',
            marginTop: -160,
            marginLeft: 30,
          }}
        >
          <Image
            source={require('../../assets/illustrations/Blue4.png')}
            style={{
              width: 500,
              height: 550,
              zIndex: 10,
              marginLeft: -100,
              marginTop: -100,
            }}
            resizeMode="contain"
          />
          <View
            style={{
              width: 500,
              height: height / 2,
              position: 'absolute',
              top: -70,
              zIndex: 8,
              right: -70,
            }}
          >
            <LottieView
              style={{ flex: 1 }}
              autoPlay
              loop={true}
              source={require('../../assets/illustrations/animated/Firework_3.json')}
            />
          </View>
        </View>
        <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
          <View style={{ width: '100%' }}>
            <CustomText
              weight="500"
              style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
            >
              Plan Change Requested!
            </CustomText>
            <View style={{ marginBottom: 20 }}>
              <CustomText style={{ fontSize: 12 }}>Your plan change has been requested.</CustomText>
            </View>
            <HorizontalLine style={{ marginBottom: 20 }} />

            <Button
              style={{ marginTop: 10 }}
              onPress={() => {
                navigation.navigate('Root');
              }}
              textStyle={{ fontSize: 14 }}
            >
              Back to App
            </Button>
            <AppGap direction="vertical" size={30} />
          </View>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
}
