import { Colors } from '../constants/Colors';
import { Dimensions, DimensionValue, StyleSheet, View, ViewStyle } from 'react-native';

const { height } = Dimensions.get('window');

type PropType = {
  children: React.ReactNode;
  backgroundColor?: string;
  height?: DimensionValue;
  style?: ViewStyle;
  maxHeight?: DimensionValue;
};

const BottomWhiteSheet = (props: PropType) => {
  const styles = StyleSheet.create({
    button: {
      width: '100%',
      backgroundColor: props.backgroundColor ?? Colors.white,
      height: props.height ?? 'auto',
      maxHeight: props.maxHeight,
      position: 'absolute',
      bottom: 0,
      alignItems: 'center',
      padding: 10,
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      shadowColor: Colors.black,
      shadowOffset: { height: -10, width: 0 },
      shadowOpacity: 0.15,
      shadowRadius: 30,
      ...props.style,
    },
  });

  return <View style={styles.button}>{props.children}</View>;
};

export default BottomWhiteSheet;
