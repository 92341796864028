import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import GuestHeader from './GuestHeader';
import NoPlanHeader from './NoPlanHeader';
import ActiveUserHeader from './ActiveUserHeader';

const PageHeader = () => {
  const { loggedIn, accounts } = useSelector((state: RootState) => state.user);

  return !loggedIn ? (
    <GuestHeader />
  ) : accounts.length === 0 ? (
    <NoPlanHeader />
  ) : (
    <ActiveUserHeader />
  );
};

export default PageHeader;
