import { SafeAreaView, StatusBar, View } from 'react-native';
import { RootStackScreenProps } from '../../types';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { StepType } from '../../types/enums/StepType';
import HorizontalLine from '../../components/HorizontalLine';
import OTPInput from '../../components/OTPInput';
import { authService } from '../../services/SSO/AuthService';
import { SystemCodes } from '../../types/enums/SystemCodes';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { validate } from '../../utilities/utilities';
import { updateEmail } from '../../store/user';
import { Feather } from '@expo/vector-icons';
import { signUpService } from '../../services/SSO/SignUpService';

export default function EditPasswordScreen({ navigation }: RootStackScreenProps<'EditPassword'>) {
  const { authorizedBy } = useSelector((state: RootState) => state.user);

  const [step, setStep] = useState<StepType>(StepType.First);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [passwordMatched, setPasswordMatched] = useState<boolean>(false);

  useEffect(() => {
    if (validate('password', newPassword) && validate('password', oldPassword)) {
      setPasswordMatched(newPassword === newConfirmPassword);
    }
  }, [newPassword, newConfirmPassword]);

  const changePasswordRequest = async () => {
    setLoading(true);

    try {
      const updatePasswordResponse = await authService.updatePassword({
        data: {
          originalPassword: oldPassword,
          newPassword: newPassword,
          newConfirmPassword: newConfirmPassword,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!updatePasswordResponse || !updatePasswordResponse.data?.success) {
        setError('Sorry, something went wrong.');
        console.log('Something went wrong with sendUpdateEmailCode: ', updatePasswordResponse);
      }

      if (updatePasswordResponse?.data?.success) {
        console.log(updatePasswordResponse.data);
        setError('');
        setStep(StepType.Success);
      }
    } catch (err) {
      console.error(err);
      setError('Sorry, something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <View
        style={{
          flex: 1,
          height: '100%',
          backgroundColor: Colors.white,
          padding: 20,
        }}
      >
        {step === StepType.First ? (
          <>
            {authorizedBy === 'PhoneBox' ? (
              <InputWithTitle
                title="Old Password"
                inputValue={oldPassword}
                onInputChange={setOldPassword}
                secureInput={true}
              />
            ) : null}
            <InputWithTitle
              title="New Password"
              inputValue={newPassword}
              onInputChange={setNewPassword}
              secureInput={true}
            />
            <InputWithTitle
              title="Confirm New Password"
              inputValue={newConfirmPassword}
              onInputChange={setNewConfirmPassword}
              secureInput={true}
            />
            <View>
              <CustomText
                weight="300"
                style={{
                  fontSize: 12,
                  textAlign: 'center',
                  color: Colors.errorRed,
                  marginVertical: 16,
                }}
              >
                New Password must contain minimum 8 charcters, at least one number, special
                character and capital letter
              </CustomText>
            </View>
          </>
        ) : (
          <View>
            <CustomText
              weight="700"
              style={{ fontSize: 32, textAlign: 'center', color: Colors.greyColor }}
            >
              Your Password has been successfully changed.
            </CustomText>
          </View>
        )}
        {error ? (
          <View style={{ marginVertical: 14 }}>
            <CustomText
              weight="500"
              style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
            >
              {error}
            </CustomText>
          </View>
        ) : null}
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            disabled={step === StepType.First ? !passwordMatched : false}
            onPress={() => {
              step === StepType.First ? changePasswordRequest() : navigation.goBack();
            }}
            textStyle={{ fontSize: 14, fontWeight: '600' }}
          >
            {step === StepType.First ? 'Update Password' : 'Go Back'}
          </Button>
        </View>
      </View>
    </>
  );
}
