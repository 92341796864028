import {
  Image,
  ImageBackground,
  Pressable,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { RootStackScreenProps } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import PageHeader from '../../components/UI/Header/PageHeader';
import AppGradientContainer from '../../components/AppGradientContainer';
import { Colors } from '../../constants/Colors';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import QuickActions from '../../components/QuickAction/QuickActions';

export default function BenefitsScreen({ navigation }: RootStackScreenProps<'Benefits'>) {
  const { loggedIn, accounts } = useSelector((state: RootState) => state.user);

  return (
    <SafeAreaView style={{ flex: 1, paddingTop: StatusBar.currentHeight }}>
      <PageHeader />
      <ScrollView showsVerticalScrollIndicator={false}>
        <AppGradientContainer
          autoHeight
          colorLeft={Colors.gradientDarkBlue}
          colorRight={Colors.gradientLightBlue}
          direction="LEFT-RIGHT"
          style={{ paddingBottom: 15, paddingTop: 65 }}
        >
          <View
            style={{
              borderRadius: 20,
              overflow: 'hidden',
              marginHorizontal: 20,
              marginBottom: 10,
              marginTop: 20,
            }}
          >
            <Pressable
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 20,
              }}
              onPress={() => {
                alert('Pressed');
              }}
            >
              <Image
                source={require('../../assets/backgrounds/benefits.png')}
                resizeMode="contain"
                style={{ height: 175, width: 300, justifyContent: 'space-between' }}
              />
            </Pressable>
          </View>
        </AppGradientContainer>
        <View
          style={{
            width: '100%',
            marginTop: -20,
            height: 100,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
          }}
        ></View>
      </ScrollView>
    </SafeAreaView>
  );
}
