import { Colors } from '../../constants/Colors';
import { Image, SafeAreaView, ScrollView, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import Button from '../../components/Button';
import { SIMType } from '.';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import CanadaPostAutoCompleteAddress from '../../components/CanadaPostAutoCompleteAddress';
import GoogleAutoCompleteAddress from '../../components/GoogleAutoCompleteAddress';
import TitleWithSupTitle from '../../components/UI/TitleWithSupTitle';
import DropDownPicker from 'react-native-dropdown-picker';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { useEffect, useState } from 'react';
import { activationService } from '../../services/MyAccount/ActivationService';

export type ShippingAddress = {
  fullAddress: string;
  unitNumber: string;
  street: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  countryId: number;
};
type Props = {
  address: ShippingAddress | null;
  onNext(address: ShippingAddress): void;
};

export default function ShippingAddressView(props: Props) {
  const [country, setCountry] = useState<string>('Canada');
  const [countryId, setCountryId] = useState<number>(42);
  const [countryIso, setCountryIso] = useState<string>('CA');
  const [street, setStreet] = useState<string>(props.address?.street ?? '');
  const [city, setCity] = useState<string>(props.address?.city ?? '');
  const [province, setProvince] = useState<string>(props.address?.province ?? '');
  const [postalCode, setPostalCode] = useState<string>(props.address?.postalCode ?? '');
  const [unitNumber, setUnitNumber] = useState<string>(props.address?.unitNumber ?? '');
  const [isAddressValid, setIsAddressValid] = useState<boolean>(props.address !== null ?? false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const [openCountry, setOpenCountry] = useState(false);
  const [newSelectedCountry, setNewSelectedCountry] = useState('42'); // Default Canada
  const [showAddressSearch, setShowAddressSearch] = useState(false);

  const [countryList, setCountryList] = useState<
    Array<{
      CountryCode: string;
      CountryCodeISO: string;
      CountryName: string;
    }>
  >([]);

  useEffect(() => {
    if (street === '' || city === '' || province === '' || postalCode === '') {
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    }
  }, [street, city, province, postalCode]);

  useEffect(() => {
    setLoading(true);
    const getCountriesAsync = async () => {
      const getCountriesResponse = await activationService.getCountries();

      if (!getCountriesResponse || !getCountriesResponse.data) {
        setError('Unable to Fetch Countries');
        return;
      }

      if (getCountriesResponse && getCountriesResponse.data) {
        setCountryList(getCountriesResponse.data);
      }
    };
    getCountriesAsync()
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getShippingAddressAutoComplete = (full_address: any) => {
    setStreet(full_address[0]);
    setCity(full_address[1]);
    setPostalCode(full_address[4]);
    setProvince(full_address[6]);
  };

  const handleChangeValueFromCountryDropDown = (item: string | null) => {
    if (item) {
      setShowAddressSearch(true);
    }
    const selectedCountry = countryList.find((country) => country.CountryCode === item);
    if (selectedCountry) {
      setCountryIso(selectedCountry.CountryCodeISO);
      setCountry(selectedCountry.CountryName);
      setCountryId(Number(selectedCountry.CountryCode));
    } else {
      setCountryIso('');
      setCountry('');
      setCountryId(-1);
    }
    setStreet('');
    setCity('');
    setProvince('');
    setPostalCode('');
  };

  const AddressInput = () => {
    return (
      <View style={{ marginBottom: 10, marginTop: 16 }}>
        <CustomText style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }} weight="500">
          Search Address to Update
        </CustomText>
        {countryIso === 'CA' || countryIso === 'US' ? (
          <CanadaPostAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            selectedCountry={countryIso || 'CA'}
          />
        ) : (
          <GoogleAutoCompleteAddress
            onAutoCompleteAddress={getShippingAddressAutoComplete}
            country={countryIso}
          />
        )}
      </View>
    );
  };

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <SafeAreaView style={{ flex: 1, height: '100%' }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            padding: 20,
            backgroundColor: Colors.white,
            flex: 1,
            height: '100%',
            marginBottom: 100,
          }}
          automaticallyAdjustKeyboardInsets={true}
        >
          {error ? (
            <View style={{ marginVertical: 14 }}>
              <CustomText
                weight="500"
                style={{ fontSize: 14, color: Colors.errorRed, textAlign: 'center' }}
              >
                {error}
              </CustomText>
            </View>
          ) : null}
          <View style={{ marginBottom: 10, zIndex: 998, marginTop: 16 }}>
            <CustomText
              style={{ fontSize: 12, color: Colors.greyColor, marginBottom: 6 }}
              weight="500"
            >
              Country
            </CustomText>
            <DropDownPicker
              searchable={true}
              open={openCountry}
              listMode="SCROLLVIEW"
              value={newSelectedCountry}
              setOpen={setOpenCountry}
              setValue={setNewSelectedCountry}
              onChangeValue={handleChangeValueFromCountryDropDown}
              schema={{
                label: 'CountryName',
                value: 'CountryCode',
              }}
              placeholder="Select a Country"
              // @ts-ignore
              items={countryList}
            />
          </View>
          {showAddressSearch ? <AddressInput /> : null}
          <InputWithTitle title="Street Name *" inputValue={street} onInputChange={setStreet} />
          <InputWithTitle title="City *" inputValue={city} onInputChange={setCity} />
          <InputWithTitle title="Province *" inputValue={province} onInputChange={setProvince} />
          <InputWithTitle
            title="Postal Code *"
            inputValue={postalCode}
            onInputChange={setPostalCode}
          />
          <InputWithTitle
            title="Unit # (Optional)"
            inputValue={unitNumber}
            onInputChange={setUnitNumber}
          />
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 30,
            width: '85%',
            alignSelf: 'center',
          }}
        >
          <Button
            style={{ marginTop: 32 }}
            disabled={!isAddressValid || loading}
            onPress={() => {
              const fullAddress = `${
                unitNumber ? unitNumber + '-' : ''
              }${street}, ${city}, ${province} ${postalCode}, ${country}`;
              props.onNext({
                fullAddress,
                unitNumber,
                street,
                city,
                province,
                postalCode,
                country,
                countryId,
              });
            }}
            textStyle={{ fontSize: 14 }}
          >
            Continue
          </Button>
        </View>
      </SafeAreaView>
    </>
  );
}
