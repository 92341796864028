import { Animated, Platform, Pressable, Text, View, ViewStyle } from 'react-native';
import { Colors } from '../../constants/Colors';
import VerticalLine from '../VerticalLine';
import HorizontalLine from '../HorizontalLine';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useRef, useState } from 'react';
import CustomText from '../utils/CustomText';
import { Entypo, EvilIcons } from '@expo/vector-icons';

const InfoDescriptionToggle = ({
  title,
  desc,
  height,
  style,
}: {
  title: string;
  desc: string;
  height: number;
  style?: ViewStyle;
}) => {
  const [detailVisiable, setDetailVisiable] = useState(false);
  const heightAnim = useRef(new Animated.Value(0)).current;
  const rotateAnim = useRef(new Animated.Value(1)).current;

  const toggleExpand = () => {
    Animated.timing(heightAnim, {
      toValue: detailVisiable ? 0 : height,
      duration: 300,
      useNativeDriver: false,
    }).start();
    Animated.timing(rotateAnim, {
      toValue: detailVisiable ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
    setDetailVisiable(!detailVisiable);
  };

  return (
    <Pressable onPress={toggleExpand} style={[{ marginHorizontal: 20 }, style]}>
      <View
        style={{
          backgroundColor: Colors.backgroundGrey,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 8,
          borderRadius: 8,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              backgroundColor: Colors.ActionLinkColorBG,
              padding: 4,
              marginRight: 8,
              borderRadius: 10,
            }}
          >
            <EvilIcons name="question" size={16} color={Colors.ActionLinkColor} />
          </View>
          <CustomText>{title}</CustomText>
        </View>
        <Animated.View
          style={{
            transform: [
              {
                rotate: rotateAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['90deg', '0deg'], // Adjust as needed
                }),
              },
            ],
          }}
        >
          <Entypo name="chevron-thin-right" size={16} color={Colors.greyColor} />
        </Animated.View>
      </View>
      <Animated.View
        style={{
          height: heightAnim,
          opacity: heightAnim.interpolate({
            inputRange: [0, height],
            outputRange: [0, 1],
          }),
          backgroundColor: Colors.backgroundGrey,
          paddingVertical: heightAnim.interpolate({
            inputRange: [0, height],
            outputRange: [0, 20],
          }),
          paddingHorizontal: 20,
        }}
      >
        <CustomText>{desc}</CustomText>
      </Animated.View>
    </Pressable>
  );
};

export default InfoDescriptionToggle;
