import { Colors } from '../../constants/Colors';
import { Alert, Keyboard, SafeAreaView, TouchableWithoutFeedback, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import Button from '../../components/Button';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { useState } from 'react';
import { PlanType } from '../../components/UI/PlanTypeBadges';
import { activationService } from '../../services/MyAccount/ActivationService';
import FullScreenLoader from '../../components/utils/FullScreenLoader';

type Props = {
  simcardNumber: string;
  onNext(simCard: string | null): void;
  planType: PlanType;
};

export default function ActivateSimCardView(props: Props) {
  const [simCard, setSimCard] = useState<string>(props.simcardNumber);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}

      <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
        <SafeAreaView style={{ flex: 1, height: '100%' }}>
          <View
            style={{
              padding: 20,
              backgroundColor: Colors.white,
              flex: 1,
              height: '100%',
            }}
          >
            <CustomText
              weight="600"
              style={{ fontSize: 24, textAlign: 'center', marginBottom: 10 }}
            >
              Do you have a PhoneBox SIM card with you?
            </CustomText>
            <CustomText style={{ fontSize: 14, textAlign: 'center', marginBottom: 32 }}>
              Please enter your SIM number to verify. If you don't have one, you can order a new SIM
              card.
            </CustomText>
            <InputWithTitle
              onInputChange={setSimCard}
              inputValue={simCard}
              title="Input your SIM card number"
              keyboardType="numeric"
              inputMode="numeric"
            />
            {error ? (
              <CustomText
                weight="500"
                style={{ color: Colors.errorRed, fontSize: 14, marginTop: 12 }}
              >
                {error}
              </CustomText>
            ) : null}
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 30,
              width: '85%',
              alignSelf: 'center',
            }}
          >
            <Button
              style={{ marginTop: 32 }}
              onPress={async () => {
                try {
                  setLoading(true);
                  const response = await activationService.verifySimCard({
                    data: {
                      verifytype: props.planType === 'monthly' ? 'postpaid' : 'prepaid',
                      countryid: 42,
                      simnum: simCard,
                    },
                  });
                  if (response.data === null) {
                    setError('The SIM card number in invalid!');
                  } else {
                    props.onNext(simCard);
                  }
                } catch (error) {
                  console.log(error);
                  setError('Unable to verify SIM card right now.');
                } finally {
                  setLoading(false);
                }
              }}
              textStyle={{ fontSize: 14 }}
            >
              Continue with this SIM Card
            </Button>
            <Button
              style={{ marginTop: 16 }}
              color={Colors.backgroundGrey}
              textColor={Colors.inputTextPlaceholderColor}
              onPress={() => {
                props.onNext(null);
              }}
              textStyle={{ fontSize: 14, fontWeight: '600' }}
            >
              Buy a SIM Card
            </Button>
          </View>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </>
  );
}
