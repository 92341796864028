import React from 'react';
import Svg, { Circle } from 'react-native-svg';
import { Text, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';

type Props = {
  radius?: number | undefined;
  strokeWidth?: number | undefined;
  strokeColor?: string | undefined;
  trailColor?: string | undefined;
  progress?: number | undefined;
  totalData: number | string;
  usedData: number | string;
  unit: string;
};

const Donut = ({
  radius = 50,
  strokeWidth = 10,
  strokeColor = Colors.canadaButtonColor,
  trailColor = Colors.DonutEmptyColor,
  progress = 0,
  ...props
}: Props) => {
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference * (1 - progress);
  strokeColor = progress < 1 ? Colors.canadaButtonColor : Colors.errorRed;

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 120,
          height: 120,
          transform: [
            { translateX: -60 }, // half of the width
            { translateY: -60 }, // half of the height
          ],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ alignItems: 'baseline', display: 'flex', flexDirection: 'row' }}>
          <CustomText
            weight="600"
            style={{
              fontSize: 26,
              fontWeight: '600',
              lineHeight: 26,
            }}
          >
            {props.usedData}
          </CustomText>
          <CustomText
            weight="600"
            style={{
              fontSize: 10,
              fontWeight: '600',
            }}
          >
            {props.unit}
          </CustomText>
        </View>
        <CustomText
          weight="500"
          style={{
            fontSize: 10,
            fontWeight: '500',
          }}
        >
          /{props.totalData} {props.unit}
        </CustomText>
      </View>
      <Svg
        // width={2 * radius}
        width={2 * radius + strokeWidth}
        // height={2 * radius}
        height={2 * radius + strokeWidth}
        viewBox={`0 0 ${2 * (radius + strokeWidth)} ${2 * (radius + strokeWidth)}`}
      >
        {/* Trail circle */}
        <Circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          stroke={trailColor}
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Progress circle */}
        <Circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          fill="none"
          strokeLinecap={'round'}
        />
      </Svg>
    </View>
  );
};

export default Donut;
