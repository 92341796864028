import { Colors } from '../../constants/Colors';
import { InfoStatus } from '../../types/types';
import CustomText, { FontWeight } from '../utils/CustomText';

type Props = {
  status: InfoStatus;
  title: string;
  fontweight?: FontWeight;
};

const Badge = ({ title, status, ...props }: Props) => {
  const colors = Colors.badge[status] ?? Colors.badge.grey;

  return (
    <CustomText
      weight={props.fontweight ?? '500'}
      style={{
        fontSize: 12,
        color: colors.color,
        backgroundColor: colors.bg,
        width: 'auto',
        textAlign: 'center',
        borderRadius: 8,
        paddingVertical: 4,
        paddingHorizontal: 8,
        alignSelf: 'center',
        textTransform: 'capitalize',
      }}
      numberOfLines={1}
    >
      {title}
    </CustomText>
  );
};

export default Badge;
