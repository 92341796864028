import React, { useRef, useState } from 'react';
import {
  Dimensions,
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import IntroSlide from './IntroSlide';
import Button from '../../components/Button';
import { Colors } from '../../constants/Colors';
import { AppGap } from '../../components/AppGap';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RootStackScreenProps } from '../../types';
import { useDispatch } from 'react-redux';
import { setIsNewUser } from '../../store/app';

const { width } = Dimensions.get('window');

const IntroSliderScreen = ({ navigation }: RootStackScreenProps<'IntroSlider'>) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);

  const handleScroll = (event: any) => {
    const xOffset = event.nativeEvent.contentOffset.x;
    const newPage = Math.round(xOffset / width);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };
  const updateStatus = async (type: 'GUEST' | 'START') => {
    await AsyncStorage.setItem('viewed-intro-slider', 'yes');
    dispatch(setIsNewUser(false));
    if (type === 'GUEST') {
      navigation.navigate('Root');
    } else {
      navigation.navigate('Landing');
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        ref={scrollViewRef}
        style={{ flex: 1, marginTop: Platform.select({ default: 0, ios: -80 }) }}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        pagingEnabled={true}
        onScroll={handleScroll}
      >
        <IntroSlide
          index={0}
          title="Welcome to PhoneBox!"
          subtitle="Ready to stay connected in Canada and the US? We've got you covered!"
          imageChild={
            <View style={{ width: '100%' }}>
              <AppGap direction="vertical" size={80} />
              <Image
                source={require('../../assets/intro-slider/intro-slider-1.png')}
                resizeMode="cover"
                style={{ width: '100%' }}
              />
              <AppGap direction="vertical" size={40} />
            </View>
          }
          width={width}
        />
        <IntroSlide
          index={1}
          title="Discover Your Perfect Plan"
          subtitle="Whether you're a student, immigrant, or local , we have mobile plans just for you."
          width={width}
          imageChild={
            <View style={{ width: '100%' }}>
              <AppGap direction="vertical" size={100} />
              <Image
                source={require('../../assets/intro-slider/intro-slider-2.png')}
                resizeMode="cover"
                style={{ width: '100%', height: 370 }}
              />
              <AppGap direction="vertical" size={50} />
            </View>
          }
        />
        <IntroSlide
          index={2}
          title="Home Internet Made Easy"
          subtitle="Get settled in with our fast and reliable home internet.Let's keep you connected!"
          imageChild={
            <View style={{ width: '100%' }}>
              <Image
                source={require('../../assets/intro-slider/intro-slider-3.png')}
                resizeMode="cover"
                style={{ width: '100%' }}
              />
              <AppGap direction="vertical" size={50} />
            </View>
          }
          width={width}
        />
        <IntroSlide
          index={3}
          title="Dive into PhoneBox"
          subtitle="Explore the app and log in to unlock the best mobile and internet deals for you!"
          imageChild={
            <View style={{ width: '100%' }}>
              <Image
                source={require('../../assets/intro-slider/intro-slider-4.png')}
                resizeMode="cover"
                style={{ width: '100%', marginBottom: -30 }}
              />
              <AppGap direction="vertical" size={10} />
            </View>
          }
          width={width}
        />
      </ScrollView>
      <View
        style={{
          width: width,
          position: 'absolute',
          bottom: 0,
          paddingHorizontal: 20,
          paddingVertical: 10,
          marginBottom: 30,
        }}
      >
        <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          {[0, 1, 2, 3].map((v) =>
            currentPage === v ? (
              <View
                style={{
                  height: 10,
                  width: 28,
                  backgroundColor: Colors.canadaButtonColor,
                  borderRadius: 5,
                  marginLeft: 10,
                }}
                key={v}
              />
            ) : (
              <View
                style={{
                  height: 10,
                  width: 10,
                  backgroundColor: Colors.IntroSliderDotColor,
                  borderRadius: 5,
                  marginLeft: 10,
                }}
                key={v}
              />
            )
          )}
        </View>
        {currentPage < 3 ? (
          <Button
            style={{ marginTop: 30 }}
            onPress={() => {
              scrollViewRef.current?.scrollTo({ x: (currentPage + 1) * width, animated: true });
            }}
          >
            Next
          </Button>
        ) : (
          <>
            <Button
              style={{ marginTop: 30 }}
              onPress={() => {
                updateStatus('START');
              }}
            >
              Let's Get Started!
            </Button>
            <Button
              size={'FULL'}
              color={Colors.noColor}
              textColor={Colors.greyColor}
              style={{
                padding: 5,
                marginTop: 20,
                marginBottom: 15,
                width: 'auto',
              }}
              onPress={() => {
                updateStatus('GUEST');
              }}
            >
              Continue As A Guest
            </Button>
          </>
        )}
      </View>
    </SafeAreaView>
  );
};

export default IntroSliderScreen;

const styles = StyleSheet.create({
  paginationWrapper: {
    position: 'absolute',
    bottom: 200,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  paginationDots: {
    height: 10,
    width: 10,
    borderRadius: 10 / 2,
    backgroundColor: '#0898A0',
    marginLeft: 10,
  },
});
