import { Image, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import CustomText from '../../components/utils/CustomText';
import { Colors } from '../../constants/Colors';
import HorizontalLine from '../../components/HorizontalLine';
import InfoDescriptionToggle from '../../components/UI/InfoDescriptionToggle';
import Button from '../../components/Button';
import { EvilIcons } from '@expo/vector-icons';
import InputWithTitle from '../../components/UI/Form/InputWithTitle';
import { useState } from 'react';
import { AppDrawer } from '../../components/AppDrawer';
import { carriers } from '../../constants/constants';
import { PortCarrier } from '../../types/types';
import { PortOutData } from '.';

const portoutReasons = [
  'Too Expensive',
  'Got a better deal elsewhere',
  'Need a Quebec number',
  'I am leaving Canada',
  'Other Reasons',
];

const PortOutDetailView = ({ onPress }: { onPress(data: PortOutData): void }) => {
  const [showCarrierModel, setShowCarrierModel] = useState<boolean>(false);
  const [showReasonModel, setShowReasonModel] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<PortCarrier>();
  const [portOutReason, setPortOutReason] = useState<string>();
  const [portOutReasonDesc, setPortOutReasonDesc] = useState<string>('');

  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 80, paddingBottom: 20 }}
      >
        <View
          style={{
            backgroundColor: Colors.backgroundGrey,
            padding: 16,
            borderRadius: 16,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <View
              style={{
                backgroundColor: Colors.errorRed20Percent,
                padding: 4,
                marginRight: 8,
                borderRadius: 10,
              }}
            >
              <EvilIcons name="question" size={16} color={Colors.errorRed} />
            </View>
            <CustomText weight="500" style={{ fontSize: 14, color: Colors.red }}>
              Please be aware!
            </CustomText>
          </View>
          <CustomText style={{ fontSize: 12 }}>
            After submitting a port-out request,{' '}
            <CustomText weight="600">data service usage will be restricted</CustomText> due to data
            calculation. However, you can still use phone and text services.
          </CustomText>
        </View>

        <Pressable
          onPress={() => {
            setShowCarrierModel(true);
          }}
        >
          <InputWithTitle
            inputValue={carrier ?? 'Select the carrier*'}
            title="To which provider?*"
          />
        </Pressable>
        <Pressable
          onPress={() => {
            setShowReasonModel(true);
          }}
        >
          <InputWithTitle
            inputValue={portOutReason ?? 'Select from the list*'}
            title="Reason of Port out*"
          />
        </Pressable>
        <InputWithTitle
          inputValue={portOutReasonDesc}
          onInputChange={setPortOutReasonDesc}
          title="Tell us more about the reason*"
        />

        <HorizontalLine
          thinkness={1}
          style={{
            marginVertical: 24,
          }}
        />

        <InfoDescriptionToggle
          title="What is Port out?"
          height={150}
          style={{
            marginHorizontal: 0,
            marginBottom: 16,
          }}
          desc="Lorem ipsum dolor sit amet consectetur. Lacus faucibus pulvinar dignissim adipiscing ultrices ipsum. Erat donec pellentesque gravida ultrices viverra arcu amet."
        />
        <InfoDescriptionToggle
          title="What should I do after request?"
          height={150}
          style={{
            marginHorizontal: 0,
          }}
          desc="Lorem ipsum dolor sit amet consectetur. Lacus faucibus pulvinar dignissim adipiscing ultrices ipsum. Erat donec pellentesque gravida ultrices viverra arcu amet."
        />
      </ScrollView>

      <AppDrawer
        onClose={() => {
          setShowCarrierModel(false);
        }}
        height={500}
        visible={showCarrierModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Select Carrier
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {carriers.map((carrier) => (
            <CustomText
              onPress={() => {
                setCarrier(carrier);
                setShowCarrierModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={carrier}
            >
              {carrier}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <AppDrawer
        onClose={() => {
          setShowReasonModel(false);
        }}
        height={300}
        visible={showReasonModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, textAlign: 'center', marginBottom: 32 }}>
          Reason to Port Out
        </CustomText>
        <ScrollView showsVerticalScrollIndicator={false}>
          {portoutReasons.map((portoutReason) => (
            <CustomText
              onPress={() => {
                setPortOutReason(portoutReason);
                setShowReasonModel(false);
              }}
              style={{
                fontSize: 15,
                textAlign: 'center',
                marginBottom: 8,
              }}
              key={portoutReason}
            >
              {portoutReason}
            </CustomText>
          ))}
        </ScrollView>
      </AppDrawer>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() =>
            onPress({
              carrier: carrier!,
              portOutReason: portOutReason!,
              portOutReasonDesc,
            })
          }
          textStyle={{ fontSize: 14 }}
          disabled={!carrier || !portOutReason || !portOutReasonDesc}
        >
          Request Port Out
        </Button>
      </View>
    </View>
  );
};

export default PortOutDetailView;
