import { Pressable, StyleSheet, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';
import { useState } from 'react';

export type PlanType = 'monthly' | 'prepaid';

type Props = {
  selected: PlanType;
  setSelected(planType: PlanType): void;
  showOnly?: PlanType;
};

const PlanTypeBadges = ({ selected, setSelected, showOnly }: Props) => {
  const PlanTypeBadge = ({ planType }: { planType: PlanType }) => {
    return (
      <Pressable
        style={[
          {
            borderRadius: 8,
            width: 100,
            backgroundColor: Colors.backgroundGrey,
            padding: 8,
          },
          selected === planType ? styles.selectedPlan : {},
        ]}
        onPress={() => {
          setSelected(planType);
        }}
      >
        <CustomText
          weight="600"
          style={[
            {
              fontSize: 14,
              textAlign: 'center',
              color: Colors.inputTextPlaceholderColor,
              textTransform: 'capitalize',
            },
            selected === planType ? styles.selectedPlanText : {},
          ]}
        >
          {planType}
        </CustomText>
      </Pressable>
    );
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
      {!showOnly || showOnly === 'monthly' ? <PlanTypeBadge planType="monthly" /> : null}
      {!showOnly || showOnly === 'prepaid' ? <PlanTypeBadge planType="prepaid" /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  selectedPlan: {
    backgroundColor: Colors.gradientBlue,
  },
  selectedPlanText: {
    color: Colors.white,
  },
});
export default PlanTypeBadges;
