import { View, Image, SafeAreaView, StyleSheet, Text, Dimensions, Pressable } from 'react-native';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import Button from '../../components/Button';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import { AppGap } from '../../components/AppGap';
import HorizontalLine from '../../components/HorizontalLine';
import LottieView from 'lottie-react-native';
import React from 'react';

import { PropType } from './index';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';
const { width, height } = Dimensions.get('window');

export default ({ onPressConfirm }: PropType) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <AuthBackground />
        <View
          style={{
            position: 'relative',
            marginTop: -160,
            marginLeft: 30,
          }}
        >
          <Image
            source={require('../../assets/illustrations/Blue14.png')}
            style={{
              width: 300,
              height: 350,
              zIndex: 10,
            }}
            resizeMode="contain"
          />
          <View
            style={{
              width: 500,
              height: height / 2,
              position: 'absolute',
              top: -70,
              zIndex: 8,
              right: -70,
            }}
          >
            <LottieView
              style={{ flex: 1 }}
              autoPlay
              loop={true}
              source={require('../../assets/illustrations/animated/Firework_3.json')}
            />
          </View>
        </View>
        <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
          <View style={{ width: '100%' }}>
            <CustomText
              weight="500"
              style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
            >
              Welcome to PhoneBox!
            </CustomText>
            <View style={{ marginBottom: 20 }}>
              <CustomText style={{ fontSize: 12 }}>
                You're verified and ready to start using the app!
              </CustomText>
            </View>
            <HorizontalLine style={{ marginBottom: 20 }} />

            <Button style={{ marginTop: 10 }} onPress={onPressConfirm} textStyle={{ fontSize: 14 }}>
              Explore the app
            </Button>
            <AppGap direction="vertical" size={30} />
          </View>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};
