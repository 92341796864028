import React, { useState, useRef } from 'react';
import { View, TextInput, StyleSheet, Platform } from 'react-native';
import { Colors } from '../constants/Colors';
import { onlyNumberRegex } from '../constants/constants';
import * as Clipboard from 'expo-clipboard';

type PropType = {
  onChangeText(otp: string): void;
  isError?: boolean;
};

const OTPInput = ({ onChangeText, isError }: PropType) => {
  // Define the state for OTP input values
  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));

  // Create a ref for input fields
  const inputRefs = useRef<(TextInput | null)[]>([]);

  // Function to handle text changes
  const handleChangeText = async (text: string, index: number) => {
    // This is commneted as I have not tested it properly
    // if (Platform.OS === 'web') {
    //   const copiedString = await Clipboard.getStringAsync();
    //   console.log({ copiedString });
    //   if (/^\d{6}$/.test(copiedString)) {
    //     setOtp(copiedString.split(''));
    //     return;
    //   }
    // }
    if (!onlyNumberRegex.test(text)) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = text;

    // Move to next input if the text length is 1
    if (text.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    // If text is empty, move to previous input
    if (text.length === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }

    setOtp(newOtp);
    onChangeText(newOtp.join(''));
  };

  // Define styles using StyleSheet
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignSelf: 'center',
      marginTop: 25,
    },
    input: {
      width: 48,
      textAlign: 'center',
      fontSize: 18,
      backgroundColor: isError ? Colors.errorRed20Percent : Colors.backgroundGrey,
      padding: 8,
      borderRadius: 8,
      height: 48,
      paddingHorizontal: 10,
      marginHorizontal: 2,
      color: isError ? Colors.errorRed : Colors.black,
    },
  });

  return (
    <View style={styles.container}>
      {otp.map((value, index) => (
        <TextInput
          key={index}
          style={styles.input}
          value={value}
          selectTextOnFocus={true}
          onChangeText={(text) => handleChangeText(text, index)}
          onKeyPress={(e) => {
            if (e.nativeEvent.key === 'Backspace') {
              inputRefs.current[index]?.clear();
              otp[index] = '';
              setOtp([...otp]);
              if (index > 0) {
                inputRefs.current[index - 1]?.focus();
              }
            }
          }}
          keyboardType="numeric"
          placeholder="-"
          inputMode='numeric'
          placeholderTextColor={isError ? Colors.errorRed80Percent : Colors.mediumGrey}
          maxLength={1}
          ref={(ref) => (inputRefs.current[index] = ref)}
        />
      ))}
    </View>
  );
};

export default OTPInput;
