import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  Image,
  Pressable,
  FlatList,
  Alert,
  Platform,
} from 'react-native';
import { RootStackScreenProps } from '../../types';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Button from '../../components/Button';
import { useState } from 'react';
import { AppDrawer } from '../../components/AppDrawer';
import HorizontalLine from '../../components/HorizontalLine';

export default function OnboardingScreen({ navigation }: RootStackScreenProps<'Onboarding'>) {
  const { firstName } = useSelector((state: RootState) => state.user);

  const [showModel, setShowModel] = useState<boolean>(false);

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <View
        style={{
          backgroundColor: Colors.white,
          padding: 20,
          flex: 1,
        }}
      >
        <View style={{ justifyContent: 'space-between', flex: 1 }}>
          <View>
            <CustomText
              weight="600"
              style={{ fontSize: 24, textAlign: 'center', color: Colors.canadaButtonColor }}
            >
              Welcome, {firstName}!
            </CustomText>
            <CustomText
              weight="500"
              style={{ fontSize: 14, textAlign: 'center', color: Colors.TextBlack }}
            >
              Select your Destination!
            </CustomText>

            <View style={{ marginTop: 20 }}>
              <Image
                source={require('../../assets/images/canada-flag.png')}
                style={{
                  width: '100%',
                  height: 160,
                  marginBottom: 16,
                }}
                resizeMode="cover"
              />
              <Pressable
                onPress={() => {
                  setShowModel(true);
                }}
              >
                <Image
                  source={require('../../assets/images/usa-flag.png')}
                  style={{
                    width: '100%',
                    height: 160,
                    marginBottom: 16,
                  }}
                  resizeMode="cover"
                />
              </Pressable>
            </View>
          </View>
          <Button
            style={{ marginTop: 32 }}
            onPress={() => {
              navigation.navigate('Plans');
            }}
            textStyle={{ fontSize: 14 }}
          >
            Continue
          </Button>
        </View>
      </View>
      <AppDrawer
        onClose={() => {
          setShowModel(false);
        }}
        height={500}
        visible={showModel}
        showBackDrop={true}
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Visit PhoneBox website to Purchage US Plan
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText weight="400" style={{ fontSize: 14, textAlign: 'justify', marginVertical: 16 }}>
          Sorry, we are currently not able to proceed us plan activation here. Please visit phonebox
          website to proceed Activation.
        </CustomText>
        <Button
          onPress={() => {
            setShowModel(false);
            Platform.select({
              web: alert('Update this to add link'),
              default: Alert.alert('Add Link', 'Update this to add link'),
            });
          }}
          textStyle={{ fontSize: 14 }}
        >
          Visit Website
        </Button>
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setShowModel(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Choose Canadian Plan
        </Button>
      </AppDrawer>
    </SafeAreaView>
  );
}
