import { Platform, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { RootStackScreenProps } from '../../types';
import PageHeader from '../../components/UI/Header/PageHeader';
import AppGradientContainer from '../../components/AppGradientContainer';
import ActiveUserHero from '../../components/UI/Hero/ActiveUserHero';
import { HERO_GRADIENT, SUSPENSION_TYPE } from '../../constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import QuickActions from '../../components/QuickAction/QuickActions';
import QuickAction, { Action } from '../../components/QuickAction/QuickAction';
import CustomText from '../../components/utils/CustomText';
import MenuItem from '../../components/UI/ListItem/MenuItem';
import { Colors } from '../../constants/Colors';
import { Entypo, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import VerticalLine from '../../components/VerticalLine';
import HorizontalLine from '../../components/HorizontalLine';
import { CrispChatAction } from '../../utilities/functions';

const manageTabActions: Action[] = [
  {
    image: require('../../assets/icons/action/help-center.png'),
    title: 'Help Center',
    tag: 'HELP_CENTER',
  },
  {
    image: require('../../assets/icons/action/chat.png'),
    title: 'Chat with Agent',
    tag: 'CHAT_WITH_AGENT',
  },
  {
    image: require('../../assets/icons/action/ticket.png'),
    title: 'Support Ticket',
    tag: 'SUPPORT_TICKET',
  },
  {
    image: require('../../assets/icons/action/blog.png'),
    title: 'Blog',
    tag: 'BLOG_ACTION',
  },
];

export default function SettingsScreen({ navigation }: RootStackScreenProps<'Settings'>) {
  const { loggedIn, accounts, id, email, firstName, lastName } = useSelector(
    (state: RootState) => state.user
  );
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  let gradientInfo = HERO_GRADIENT.default;
  if (accountProfileCA.Suspended && accountProfileCA.SuspensionReason) {
    gradientInfo = HERO_GRADIENT[SUSPENSION_TYPE[accountProfileCA.SuspensionReason]];
  }

  const backIcon = <Entypo name="chevron-thin-right" size={16} color={Colors.greyColor} />;
  const movieIcon = (
    <MaterialCommunityIcons name="movie-open-outline" size={16} color={Colors.ActionLinkColor} />
  );

  return (
    <SafeAreaView style={{ flex: 1, paddingTop: StatusBar.currentHeight }}>
      <PageHeader />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          paddingTop: Platform.select({ ios: 60, default: 80 }),
          paddingBottom: Platform.select({ ios: 40, default: 30 }),
          backgroundColor: Colors.white,
          paddingHorizontal: 20,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: 16,
          }}
        >
          {manageTabActions.map((action, index) => {
            return (
              <QuickAction
                action={action}
                key={index}
                onActionSelected={(tag) => {
                  switch (tag) {
                    case 'HELP_CENTER':
                      break;
                    case 'CHAT_WITH_AGENT':
                      CrispChatAction(
                        loggedIn,
                        id,
                        email,
                        firstName,
                        lastName,
                        accountProfileCA.PhoneNumber
                      );
                      break;
                    case 'SUPPORT_TICKET':
                      break;
                    case 'BLOG_ACTION':
                      break;
                  }
                  console.log(tag);
                }}
              />
            );
          })}
        </View>
        <View>
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
          >
            Account
          </CustomText>
          <View
            style={{ padding: 8, gap: 4, backgroundColor: Colors.backgroundGrey, borderRadius: 16 }}
          >
            <MenuItem
              frontIcon={
                <FontAwesome5 name="user-circle" size={16} color={Colors.ActionLinkColor} />
              }
              title="My Account"
              backIcon={backIcon}
              onPress={() => navigation.push('Account')}
            />
            <MenuItem
              frontIcon={<Entypo name="mobile" size={16} color={Colors.ActionLinkColor} />}
              title="My Orders"
              backIcon={backIcon}
              onPress={() => navigation.push('MyOrders')}
            />
            <MenuItem
              frontIcon={
                <MaterialCommunityIcons name="target" size={16} color={Colors.ActionLinkColor} />
              }
              title="Activity Tracking"
              backIcon={backIcon}
              onPress={() => navigation.push('StatusTracking')}
            />
          </View>
        </View>

        <View>
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
          >
            Plan Management
          </CustomText>
          <View
            style={{ padding: 8, gap: 4, backgroundColor: Colors.backgroundGrey, borderRadius: 16 }}
          >
            <MenuItem
              frontIcon={movieIcon}
              title="Vacation Plan"
              backIcon={backIcon}
              // onPress={() => navigation.push('EditName')}
            />
            <MenuItem
              frontIcon={movieIcon}
              title="SIM Lost/Stolen Hold"
              backIcon={backIcon}
              // onPress={() => navigation.push('EditName')}
            />
            <MenuItem
              frontIcon={movieIcon}
              title="Porting"
              backIcon={backIcon}
              onPress={() => navigation.push('PortOption')}
            />
            <MenuItem
              frontIcon={movieIcon}
              title="Change Plan"
              backIcon={backIcon}
              onPress={() => navigation.push('ChangePlan')}
            />
            <MenuItem
              frontIcon={movieIcon}
              title="Terminate Plan"
              backIcon={backIcon}
              // onPress={() => navigation.push('EditName')}
            />
          </View>
        </View>

        {/* <View>
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
          >
            Screen
          </CustomText>
          <View
            style={{ padding: 8, gap: 4, backgroundColor: Colors.backgroundGrey, borderRadius: 16 }}
          >
            <MenuItem
              frontIcon={movieIcon}
              title="Language"
              backIcon={backIcon}
              // onPress={() => navigation.push('EditName')}
            />
            <MenuItem
              frontIcon={movieIcon}
              title="Dark Mode"
              backIcon={backIcon}
              // onPress={() => navigation.push('EditName')}
            />
          </View>
        </View> */}

        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 24 }}>
          <CustomText style={{ width: 130, textAlign: 'center' }}>Term & Condition</CustomText>
          <VerticalLine thinkness={1} />
          <CustomText style={{ width: 130, textAlign: 'center' }}>Blog</CustomText>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
