import { RootStackScreenProps } from '../../types';
import {
  Entypo,
  Feather,
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import CustomText from '../../components/utils/CustomText';
import { Pressable, SafeAreaView, StatusBar, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import Button from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import TitleWithSupTitle from '../../components/UI/TitleWithSupTitle';
import MenuItem from '../../components/UI/ListItem/MenuItem';
import { AppDrawer } from '../../components/AppDrawer';
import HorizontalLine from '../../components/HorizontalLine';
import { tokenService } from '../../services/TokenService';
import { resetUser } from '../../store/user';

// type InformationFieldDestination = 'EditName' | 'EditPassword' | 'EditEmail' | 'EditAddress';

export default function AccountScreen({ navigation }: RootStackScreenProps<'Account'>) {
  const [logoutModelShown, setLogoutModelShown] = useState(false);

  const dispatch = useDispatch();

  const { firstName, lastName, email } = useSelector((store: RootState) => store.user);
  const { Address } = useSelector((store: RootState) => store.selfServeCA.accountProfileCA);

  const userIcon = <FontAwesome5 name="user-circle" size={16} color={Colors.ActionLinkColor} />;
  const passwordIcon = <MaterialIcons name="password" size={16} color={Colors.ActionLinkColor} />;
  const emailIcon = <Entypo name="email" size={16} color={Colors.ActionLinkColor} />;
  const addressIcon = (
    <FontAwesome name="address-card-o" size={16} color={Colors.ActionLinkColor} />
  );
  const deleteIcon = (
    <MaterialCommunityIcons name="delete-forever" size={16} color={Colors.ActionLinkColor} />
  );
  const logOutIcon = <Feather name="log-out" size={16} color={Colors.ActionLinkColor} />;
  const backIcon = <Entypo name="chevron-thin-right" size={16} color={Colors.greyColor} />;

  return (
    <View
      style={{
        flex: 1,
        height: '100%',
        padding: 20,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CustomText
          style={{ color: Colors.greyColor, fontSize: 12, fontWeight: '500' }}
          weight="500"
        >
          Account Profile
        </CustomText>
        {/* <Pressable
          onPress={() => {
            navigation.navigate('EditAddress');
          }}
          style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 4 }}
        >
          <SimpleLineIcons name="pencil" size={14} color={Colors.canadaButtonColor} />
          <CustomText weight="600" style={{ fontSize: 14, color: Colors.canadaButtonColor }}>
            Edit
          </CustomText>
        </Pressable> */}
      </View>
      <View
        style={{
          backgroundColor: Colors.backgroundGrey,
          padding: 16,
          borderRadius: 16,
          marginTop: 8,
        }}
      >
        <TitleWithSupTitle title={`${firstName} ${lastName}`} supTitle="Name" />
        <HorizontalLine
          style={{
            marginTop: -8,
            marginBottom: 12,
          }}
        />
        <TitleWithSupTitle title={email} supTitle="Email" />
        <HorizontalLine
          style={{
            marginTop: -8,
            marginBottom: 12,
          }}
        />
        <TitleWithSupTitle
          title={Address || 'No Address Added to the Profile'}
          supTitle="Address"
        />
        <HorizontalLine
          style={{
            marginTop: -8,
          }}
        />
      </View>
      <View>
        <CustomText
          weight="600"
          style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 8, marginTop: 32 }}
        >
          Account Management
        </CustomText>
        <View
          style={{ padding: 8, gap: 4, backgroundColor: Colors.backgroundGrey, borderRadius: 16 }}
        >
          <MenuItem
            frontIcon={userIcon}
            title="Update Name"
            backIcon={backIcon}
            onPress={() => navigation.push('EditName')}
          />
          <MenuItem
            frontIcon={passwordIcon}
            title="Update Password"
            backIcon={backIcon}
            onPress={() => navigation.push('EditPassword')}
          />
          <MenuItem
            frontIcon={emailIcon}
            title="Update Email"
            backIcon={backIcon}
            onPress={() => navigation.push('EditEmail')}
          />
          <MenuItem
            frontIcon={addressIcon}
            title="Update Address"
            backIcon={backIcon}
            onPress={() => navigation.push('EditAddress')}
          />
          <MenuItem
            frontIcon={deleteIcon}
            title="Delete Account"
            backIcon={backIcon}
            onPress={() => navigation.push('DeleteAccount')}
          />
          <MenuItem
            frontIcon={logOutIcon}
            title="Sign Out"
            backIcon={backIcon}
            onPress={() => setLogoutModelShown(true)}
          />
        </View>
      </View>
      <AppDrawer
        visible={logoutModelShown}
        height={385}
        onClose={() => {
          setLogoutModelShown(false);
        }}
        showBackDrop
      >
        <CustomText weight="500" style={{ fontSize: 24, marginBottom: 16 }}>
          Are you sure you want to sign out?
        </CustomText>
        <HorizontalLine thinkness={1} />
        <CustomText style={{ fontSize: 16, marginTop: 16 }}>
          Don't worry, you can easily log back in anytime to access your account!
        </CustomText>
        <Button
          style={{ marginTop: 32 }}
          onPress={async () => {
            console.log('Logout');
            await tokenService.deleteAccessTokenAsync();
            await tokenService.deleteAccessTokenExpiryAsync();
            await tokenService.deleteRefreshTokenAsync();
            await tokenService.deleteRefreshTokenExpiryAsync();
            await tokenService.deleteCASelfServeTokenAsync();
            await tokenService.deleteRememberMe();
            dispatch(resetUser());
          }}
          textStyle={{ fontSize: 14 }}
        >
          Sign Out
        </Button>
        <Button
          style={{ marginTop: 16 }}
          color={Colors.backgroundGrey}
          textColor={Colors.inputTextPlaceholderColor}
          onPress={() => {
            setLogoutModelShown(false);
          }}
          textStyle={{ fontSize: 14, fontWeight: '600' }}
        >
          Keep Sign In
        </Button>
      </AppDrawer>
    </View>
  );
}
