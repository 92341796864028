import { Animated, Platform, Pressable, ScrollView, View } from 'react-native';
import { Colors } from '../../constants/Colors';
import Button from '../../components/Button';
import CustomText from '../../components/utils/CustomText';
import HorizontalLine from '../../components/HorizontalLine';
import { useRef, useState } from 'react';
import { Entypo } from '@expo/vector-icons';
import Badge from '../../components/UI/Badge';

const PromotionPlan = ({ plan, selectedPlan }: { plan: any; selectedPlan: any }) => {
  const [detailVisiable, setDetailVisiable] = useState(false);
  const heightAnim = useRef(new Animated.Value(0)).current;

  const toggleExpand = () => {
    Animated.timing(heightAnim, {
      toValue: detailVisiable ? 0 : Platform.select({ web: 125, default: 145 }),
      duration: 300,
      useNativeDriver: false,
    }).start();
    setDetailVisiable(!detailVisiable);
  };

  return (
    <Pressable
      style={{
        padding: 16,
        borderWidth: 1,
        borderColor: Colors.activePlanBorderColor,
        borderRadius: 12,
        backgroundColor:
          // selectedPlan && selectedPlan.planId === plan.planId
          //   ? Colors.ActionLinkColorBG :
          Colors.noColor,
      }}
      onPress={() => {
        console.log('Test');
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 14,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Entypo name="mobile" size={24} color={Colors.canadaButtonColor} />
          <CustomText
            weight="500"
            style={{ fontSize: 12, color: Colors.QuickActionTextColor, marginLeft: 8 }}
          >
            Canadian Mobile Plan
          </CustomText>
        </View>
        <Badge
          title={Math.random() > 0.5 ? 'Monthly' : `${'7'} Days`}
          status="light-blue"
          fontweight="600"
        />
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <CustomText weight="500" style={{ fontSize: 20, color: Colors.canadaButtonColor }}>
          {'50GB'}
        </CustomText>
        <CustomText weight="600" style={{ fontSize: 20 }}>
          $35.00
        </CustomText>
      </View>
      <HorizontalLine thinkness={1} style={{ marginTop: 16, marginBottom: 10 }} />
      <Animated.View
        style={[
          {
            height: heightAnim,
            opacity: heightAnim.interpolate({
              inputRange: [0, 100],
              outputRange: [0, 1],
            }),
          },
        ]}
      >
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Plan will reset on the 2nd of every month
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; UNLIMITED picture messaging
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Supported on the {Math.random() > 0.5 ? 'Rogers' : 'Telus'} Network
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Call Display
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Voicemail Up to 35 minutes of messages
        </CustomText>
        <CustomText
          style={{ color: Colors.inputTextPlaceholderColor, fontSize: 12, marginBottom: 2 }}
        >
          &bull; Data Overage Rate: $15.00/1GB
        </CustomText>
      </Animated.View>
      <CustomText
        weight="600"
        onPress={toggleExpand}
        style={{
          color: Colors.ActionLinkColor,
          fontWeight: '600',
          fontSize: 12,
          textAlign: 'center',
          marginTop: Platform.select({ web: 8, default: 10 }),
        }}
      >
        {detailVisiable ? 'Hide' : 'Show'} details
      </CustomText>
    </Pressable>
  );
};

const PortOutPlanView = ({ onPress }: { onPress(option: string): void }) => {
  return (
    <View
      style={{
        position: 'relative',
        padding: 20,
        backgroundColor: Colors.white,
        flex: 1,
        height: '100%',
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ marginBottom: 150, paddingBottom: 20 }}
      >
        <CustomText weight="500" style={{ fontSize: 20 }}>
          It&apos;s sad to let you go...
        </CustomText>
        <CustomText style={{ fontSize: 16 }}>Here&apos;s some amazing deals!</CustomText>
        <HorizontalLine thinkness={1} style={{ marginVertical: 20 }} />
        <CustomText weight="500" style={{ fontSize: 14 }}>
          Promotion Plan
        </CustomText>
        <View style={{ gap: 16, marginTop: 16 }}>
          {['', '', '', ''].map((plan) => (
            <PromotionPlan plan={plan} selectedPlan={plan} />
          ))}
        </View>
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <Button
          style={{ marginTop: 32 }}
          onPress={() => onPress('OFFER')}
          textStyle={{ fontSize: 14 }}
        >
          Take This Offer!
        </Button>
        <Button
          style={{ marginTop: 10 }}
          color={Colors.backgroundGrey}
          textColor={Colors.TextBlack}
          onPress={() => onPress('CONTINUE')}
        >
          <CustomText weight="600" style={{ fontSize: 14, fontWeight: '600' }}>
            Continue With Port Out
          </CustomText>
        </Button>
      </View>
    </View>
  );
};

export default PortOutPlanView;
