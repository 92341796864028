import { View, ScrollView, RefreshControl, Pressable, SafeAreaView, StatusBar } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { RootStackScreenProps } from '../../types';
import { Colors } from '../../constants/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect, useState } from 'react';
import { accountService } from '../../services/MyAccount/AccountService';
import { DATE_FORMAT, DATE_FORMAT_FOR_API, HERO_GRADIENT } from '../../constants/constants';
import { AppGap } from '../../components/AppGap';
import PageHeader from '../../components/UI/Header/PageHeader';
import AppGradientContainer from '../../components/AppGradientContainer';
import CustomText from '../../components/utils/CustomText';
import HorizontalLine from '../../components/HorizontalLine';
import moment from 'moment';
import { formatNumber } from '../../utilities/utilities';
import QuickAction, { Action } from '../../components/QuickAction/QuickAction';
import { setIsLoading } from '../../store/app';
import { InvoiceInfo } from '../../types/models/InvoiceInfo';
import { setInvoices, setRecentTransaction } from '../../store/selfServeCA';
import { RecentTransaction } from '../../types/models/RecentTransaction';
import InvoiceListItem from '../../components/UI/ListItem/InvoiceListItem';
import RecentTransactionListItem from '../../components/UI/ListItem/RecentTransactionListItem';

const gradientInfo = HERO_GRADIENT.default;

const billQuickActions: Action[] = [
  {
    image: require('../../assets/icons/action/pay-bill.png'),
    title: 'Pay Bill',
    tag: 'PAY_BILL',
  },
  {
    image: require('../../assets/icons/action/redeem-promo-code.png'),
    title: `${'Redeem Promo Code'}`,
    tag: 'RDEEM_PROMO_CODE',
  },
  {
    image: require('../../assets/icons/action/payment.png'),
    title: 'Manage Payment',
    tag: 'MANAGE_PAYMENT',
  },
];

export default function BillingScreen({ navigation }: RootStackScreenProps<'Billing'>) {
  const dispatch = useDispatch();

  const {
    accountInfoCA: { Prepaid: isPrepaid, CurrentBalance, EndDate },
    recentTransaction,
    invoices,
  } = useSelector((state: RootState) => state.selfServeCA);
  const [paymentDollar, paymentCent]: string[] = CurrentBalance.toString().split('.');

  const remainingDays = isPrepaid ? moment(EndDate).diff(moment(), 'days') + 1 : 30;
  const _paymentString = { paymentDate: '', paymentInfo: 'Pay Before: ' };

  const [currentView, setCurrentView] = useState<'TRANSACTION' | 'INVOICES'>('TRANSACTION');

  if (isPrepaid) {
    _paymentString.paymentInfo = 'Expires on:';
    _paymentString.paymentDate = moment(EndDate).format(DATE_FORMAT);
  } else {
    if (CurrentBalance) {
      _paymentString.paymentDate = moment().format('MMM [25], YYYY');
    } else {
      _paymentString.paymentInfo = '';
    }
  }

  const onActionSelected = (action: string) => {
    console.log(action);

    switch (action) {
      case 'PAY_BILL':
        navigation.push('BillingPayBalance');
        break;
      case 'RDEEM_PROMO_CODE':
        break;
      case 'MANAGE_PAYMENT':
        navigation.push('ManagePayment');
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    try {
      dispatch(setIsLoading(true));
      const ApiCall = [
        accountService.recentAccountTransaction(moment().format(DATE_FORMAT_FOR_API)),
        accountService.invoiceInfo(new Date().getFullYear()),
      ];
      const [{ data: recentTransaction }, { data: invoices }] = await Promise.all(ApiCall);
      dispatch(setRecentTransaction(recentTransaction as RecentTransaction[]));
      dispatch(setInvoices(invoices as InvoiceInfo[]));
    } catch (error) {
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, paddingTop: StatusBar.currentHeight }}>
      <PageHeader />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: Colors.white,
        }}
        refreshControl={<RefreshControl refreshing={false} onRefresh={fetchData} />}
      >
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: 80,
          }}
        >
          <View
            style={{
              backgroundColor: Colors.white,
              borderRadius: 15,
              marginHorizontal: 24,
              marginTop: 16,
              marginBottom: 20,
              padding: 16,
            }}
          >
            <CustomText
              weight="600"
              style={{
                fontWeight: '600',
                fontSize: 10,
                color: Colors.inputTextPlaceholderColor,
                marginBottom: 8,
              }}
            >
              Account Balance
            </CustomText>
            <CustomText
              weight="600"
              style={{
                fontSize: 24,
                fontWeight: '600',
                color: Colors.TextBlack,
              }}
            >
              {isPrepaid ? remainingDays : `$${formatNumber(paymentDollar)}`}
              <CustomText
                style={[
                  { color: Colors.inputTextPlaceholderColor },
                  isPrepaid ? { fontSize: 15 } : {},
                ]}
              >
                {isPrepaid ? ' Days left' : `.${paymentCent?.padEnd(2, '0') ?? '00'}`}
              </CustomText>
            </CustomText>
            {_paymentString.paymentInfo ? (
              <CustomText
                weight="500"
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  fontWeight: '500',
                }}
              >
                <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>
                  {_paymentString.paymentInfo}{' '}
                </CustomText>
                {_paymentString.paymentDate}
              </CustomText>
            ) : null}
            <HorizontalLine thinkness={1} style={{ marginVertical: 16 }} />
            <View
              style={{
                display: 'flex',
                paddingHorizontal: 10,
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {billQuickActions.map((action, index) => (
                <QuickAction
                  action={action}
                  key={index}
                  onActionSelected={onActionSelected}
                  textWidth={action.tag === 'RDEEM_PROMO_CODE' ? 80 : null}
                />
              ))}
            </View>
          </View>
        </AppGradientContainer>

        <View
          style={{
            width: '100%',
            marginTop: -20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            backgroundColor: Colors.white,
          }}
        >
          <View
            style={{
              marginTop: 36,
              paddingHorizontal: 20,
              flexDirection: 'row',
              gap: 26,
              borderColor: Colors.backgroundGrey,
              borderBottomWidth: 4,
            }}
          >
            <CustomText
              weight="500"
              style={{
                color: currentView === 'TRANSACTION' ? Colors.TextBlack : Colors.grey,
                borderColor:
                  currentView === 'TRANSACTION' ? Colors.gradientBlue : Colors.backgroundGrey,
                borderBottomWidth: 4,
                fontSize: 16,
                marginBottom: -4,
              }}
              onPress={() => {
                setCurrentView('TRANSACTION');
              }}
            >
              Transaction
            </CustomText>
            <CustomText
              weight="500"
              style={{
                color: currentView === 'INVOICES' ? Colors.TextBlack : Colors.grey,
                borderColor:
                  currentView === 'INVOICES' ? Colors.gradientBlue : Colors.backgroundGrey,
                borderBottomWidth: 4,
                fontSize: 16,
                marginBottom: -4,
              }}
              onPress={() => {
                setCurrentView('INVOICES');
              }}
            >
              Invoices
            </CustomText>
          </View>
          {/* Listing */}
          {currentView === 'TRANSACTION' ? (
            <View
              style={{
                padding: 20,
              }}
            >
              {recentTransaction.map((transaction, index) => (
                <View key={index}>
                  <RecentTransactionListItem transaction={transaction} />
                  {index < recentTransaction.length - 1 ? <HorizontalLine thinkness={1} /> : null}
                </View>
              ))}
            </View>
          ) : (
            <View
              style={{
                padding: 20,
              }}
            >
              {invoices.map((invoice, index) => (
                <View key={index}>
                  <InvoiceListItem invoice={invoice} />
                  {index < invoices.length - 1 ? <HorizontalLine thinkness={1} /> : null}
                </View>
              ))}
            </View>
          )}
          {/* View All Button */}
          {currentView === 'INVOICES' ? (
            <Pressable
              onPress={() => {
                navigation.push('Invoices');
              }}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                alignSelf: 'flex-end',
                marginRight: 20,
              }}
            >
              <CustomText
                weight="500"
                style={{
                  color: Colors.canadaButtonColor,
                  fontSize: 12,
                }}
              >
                See All{' '}
              </CustomText>
              <Entypo name="chevron-small-right" size={18} color={Colors.canadaButtonColor} />
            </Pressable>
          ) : null}
          <AppGap direction="vertical" size={20} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
