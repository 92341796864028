import { Alert, Image, Platform, View } from 'react-native';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { Colors } from '../../../constants/Colors';
import Button from '../../Button';
import HorizontalLine from '../../HorizontalLine';
import Donut from '../Donut';
import {
  formatNumber,
  getDataUsage,
  // getDetailedDate,
  getRemainingDataString,
  getUsagePercentage,
} from '../../../utilities/utilities';
import { RootState } from '../../../store';
import CustomText from '../../utils/CustomText';
import DataUsage from '../DataUsage';
import { useNavigation } from '@react-navigation/native';
import { DATE_FORMAT } from '../../../constants/constants';

type Props = {};

const ActiveUserHero = (props: Props) => {
  const navigation = useNavigation();
  const accountInfoCA = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const accountProfileCA = useSelector((state: RootState) => state.selfServeCA.accountProfileCA);

  const [paymentDollar, paymentCent]: string[] = accountInfoCA.CurrentBalance.toString().split('.');

  const _currentDate = moment();
  let _resetDateData = moment();
  if (_currentDate.date() !== 1) {
    _resetDateData.add(1, 'months');
  }

  const _resetDate = accountInfoCA.Prepaid
    ? moment(accountInfoCA.EndDate).format(DATE_FORMAT)
    : _resetDateData.format('MMM [02], YYYY');

  const remainingDays = accountInfoCA.Prepaid
    ? moment(accountInfoCA.EndDate).diff(moment(), 'days') + 1
    : 30;

  let _paymentError: false | '< 3 Days Left' | 'Due Today' | 'Past Due Date' | 'Pay Before 9' =
    false;

  const _paymentString = { paymentDate: '', paymentInfo: 'Pay Before: ' };

  if (accountInfoCA.Prepaid) {
    _paymentString.paymentInfo = 'Expires on:';
    _paymentString.paymentDate = _resetDate;
  } else {
    if (accountInfoCA.CurrentBalance) {
      if (_currentDate.date() > 14 && _currentDate.date() < 26) {
        _paymentString.paymentDate = moment().format('MMM [25], YYYY');
        if (_currentDate.date() + 2 >= 25) {
          _paymentError = '< 3 Days Left';
        }
        if (_currentDate.date() === 25) {
          _paymentError = 'Due Today';
        }
      } else if (
        _currentDate.date() > 25 &&
        (_currentDate.date() <= 31 || _currentDate.date() === 1)
      ) {
        _paymentError = 'Past Due Date';
        _paymentString.paymentDate = moment().add(1, 'months').format('MMM [01], YYYY');
        if (_currentDate.date() === 1) {
          _paymentString.paymentDate = moment().format('MMM [01], YYYY');
        }
      } else {
        _paymentError = 'Pay Before 9';
        _paymentString.paymentInfo = 'Account Suspended!';
      }
    } else {
      _paymentString.paymentInfo = '';
    }
  }

  const PaymentError = () => {
    const _3DaysLeft = (
      <CustomText
        weight="500"
        style={{
          marginLeft: 8,
          marginTop: 2,
          fontWeight: '500',
          fontSize: 14,
          color: Colors.inputTextPlaceholderColor,
        }}
      >
        <CustomText style={{ color: Colors.errorRed }}>{26 - _currentDate.date()} days </CustomText>
        left to pay your bill
      </CustomText>
    );

    const _dueToday = (
      <View style={{ marginLeft: 8, marginTop: 1 }}>
        <CustomText
          weight="500"
          style={{ fontWeight: '500', fontSize: 14, color: Colors.inputTextPlaceholderColor }}
        >
          Today is the <CustomText style={{ color: Colors.errorRed }}>due date.</CustomText>
        </CustomText>
        <CustomText style={{ fontSize: 12, color: Colors.inputTextPlaceholderColor, marginTop: 2 }}>
          Please complete your payment
        </CustomText>
      </View>
    );

    const _pastDueDate = (
      <View style={{ marginLeft: 8, marginTop: 2 }}>
        <CustomText
          weight="500"
          style={{ color: Colors.errorRed, fontWeight: '500', fontSize: 14 }}
        >
          The Payment due date has passed.
        </CustomText>
        <CustomText
          style={{
            fontSize: 12,
            color: Colors.inputTextPlaceholderColor,
            width: 250,
            marginTop: 2,
          }}
        >
          Please note that your number may be suspended in next cycle.
        </CustomText>
      </View>
    );

    const _payBefore9 = (
      <View style={{ marginLeft: 8, marginTop: 2 }}>
        <CustomText
          weight="500"
          style={{ color: Colors.errorRed, fontWeight: '500', fontSize: 14 }}
        >
          The Payment due date has passed.
        </CustomText>
        <CustomText
          style={{
            fontSize: 12,
            color: Colors.inputTextPlaceholderColor,
            width: 250,
            marginTop: 2,
          }}
        >
          Please pay before 9th, to save your phone&nbsp;number
        </CustomText>
      </View>
    );

    return (
      <View style={{ flexDirection: 'row', marginBottom: 8 }}>
        <AntDesign name="exclamationcircle" size={20} color={Colors.errorRed} />
        {_paymentError === '< 3 Days Left'
          ? _3DaysLeft
          : _paymentError === 'Due Today'
          ? _dueToday
          : _paymentError === 'Past Due Date'
          ? _pastDueDate
          : _paymentError === 'Pay Before 9'
          ? _payBefore9
          : null}
      </View>
    );
  };

  const InfoMessage = ({
    normalText = '',
    boldText = '',
  }: {
    normalText?: string;
    boldText?: string;
  }) => {
    return (
      <View style={{ flexDirection: 'row', marginBottom: 8 }}>
        <AntDesign name="exclamationcircle" size={20} color={Colors.errorRed} />
        <CustomText
          weight="500"
          style={{
            marginLeft: 8,
            fontWeight: '500',
            fontSize: 14,
            color: Colors.inputTextPlaceholderColor,
          }}
        >
          {normalText}{' '}
          <CustomText weight="600" style={{ color: Colors.black }}>
            {boldText}
          </CustomText>
        </CustomText>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: Colors.white,
        borderRadius: 15,
        marginHorizontal: 24,
        marginTop: 16,
        marginBottom: 20,
        padding: 16,
      }}
    >
      {_paymentError ? <PaymentError /> : null}
      <CustomText
        weight="600"
        style={{
          fontWeight: '600',
          fontSize: 10,
          color: Colors.inputTextPlaceholderColor,
          marginBottom: 8,
        }}
      >
        {accountInfoCA.Prepaid ? 'Prepaid Plan' : 'Account Balance'}
      </CustomText>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CustomText
          weight="600"
          style={{
            fontSize: 24,
            fontWeight: '600',
            color: accountInfoCA.Prepaid && remainingDays < 3 ? Colors.errorRed : Colors.TextBlack,
          }}
        >
          {accountInfoCA.Prepaid ? remainingDays : `$${formatNumber(paymentDollar)}`}
          <CustomText
            style={[
              { color: Colors.inputTextPlaceholderColor },
              accountInfoCA.Prepaid ? { fontSize: 15 } : {},
            ]}
          >
            {accountInfoCA.Prepaid ? ' Days left' : `.${paymentCent?.padEnd(2, '0') ?? '00'}`}
          </CustomText>
        </CustomText>
        <Button
          isChildNonText
          size="NORMAL"
          style={{
            paddingVertical: 8,
            paddingHorizontal: 12,
          }}
          color={
            accountInfoCA.Prepaid && remainingDays < 3
              ? Colors.errorRed
              : !accountInfoCA.Prepaid && _paymentError
              ? Colors.errorRed
              : undefined
          }
          onPress={
            accountInfoCA.Prepaid
              ? () => {
                  navigation.navigate('PrepaidPlanCheckout', {
                    update: 'extension',
                    planId: accountInfoCA.CurrentPlan_pfId,
                  });
                }
              : () => {
                  console.log('Postpaid Clicked');
                }
          }
        >
          <MaterialCommunityIcons name="plus" size={20} color={Colors.white} />
          <CustomText
            weight="600"
            style={{ color: Colors.white, marginLeft: 2, fontWeight: '600' }}
          >
            {accountInfoCA.Prepaid ? 'Extension' : 'Pay a Bill'}
          </CustomText>
        </Button>
      </View>
      {_paymentString.paymentInfo ? (
        <CustomText
          weight="500"
          style={{
            marginTop: 10,
            fontSize: 12,
            fontWeight: '500',
          }}
        >
          <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>
            {_paymentString.paymentInfo}{' '}
          </CustomText>
          {_paymentString.paymentDate}
        </CustomText>
      ) : null}
      <HorizontalLine style={{ marginVertical: 16 }} />
      {/* Data Usage */}
      {accountProfileCA.Suspended ? null : <DataUsage />}

      {/* Vacation Info  */}
      {accountProfileCA.Suspended && accountProfileCA.SuspensionReason === 'VC' ? (
        <>
          <InfoMessage normalText="Your account is currently on the" boldText="Vacation Plan." />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                fontWeight: '500',
              }}
            >
              <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>From: </CustomText>
              {moment(accountProfileCA.SuspensionDate).format(DATE_FORMAT)}
            </CustomText>
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                fontWeight: '500',
              }}
            >
              <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>
                Resume on:{' '}
              </CustomText>
              {moment(accountProfileCA.VacationEndDate).format(DATE_FORMAT)}
            </CustomText>
          </View>
        </>
      ) : null}

      {/* Lost and Stolen Info  */}
      {accountProfileCA.Suspended && accountProfileCA.SuspensionReason === 'LS' ? (
        <>
          <InfoMessage normalText="Suspended for" boldText="Lost/Stolen." />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <CustomText
              weight="500"
              style={{
                fontSize: 12,
                fontWeight: '500',
              }}
            >
              <CustomText style={{ color: Colors.inputTextPlaceholderColor }}>From: </CustomText>
              {moment(accountProfileCA.SuspensionDate).format(DATE_FORMAT)}
            </CustomText>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Button
              textStyle={{ fontSize: 12 }}
              color={Colors.backgroundGrey}
              textColor={Colors.greyColor}
              style={{ paddingVertical: 8, width: 142 }}
              onPress={() => {
                console.log('Press Pause Data');
              }}
            >
              Request new SIM
            </Button>
            <Button
              textStyle={{ fontSize: 12 }}
              style={{ paddingVertical: 8, width: 142 }}
              onPress={() => {
                console.log('Press Add more data');
              }}
            >
              Resume My Plan
            </Button>
          </View>
        </>
      ) : null}

      {/* Unpaid Info  */}
      {accountProfileCA.Suspended && accountProfileCA.SuspensionReason === 'UP' ? (
        <>
          <InfoMessage normalText="Suspended for" boldText="unpaid." />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 4,
            }}
          >
            <Button
              textStyle={{ fontSize: 12 }}
              color={Colors.backgroundGrey}
              textColor={Colors.greyColor}
              style={{ paddingVertical: 8, width: 142 }}
              onPress={() => {
                console.log('Press Pause Data');
              }}
            >
              Request new SIM
            </Button>
            <Button
              textStyle={{ fontSize: 12 }}
              style={{ paddingVertical: 8, width: 142 }}
              onPress={() => {
                console.log('Press Add more data');
              }}
            >
              Retrieve Number
            </Button>
          </View>
        </>
      ) : null}

      {/* Add More Data Button */}
      {accountProfileCA.Suspended ? null : (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          {/* <Button
            textStyle={{ fontSize: 12 }}
            color={Colors.backgroundGrey}
            textColor={Colors.greyColor}
            style={{ paddingVertical: 8, width: 142 }}
            onPress={() => {
              console.log('Press Pause Data');
            }}
          >
            Pause Data
          </Button> */}
          <Button
            textStyle={{ fontSize: 12 }}
            style={{ paddingVertical: 8, width: 142 }}
            onPress={() => {
              const endDateString = moment(accountInfoCA.EndDate).format(DATE_FORMAT);
              if (Platform.OS === 'web') {
                if (
                  confirm(
                    `Your Current term end date is ${endDateString}. Do you still want to Top Up`
                  )
                ) {
                  navigation.navigate('AddMoreData');
                }
              } else {
                Alert.alert(
                  'Confirmation',
                  `Your Current term end date is ${endDateString}. Do you still want to Top Up`,
                  [
                    {
                      isPreferred: true,
                      onPress: () => {
                        navigation.navigate('AddMoreData');
                      },
                      text: 'Yes',
                    },
                    {
                      text: 'Cancel',
                    },
                  ]
                );
              }
              console.log('Press Add more data');
            }}
          >
            Add more data
          </Button>
        </View>
      )}
    </View>
  );
};

export default ActiveUserHero;
