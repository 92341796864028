import useIsMobile from '../../hooks/useIsMobile';
import { RootStackScreenProps } from '../../types';

import MobileView from './mobile';
import DesktopView from './desktop';
import { useEffect, useState } from 'react';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';

export type PropType = {
  onPressConfirm(): void;
};

export default function JoinSuccessScreen({
  navigation,
  route,
}: RootStackScreenProps<'JoinSuccess'>) {
  const isMobile = useIsMobile();
  const [user, setUser] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });

  useEffect(() => {
    // this only happens in redirect flow from sign up page
    const newUser = {
      email: route.params?.email ?? '',
      password: route.params?.password ?? '',
    };
    setUser(newUser);
  }, []);

  return (
    <ScrollViewWithSafeAreaContainer>
      {isMobile ? (
        <MobileView
          onPressConfirm={() =>
            navigation.replace('LogIn', { email: user.email, password: user.password })
          }
        />
      ) : (
        <DesktopView
          onPressConfirm={() =>
            navigation.replace('LogIn', { email: user.email, password: user.password })
          }
        />
      )}
    </ScrollViewWithSafeAreaContainer>
  );
}
