import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import * as AppleAuthentication from 'expo-apple-authentication';

import { Platform } from 'react-native';
import { Helmet } from 'react-helmet';
import { RootStackScreenProps } from '../../types';
import useIsMobile from '../../hooks/useIsMobile';
import { useEffect } from 'react';
import { authService } from '../../services/SSO/AuthService';
import { SystemCodes } from '../../types/enums/SystemCodes';
import { tokenService } from '../../services/TokenService';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../store/app';
import { setActiveUser, setLoggedIn } from '../../store/user';
import { SignInResponse } from '../../types/responses/SSO/SignInResponse';

import MobileView from './mobile';
import DesktopView from './desktop';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { accountService } from '../../services/MyAccount/AccountService';
import { RootState } from '../../store';
import { setAccountInfoCA } from '../../store/selfServeCA';

WebBrowser.maybeCompleteAuthSession();

export type PropType = {
  onSelectLine(selectedLine: string, selectedType: 'MOBILE' | 'HOME', accountCnum: string): void;
};

export default function SelectLineScreen({ navigation }: RootStackScreenProps<'Landing'>) {
  const isMobile = useIsMobile();
  const { loggedIn, accounts, cnum, activeCnum } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    navigation.navigate('Root');
    return;
    const haveAccountsOrIsSignedIn = loggedIn || cnum || accounts.length;
    if (!haveAccountsOrIsSignedIn) {
      navigation.navigate('Landing');
    } else {
      if (accounts.length === 1) {
      }
    }
  }, [loggedIn, accounts, cnum]);

  return (
    <>
      {isMobile ? (
        <ScrollViewWithSafeAreaContainer>
          <MobileView
            onSelectLine={async (selectedLine, selectedType, accountCnum) => {
              dispatch(
                setActiveUser({
                  user: {
                    firstName: selectedLine,
                    lastName: selectedLine,
                    cnum: accountCnum,
                  },
                })
              );
              const response = await accountService.getAccountInfo({});
              dispatch(setAccountInfoCA(response.data));
              dispatch(setLoggedIn(true)); // ! May be move this to login page. and move Select line under available pages when logged in.
            }}
          />
        </ScrollViewWithSafeAreaContainer>
      ) : (
        <DesktopView
          onSelectLine={() => {
            dispatch(setLoggedIn(true));
          }}
        />
      )}
    </>
  );
}
