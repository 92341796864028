import { Colors } from '../../constants/Colors';
import {
  Image,
  ImageSourcePropType,
  Pressable,
  SafeAreaView,
  ScrollView,
  View,
} from 'react-native';
import CustomText from '../../components/utils/CustomText';
import Button from '../../components/Button';
import { useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { PaymentType } from '../../types/types';
import { PaymentMethods } from '../../types/enums/enums';
import { HERO_GRADIENT } from '../../constants/constants';
import AppGradientContainer from '../../components/AppGradientContainer';
import { AppGap } from '../../components/AppGap';

const gradientInfo = HERO_GRADIENT.default;

type Props = {
  amountToPay: string;
  onNext(paymentInfo: { paymentTypeSlug: PaymentType; paymentType: PaymentMethods }): void;
};

const CheckMarkIcon = (
  <View
    style={{
      position: 'absolute',
      top: -10,
      right: -8,
      backgroundColor: Colors.white,
      borderRadius: 50,
    }}
  >
    <AntDesign name="checkcircle" size={24} color={Colors.canadaButtonColor} />
  </View>
);

type PaymentOptionProps = {
  selected: boolean;
  onPress(): void;
  image: ImageSourcePropType;
  title: string;
};

const PaymentOption = ({ selected, onPress, image, title }: PaymentOptionProps) => {
  return (
    <Pressable
      style={{
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 14,
        marginHorizontal: 10,
        position: 'relative',
        backgroundColor: selected ? Colors.SelectLineItemHighlightColor : Colors.white,
        borderRadius: 8,
      }}
      onPress={onPress}
    >
      {selected ? CheckMarkIcon : null}
      <Image source={image} resizeMode="contain" style={{ width: 44, height: 44 }} />
      <CustomText
        weight="500"
        style={{
          textAlign: 'center',
          marginTop: 5,
          fontSize: 14,
          fontWeight: '500',
          color: Colors.QuickActionTextColor,
          width: 60,
        }}
      >
        {title}
      </CustomText>
    </Pressable>
  );
};

export default function CheckoutView({ amountToPay, ...props }: Props) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    paymentTypeSlug: PaymentType;
    paymentType: PaymentMethods;
  } | null>(null);

  const PaymentOptionList = [
    {
      title: 'Credit Card',
      image: require('../../assets/icons/credit-card.png'),
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'CREDIT_CARD',
          paymentType: PaymentMethods.CREDIT_CARD,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'CREDIT_CARD',
    },
    {
      title: 'Google Pay',
      image: require('../../assets/icons/google-pay.png'),
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'GOOGLE_PAY',
          paymentType: PaymentMethods.GOOGLE_PAY,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'GOOGLE_PAY',
    },
    // {
    //   title: 'WeChat Pay',
    //   image: require('../../assets/icons/wechat-pay.png'),
    //   onPress: () => {
    //     setSelectedPaymentMethod({
    //       paymentTypeSlug: 'WECHAT_PAY',
    //       paymentType: PaymentMethods.WECHAT_PAY,
    //     });
    //   },
    //   selected: selectedPaymentMethod?.paymentTypeSlug === 'WECHAT_PAY',
    // },
    {
      title: 'PayPal',
      image: require('../../assets/icons/paypal.png'),
      onPress: () => {
        setSelectedPaymentMethod({
          paymentTypeSlug: 'PAYPAL',
          paymentType: PaymentMethods.PAYPAL,
        });
      },
      selected: selectedPaymentMethod?.paymentTypeSlug === 'PAYPAL',
    },
    // {
    //   title: 'AliPay',
    //   image: require('../../assets/icons/alipay.jpg'),
    //   onPress: () => {
    //     setSelectedPaymentMethod({
    //       paymentTypeSlug: 'ALIPAY',
    //       paymentType: PaymentMethods.ALIPAY,
    //     });
    //   },
    //   selected: selectedPaymentMethod?.paymentTypeSlug === 'ALIPAY',
    // },
  ];

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: Colors.white,
          marginBottom: 150,
        }}
      >
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: 80,
          }}
        >
          <CustomText
            weight="500"
            style={{ fontSize: 12, textAlign: 'center', color: Colors.white }}
          >
            Payment Amount
          </CustomText>
          <CustomText
            weight="600"
            style={{ fontSize: 40, color: Colors.white, textAlign: 'center' }}
          >
            $<CustomText style={{ color: Colors.white }}>{amountToPay}</CustomText>
          </CustomText>
          <AppGap size={48} direction="vertical" />
        </AppGradientContainer>
        <View
          style={{
            backgroundColor: Colors.white,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            marginTop: -24,
            padding: 20,
          }}
        >
          <CustomText
            weight="600"
            style={{ fontSize: 14, color: Colors.greyColor, marginBottom: 16 }}
          >
            Payment Methods
          </CustomText>

          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 5,
            }}
          >
            {PaymentOptionList.map((paymentOption) => (
              <PaymentOption {...paymentOption} key={paymentOption.title} />
            ))}
          </View>
        </View>
      </ScrollView>

      <View
        style={{
          position: 'absolute',
          bottom: 30,
          width: '85%',
          alignSelf: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <CustomText weight="600" style={{ fontSize: 16, color: Colors.TextBlack }}>
            Total Amount:
          </CustomText>
          <CustomText weight="600" style={{ fontSize: 16, color: Colors.canadaButtonColor }}>
            ${amountToPay}
          </CustomText>
        </View>
        <Button
          style={{ marginTop: 32 }}
          onPress={() => {
            props.onNext(selectedPaymentMethod!);
          }}
          textStyle={{ fontSize: 14 }}
          disabled={selectedPaymentMethod === null}
        >
          Pay
        </Button>
      </View>
    </SafeAreaView>
  );
}
