import { View, Image, SafeAreaView, StyleSheet, Text, Dimensions } from 'react-native';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import Button from '../../components/Button';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import { PropType } from './index';
import CustomText from '../../components/utils/CustomText';

const { width, height } = Dimensions.get('window');

export default ({ onPressConfirm }: PropType) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
          <Image
            source={require('../../assets/images/logo_white.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <BottomWhiteSheet height={350} style={{ paddingLeft: 30, paddingRight: 30 }}>
          <CustomText>This is Sparta</CustomText>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};

const styles = StyleSheet.create({
  logo: {
    flex: 1,
    width: 100,
    height: 50,
  },
});
