import { Dispatch } from '@reduxjs/toolkit';
import { accountService } from '../services/MyAccount/AccountService';
import { authService } from '../services/SSO/AuthService';
import { tokenService } from '../services/TokenService';
import { setIsInOnboardingFlow, setIsLoading } from '../store/app';
import { setAccountInfoCA, setAccountProfileCA } from '../store/selfServeCA';
import {
  setAccounts,
  setApiToken,
  setAuthorizedBy,
  setCnum,
  setLoggedIn,
  setProfile,
} from '../store/user';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../types';
import { IdentityApiResponse } from '../types/responses/ApiResponse';
import { SignInResponse } from '../types/responses/SSO/SignInResponse';
import { SignInAppleResponse } from '../types/responses/SSO/SignInAppleResponse';
import { SignInGoogleResponse } from '../types/responses/SSO/SignInGoogleResponse';

import * as CrispChatSDK from 'react-native-crisp-chat-sdk';
import { Platform } from 'react-native';

export const getUserProfileAsync = async (
  dispatch: Dispatch,
  navigation: NativeStackNavigationProp<RootStackParamList>,
  cnum: string
) => {
  const userProfileResponse = await authService.userProfile({});

  if (!userProfileResponse?.data?.success) {
    console.log('error handling: ');
    console.log('Is invalid user');
    // TODO : fix this in backend
    await tokenService.deleteAccessTokenAsync();
    await tokenService.deleteAccessTokenExpiryAsync();
    await tokenService.deleteRefreshTokenAsync();
    await tokenService.deleteRememberMe();
    await tokenService.deleteRefreshTokenExpiryAsync();
    dispatch(setLoggedIn(false));
    navigation.navigate('Landing');
    return false;
  }

  dispatch(setProfile(userProfileResponse.data.data.profile));
  dispatch(setAuthorizedBy(userProfileResponse.data.data.authorizedBy));
  if (userProfileResponse?.data?.success && userProfileResponse.data.data.profile) {
    if (userProfileResponse.data.data.accounts) {
      const accountList = userProfileResponse.data.data.accounts.CA_SELFSERVE;
      if (!accountList || accountList.length <= 0) {
        console.log('no accounts: ');
        dispatch(setIsInOnboardingFlow(true));

        console.info(
          '%cWARNING: Update to Welcome!',
          'color: red; font-size: 40px; font-weight: bold; background-color: #fdd; padding: 14px; border: 4px solid red; border-radius: 10px;'
        );

        navigation.navigate('Onboarding');

        dispatch(setLoggedIn(true));
        return;
      } else if (accountList.length > 1) {
        const accounts = await accountService.getMultiLineAccountInfo({
          cnumAcctOwner: cnum,
        });
        dispatch(setAccounts({ accounts: accounts.data, isMultiLineAccount: true }));
        console.log('has accounts: ', accounts.data);
      } else if (accountList !== undefined) {
        dispatch(setAccounts({ accounts: accountList, isMultiLineAccount: false }));
      }
      const accountInfoResponse = await accountService.getAccountInfo({});
      const profileResponse = await accountService.getProfile({});
      dispatch(setAccountInfoCA(accountInfoResponse.data));
      dispatch(setAccountProfileCA(profileResponse.data));
      dispatch(setLoggedIn(true));
    }
    return true;
  } else {
    console.error(userProfileResponse);
  }
  return false;
};

export const validateToken = async (
  signInResponse: SignInResponse | SignInGoogleResponse | SignInAppleResponse,
  dispatch: Dispatch,
  navigation: NativeStackNavigationProp<RootStackParamList>
) => {
  if (signInResponse.idToken && signInResponse.idRefreshToken) {
    const validateTokenResponse = await accountService.validateToken({
      authtoken: signInResponse.idToken,
    });

    if (validateTokenResponse.data?.cNum && validateTokenResponse.data?.apiToken) {
      await tokenService.setAccessTokenAsync(signInResponse.idToken);
      await tokenService.setRefreshTokenAsync(signInResponse.idRefreshToken);
      await tokenService.setCASelfServeTokenAsync(validateTokenResponse.data.apiToken);

      const millisecondsNow = new Date().getTime();

      // expiry dates are in seconds, here we convert them to milliseconds
      const accessTokenExpiry = millisecondsNow + signInResponse.idTokenExpiresIn * 1000;
      const refreshTokenExpiry = millisecondsNow + signInResponse.idRefreshTokenExpiresIn * 1000;

      await tokenService.setAccessTokenExpiryAsync(accessTokenExpiry);
      await tokenService.setRefreshTokenExpiryAsync(refreshTokenExpiry);

      dispatch(setCnum(validateTokenResponse.data.cNum));
      dispatch(setApiToken(validateTokenResponse.data.apiToken));
      dispatch(setIsLoading(true));
      try {
        const _res = await getUserProfileAsync(
          dispatch,
          navigation,
          validateTokenResponse.data.cNum
        );
        if (_res === true) {
          // navigation.navigate('SelectLine'); // ! No need to Go there yet as we don't have multi line products at the moment
        }

        // dispatch(setLoggedIn(true)); // Move this to Select Line Screen
      } catch (error) {
        console.log('Error: ', error);
      } finally {
        dispatch(setIsLoading(false));
      }

      // dispatch(setIsLoading(true));
      // navigation.navigate('SelectLine');
    }
  }
};

export const CrispChatAction = (
  loggedIn: boolean,
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  PhoneNumber: string
) => {
  if (Platform.OS === 'web') {
    alert("Not Enabled for Web, Please Use Application to test.")
    return;
  }
  CrispChatSDK.configure('13c8a0ca-df39-4c67-ad39-5ea75da90c9d');
  if (loggedIn) {
    CrispChatSDK.setTokenId(id);
    CrispChatSDK.setUserEmail(email);
    CrispChatSDK.setUserNickname(`${firstName} ${lastName}`);
    CrispChatSDK.setUserPhone(PhoneNumber);
  }
  // CrispChatSDK.resetSession();
  CrispChatSDK.show();
};
