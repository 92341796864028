import * as WebBrowser from 'expo-web-browser';
import { RootStackScreenProps } from '../../types';
import useIsMobile from '../../hooks/useIsMobile';

import MobileView from './mobile';
import DesktopView from './desktop';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';

WebBrowser.maybeCompleteAuthSession();

export type PropType = {
  onButtonPress(type: 'CANADA' | 'USA' | 'GUEST'): void;
};

export default function LandingScreen({ navigation }: RootStackScreenProps<'Landing'>) {
  const isMobile = useIsMobile();

  const onButtonPress = (type: 'CANADA' | 'USA' | 'GUEST') => {
    switch (type) {
      case 'USA':
        alert(
          'USA LOGIN NOT DONE\nUSA LOGIN NOT DONE, WILL ADD PLAYSTORE URL ONCE I KNOW ABOUT IT'
        );
        break;
      case 'GUEST':
        navigation.navigate('Root');
        break;
      default:
        navigation.navigate('LogIn');
        break;
    }
  };

  return (
    <ScrollViewWithSafeAreaContainer>
      {isMobile ? (
        <MobileView onButtonPress={onButtonPress} />
      ) : (
        <DesktopView onButtonPress={onButtonPress} />
      )}
    </ScrollViewWithSafeAreaContainer>
  );
}
