import { Alert, Platform, StyleSheet } from 'react-native';
import useIsMobile from '../../hooks/useIsMobile';
import { useEffect, useState } from 'react';
import { authService } from '../../services/SSO/AuthService';
import { tokenService } from '../../services/TokenService';
import { SystemCodes } from '../../types/enums/SystemCodes';
import { RootStackParamList, RootStackScreenProps } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedIn, setCnum, setApiToken, setProfile, setAccounts } from '../../store/user';
import { RootState } from '../../store';
import { setIsInOnboardingFlow, setIsLoading } from '../../store/app';
import { DevMessage } from '../../types/enums/DevMessage';
import { StepType } from '../../types/enums/StepType';
import { accountService } from '../../services/MyAccount/AccountService';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import AsyncStorage from '@react-native-async-storage/async-storage';

import MobileView from './mobile';
// @ts-ignore
import DesktopView from './desktop';
import FullScreenLoader from '../../components/utils/FullScreenLoader';

import { AppleGoogleProps } from '../../components/AppleGoogleSSO';
import { CRED_EMAIL } from '../../constants/constants';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';
import { getUserProfileAsync, validateToken } from '../../utilities/functions';

export type PropType = {
  onPressLogIn(emailText: string, passwordText: string, rememberMe: boolean): void;
  navigation: NativeStackNavigationProp<RootStackParamList, 'LogIn', undefined>;
  emailText: string;
  setEmailText: React.Dispatch<React.SetStateAction<string>>;
  emailTextError: string;
  setEmailTextError: React.Dispatch<React.SetStateAction<string>>;
  passwordText: string;
  setPasswordText: React.Dispatch<React.SetStateAction<string>>;
  passwordTextError: string;
  setPasswordTextError: React.Dispatch<React.SetStateAction<string>>;
  rememberMe: boolean;
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  logInError: string;
  appleGoogleFunctions: AppleGoogleProps;
};

export default function LogInScreen({ navigation, route }: RootStackScreenProps<'LogIn'>) {
  const { cnum } = useSelector((state: RootState) => state.user);
  const { isLoading } = useSelector((state: RootState) => state.app);
  const isMobile = useIsMobile();

  const [emailText, setEmailText] = useState<string>('');
  const [emailTextError, setEmailTextError] = useState<string>('');
  const [passwordText, setPasswordText] = useState<string>('');
  const [passwordTextError, setPasswordTextError] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [pendingActivation, setPendingActivation] = useState(false); // May be remove This?
  const [logInError, setLogInError] = useState('');
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // this only happens in redirect flow from Join Success  page
    if (route.params?.email && route.params?.password) {
      setEmailText(route.params.email);
      setPasswordText(route.params.password);
    }
  }, []);

  const saveCredentials = async (emailText: string, passwordText: string) => {
    await AsyncStorage.setItem(CRED_EMAIL, emailText);
    await tokenService.setRememberMe();
  };

  const onPressLogIn = async (emailText: string, passwordText: string, rememberMe: boolean) => {
    if (rememberMe) {
      await saveCredentials(emailText, passwordText);
    }
    setLoading(true);
    setPendingActivation(false);
    const loginAsync = async () => {
      const signInResponse = await authService.signIn({
        data: {
          username: emailText,
          password: passwordText,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!signInResponse?.data?.success) {
        if (
          signInResponse?.data?.devMessage &&
          signInResponse.data.devMessage === DevMessage.ActivationPending
        ) {
          setLogInError(signInResponse.data.error.message);
          // setPendingActivation(true);
          if (Platform.OS === 'web') {
            const isOkay = confirm('Press Okay to continue with verification.');
            if (isOkay) {
              navigation.navigate('Join', { email: emailText, step: StepType.Second });
            }
          } else {
            Alert.alert(
              'Need Verification',
              'Press Continue to continue with verification.',
              [
                {
                  text: 'Cancel',
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel',
                },
                {
                  text: 'Continue',
                  onPress: () => {
                    navigation.navigate('Join', { email: emailText, step: StepType.Second });
                  },
                },
              ],
              { cancelable: false }
            );
          }
          return;
        }
        console.error(signInResponse);
        setLogInError('Incorrect username or password.');
        return;
      }

      if (signInResponse && signInResponse.data && signInResponse.data.success) {
        setLogInError('');
        validateToken(signInResponse.data.data, dispatch, navigation);
      }
    };

    loginAsync()
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const onPressForgotPassword = () => {
    navigation.navigate('RecoverPassword');
  };

  return isMobile ? (
    <ScrollViewWithSafeAreaContainer>
      <FullScreenLoader loading={isLoadingAPI || isLoading} />
      <MobileView
        onPressLogIn={onPressLogIn}
        emailText={emailText}
        setEmailTextError={setEmailTextError}
        setEmailText={setEmailText}
        emailTextError={emailTextError}
        passwordText={passwordText}
        setPasswordText={setPasswordText}
        setPasswordTextError={setPasswordTextError}
        passwordTextError={passwordTextError}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
        loading={loading}
        logInError={logInError}
        navigation={navigation}
        appleGoogleFunctions={{ setIsLoadingAPI }}
      />
    </ScrollViewWithSafeAreaContainer>
  ) : (
    <ScrollViewWithSafeAreaContainer>
      <FullScreenLoader loading={isLoadingAPI} />
      {/* <DesktopView
        onPressLogIn={onPressLogIn}
        emailText={emailText}
        setEmailTextError={setEmailTextError}
        setEmailText={setEmailText}
        emailTextError={emailTextError}
        passwordText={passwordText}
        setPasswordText={setPasswordText}
        setPasswordTextError={setPasswordTextError}
        passwordTextError={passwordTextError}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
        loading={loading}
        logInError={logInError}
        navigation={navigation}
        appleGoogleFunctions={{ setIsLoadingAPI }}
      /> */}
    </ScrollViewWithSafeAreaContainer>
  );
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
});
