import {
  View,
  Image,
  SafeAreaView,
  StyleSheet,
  Text,
  Dimensions,
  TouchableHighlight,
  ScrollView,
} from 'react-native';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import { PropType } from './index';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import { formatPhoneNumber } from '../../utilities/utilities';
import HorizontalLine from '../../components/HorizontalLine';
import { AppGap } from '../../components/AppGap';
import AuthBackground from '../../components/utils/AuthBackgroud';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import React from 'react';
import CustomText from '../../components/utils/CustomText';

const homeIcon = require('../../assets/icons/home.png');
const mobileIcon = require('../../assets/icons/mobile.png');

const { width, height } = Dimensions.get('window');

const dummyData = {
  mobile: [
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
    {
      name: 'Canada Mobile',
      number: '7788778045',
    },
  ],
  homeInternet: [
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
    {
      name: 'Hight Speed 500MB',
      address: '658 Seymour',
    },
  ],
};

export default ({ onSelectLine }: PropType) => {
  const { accounts } = useSelector((state: RootState) => state.user);

  const ListItem = ({
    type,
    title,
    subtitle,
    accountNumber,
  }: {
    type: 'MOBILE' | 'HOME';
    title: string;
    subtitle: string;
    accountNumber: string;
  }) => (
    <TouchableHighlight
      onPress={onSelectLine.bind(null, title, type, accountNumber)}
      underlayColor={Colors.SelectLineItemHighlightColor} // Color when the button is pressed
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 2,
        padding: 10,
        borderRadius: 10,
      }}
    >
      <>
        <Image
          source={type === 'HOME' ? homeIcon : mobileIcon}
          style={{ width: 50, height: 50, borderRadius: 50 }}
          resizeMode="contain"
        />
        <View style={{ flex: 1, marginLeft: 15 }}>
          <CustomText weight="600" style={{ fontWeight: '600', fontSize: 16 }}>
            {title}
          </CustomText>
          <CustomText style={{ color: Colors.grey }}>
            {type === 'HOME' ? subtitle : formatPhoneNumber(subtitle)}
          </CustomText>
        </View>
        <Image
          source={require('../../assets/icons/right-arrow.png')}
          style={{ width: 15, height: 15 }}
          resizeMode="contain"
        />
      </>
    </TouchableHighlight>
  );

  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <AuthBackground />
        <BottomWhiteSheet style={{ padding: 0 }} maxHeight={550}>
          <View
            style={{
              position: 'absolute',
              top: -175,
              zIndex: 8,
            }}
          >
            <Image
              source={require('../../assets/illustrations/Blue16.png')}
              style={{
                width: 150,
                height: 200,
              }}
              resizeMode="contain"
            />
          </View>
          <View style={{ width: '100%' }}>
            <CustomText
              weight="500"
              style={{
                fontSize: 30,
                marginTop: 30,
                marginBottom: 20,
                textAlign: 'center',
                fontWeight: '500',
              }}
            >
              Select your Line
            </CustomText>
            <ScrollView>
              {accounts.length < 1 ? (
                <>
                  <CustomText
                    style={{
                      backgroundColor: Colors.SelectLineTitleBackgroundColor,
                      color: Colors.SelectLineTitleTextColor,
                      fontSize: 16,
                      paddingVertical: 10,
                      paddingHorizontal: 30,
                      marginVertical: 10,
                    }}
                  >
                    Mobile
                  </CustomText>
                  <View style={{ paddingLeft: 30, paddingRight: 30 }}>
                    {accounts.map((e: any, i) => (
                      <React.Fragment key={`react-fragment-item-mobile-${i}`}>
                        <ListItem
                          subtitle={e.Pnum}
                          title={e.FirstName}
                          accountNumber={e.Cnum}
                          type="MOBILE"
                          key={`item-mobile-${i}`}
                        />
                        {i < dummyData.mobile.length - 1 ? (
                          <HorizontalLine
                            thinkness={1}
                            color={Colors.backgroundGrey}
                            key={`hl-mobile-${i}`}
                          />
                        ) : null}
                      </React.Fragment>
                    ))}
                  </View>
                </>
              ) : null}
              {/* {dummyData.homeInternet.length ? (
                <>
                  <CustomText
                    style={{
                      backgroundColor: Colors.SelectLineTitleBackgroundColor,
                      color: Colors.SelectLineTitleTextColor,
                      fontSize: 16,
                      paddingVertical: 15,
                      paddingHorizontal: 30,
                      marginVertical: 10,
                    }}
                  >
                    Home Internet
                  </CustomText>
                  <View style={{ paddingLeft: 30, paddingRight: 30 }}>
                    {dummyData.homeInternet.map((e, i) => (
                      <>
                        <ListItem
                          subtitle={e.address}
                          title={e.name}
                          type="HOME"
                          key={`item-home-${i}`}
                        />
                        {i < dummyData.mobile.length - 1 ? (
                          <HorizontalLine
                            thinkness={1}
                            color={Colors.backgroundGrey}
                            key={`hl-home-${i}`}
                          />
                        ) : null}
                      </>
                    ))}
                  </View>
                </>
              ) : null} */}
            </ScrollView>
            <AppGap size={10} direction="vertical" />
          </View>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};

const styles = StyleSheet.create({
  logo: {
    flex: 1,
    width: 100,
    height: 50,
  },
});
