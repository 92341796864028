import AppGradientContainer from '../../components/AppGradientContainer';
import { PropTypeVerify } from './index';
import { Image, KeyboardAvoidingView, Platform, Pressable, SafeAreaView, Text, View } from 'react-native';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import { Colors } from '../../constants/Colors';
import HorizontalLine from '../../components/HorizontalLine';
import Button from '../../components/Button';

import { AppGap } from '../../components/AppGap';
import OTPInput from '../../components/OTPInput';
import { useEffect, useState } from 'react';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';

export default ({
  navigation,
  loading,
  onPressCancel,
  onPressConfirm,
  onPressResend,
}: PropTypeVerify) => {
  const [otp, setOtp] = useState<string>('');
  const [otpShowError, setOtpShowError] = useState<boolean>(false);

  useEffect(() => {
    setOtpShowError(false);
  }, [otp]);

  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={-150}
        style={{ flex: 1 }}
      >
        <SafeAreaView style={{ display: 'flex', height: '100%' }}>
          <AuthBackground />
          <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
            <View
              style={{
                position: 'absolute',
                bottom: Platform.select({ android: 380, default: 360 }),
                zIndex: 8,
              }}
            >
              <Image
                source={require('../../assets/illustrations/Blue30.png')}
                style={{
                  width: 250,
                  height: 350,
                }}
                resizeMode="contain"
              />
            </View>
            <View style={{ width: '100%' }}>
              <CustomText
                weight="500"
                style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
              >
                Verification Code
              </CustomText>
              <View style={{ marginBottom: 20 }}>
                <CustomText style={{ fontSize: 12 }}>
                  A verification email has been sent to you
                </CustomText>
              </View>
              <HorizontalLine style={{ marginBottom: 20 }} />
              <OTPInput onChangeText={setOtp} isError={otpShowError} />
              <CustomText
                style={{
                  marginVertical: 15,
                }}
              >
                Didn't receive the code?{' '}
                <CustomText
                  onPress={() => onPressResend()}
                  weight="600"
                  style={{
                    color: Colors.canadaButtonColor,
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                  }}
                >
                  Resend Code
                </CustomText>
              </CustomText>
              <Button
                style={{ marginTop: 25 }}
                loadingStatus={loading}
                disabled={loading}
                onPress={() => {
                  if (otp.length !== 6) {
                    setOtpShowError(true);
                    return;
                  }
                  onPressConfirm(otp, () => setOtpShowError(true));
                }}
                textStyle={{ fontSize: 14 }}
              >
                Continue
              </Button>
              <Button
                size={'FULL'}
                color={Colors.noColor}
                textColor={Colors.greyColor}
                onPress={onPressCancel}
                style={{
                  padding: 5,
                  marginTop: 10,
                  width: 'auto',
                }}
                textStyle={{ fontSize: 14 }}
              >
                Cancel
              </Button>
              <AppGap direction="vertical" size={20} />
            </View>
          </BottomWhiteSheet>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </AppGradientContainer>
  );
};
