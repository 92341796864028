import AppGradientContainer from '../../components/AppGradientContainer';
import { PropType } from './index';
import {
  Image,
  Platform,
  Pressable,
  SafeAreaView,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import { Colors } from '../../constants/Colors';
import HorizontalLine from '../../components/HorizontalLine';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

import AppleGoogleSSO from '../../components/AppleGoogleSSO';
import { AppGap } from '../../components/AppGap';
import AuthBackground from '../../components/utils/AuthBackgroud';
import CustomText from '../../components/utils/CustomText';

export default ({
  navigation,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  loading,
  validationErrors,
  setValidationErrors,
  validateOnPressCreateAccount,
  appleGoogleFunctions,
}: PropType) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={-150}
        style={{ flex: 1 }}
      >
        <SafeAreaView style={{ flex: 1 }}>
          <AuthBackground />
          <BottomWhiteSheet style={{ paddingLeft: 30, paddingRight: 30 }}>
            <View
              style={{
                position: 'absolute',
                top: Platform.select({ android: -190, default: -190, ios: -80 }),
                zIndex: 8,
              }}
            >
              <Image
                source={require('../../assets/illustrations/blue21.png')}
                style={{
                  width: 150,
                  height: Platform.select({ android: 300, default: 300, ios: 120 }),
                }}
                resizeMode="contain"
              />
            </View>
            <View style={{ width: '100%' }}>
              <CustomText
                weight="500"
                style={{ fontSize: 24, fontWeight: '500', marginTop: 50, marginBottom: 10 }}
              >
                Create Your Account
              </CustomText>
              <View
                style={{
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <CustomText>Already a user? </CustomText>
                <Pressable onPress={() => navigation.replace('LogIn')}>
                  <CustomText
                    weight="600"
                    style={{
                      color: Colors.canadaButtonColor,
                      fontWeight: '600',
                      textDecorationLine: 'underline',
                    }}
                  >
                    Sign in
                  </CustomText>
                </Pressable>
              </View>
              <HorizontalLine style={{ marginBottom: 20 }} />
              <TextInput
                placeholder="First Name"
                value={firstName}
                onChangeText={(firstName: string) => {
                  setFirstName(firstName);
                  if (firstName) {
                    setValidationErrors({ ...validationErrors, firstName: '' });
                  }
                }}
                error={{ error: validationErrors.firstName }}
              />
              <TextInput
                placeholder="Last Name"
                value={lastName}
                onChangeText={(lastName: string) => {
                  setLastName(lastName);
                  if (lastName) {
                    setValidationErrors({ ...validationErrors, lastName: '' });
                  }
                }}
                style={{ marginTop: 15 }}
                error={{ error: validationErrors.lastName }}
              />
              <TextInput
                placeholder="Enter your Email"
                keyboardType="email-address"
                value={email}
                onChangeText={(email: string) => {
                  setEmail(email);
                  if (email) {
                    setValidationErrors({ ...validationErrors, email: '' });
                  }
                }}
                style={{ marginTop: 15 }}
                error={{ error: validationErrors.email }}
              />
              <TextInput
                placeholder="Password"
                value={password}
                eyeTopMargin={29}
                onChangeText={(password: string) => {
                  setPassword(password);
                  if (password) {
                    setValidationErrors({ ...validationErrors, password: '' });
                  }
                }}
                style={{ marginTop: 15 }}
                secureTextEntry={true}
                error={{ error: validationErrors.password }}
              />
              <Button
                style={{ marginTop: 25 }}
                onPress={validateOnPressCreateAccount}
                loadingStatus={loading}
                disabled={loading}
                textStyle={{ fontSize: 14 }}
              >
                Create Account
              </Button>
              <CustomText
                style={{
                  textAlign: 'center',
                  color: Colors.inputTextPlaceholderColor,
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                or continue with
              </CustomText>
              <AppleGoogleSSO
                setIsLoadingAPI={appleGoogleFunctions.setIsLoadingAPI}
                propsKey={'sign-up'}
              />
              <AppGap direction="vertical" size={20} />
            </View>
          </BottomWhiteSheet>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </AppGradientContainer>
  );
};
