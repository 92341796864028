import { DimensionValue, Image, ImageSourcePropType, Pressable, Text } from 'react-native';
import { Colors } from '../../constants/Colors';
import CustomText from '../utils/CustomText';

export type Action = {
  image: ImageSourcePropType;
  title: string;
  tag: string;
};

type Props = {
  action: Action;
  onActionSelected: (tag: string) => void;
  textWidth?: DimensionValue; // Asked to keep the action text in max 2 lines
};

const QuickAction = ({ action, onActionSelected, textWidth }: Props) => {
  return (
    <Pressable
      style={{
        width: 55,
        alignItems: 'center',
        paddingVertical: 10,
        marginHorizontal: 10,
      }}
      onPress={() => {
        onActionSelected(action.tag);
      }}
    >
      <Image source={action.image} resizeMode="contain" style={{ width: 44, height: 44 }} />
      <CustomText
        weight="500"
        style={{
          textAlign: 'center',
          marginTop: 5,
          fontSize: 12,
          fontWeight: '500',
          color: Colors.QuickActionTextColor,
          width: textWidth ? textWidth : 60,
        }}
      >
        {action.title}
      </CustomText>
    </Pressable>
  );
};

export default QuickAction;
