import { View, ScrollView, StyleSheet, Platform } from 'react-native';
import { Colors } from '../../constants/Colors';
import AppGradientContainer from '../../components/AppGradientContainer';
import CustomText from '../../components/utils/CustomText';
import { Ionicons } from '@expo/vector-icons';
import Button from '../../components/Button';
import { AppGap } from '../../components/AppGap';
import { HERO_GRADIENT } from '../../constants/constants';
import { useState } from 'react';

const gradientInfo = HERO_GRADIENT.default;

const InitialView = ({
  currentBalance,
  onNext,
}: {
  currentBalance: number;
  onNext(amountToPay: string): void;
}) => {
  const [balance, setBalance] = useState(currentBalance.toFixed(2));

  const updateBalance = (action: number | 'delete' | '.') => {
    console.log(action);
    switch (action) {
      case 'delete':
        if (balance.length === 1 || balance === '0.00') {
          setBalance('0.00');
        } else {
          setBalance((b) => b.slice(0, -1));
        }
        break;
      case '.':
        if (balance.includes('.')) {
          return;
        }
        setBalance((b) => b + action);
        break;

      default:
        if (balance === '0.00') {
          setBalance(action.toString());
        } else if (balance.includes('.')) {
          if (balance.split('.')[1].length < 2) {
            setBalance((b) => b + action);
          }
        } else {
          setBalance((b) => b + action);
        }
        break;
    }
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          backgroundColor: Colors.white,
        }}
      >
        <AppGradientContainer
          autoHeight
          colorStart={gradientInfo.start}
          colorEnd={gradientInfo.end}
          direction={gradientInfo.direction}
          style={{
            paddingBottom: 20,
            paddingTop: 20,
          }}
        >
          <CustomText
            weight="500"
            style={{ fontSize: 12, textAlign: 'center', color: Colors.white }}
          >
            Account Balance
          </CustomText>
          <CustomText
            weight="600"
            style={{ fontSize: 40, color: Colors.white, textAlign: 'center' }}
          >
            $<CustomText style={{ color: Colors.white }}>{balance}</CustomText>
          </CustomText>

          <View
            style={{
              flexDirection: 'row',
              gap: 8,
              justifyContent: 'center',
              marginTop: 24,
            }}
          >
            <Button
              style={{
                borderRadius: 20,
                paddingVertical: 2,
                paddingHorizontal: 10,
                backgroundColor: Colors.gradientLightBlue,
              }}
              textStyle={{
                fontSize: 14,
              }}
              onPress={() => {
                setBalance('0.00');
              }}
            >
              Clear Balance
            </Button>
            <Button
              style={{
                borderRadius: 20,
                paddingVertical: 2,
                paddingHorizontal: 10,
                backgroundColor: Colors.gradientDarkBlue,
              }}
              textStyle={{
                fontSize: 14,
              }}
              onPress={() => {
                setBalance(currentBalance.toFixed(2));
              }}
            >
              Current Balance
            </Button>
          </View>
          <CustomText
            style={{
              fontSize: 12,
              color: Colors.PaginationDotColor,
              textAlign: 'center',
              marginTop: 24,
            }}
          >
            Current Account Balance ${currentBalance}
          </CustomText>
          <AppGap size={48} direction="vertical" />
        </AppGradientContainer>
        <View
          style={{
            backgroundColor: Colors.white,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            marginTop: -24,
            padding: 20,
          }}
        >
          <View style={styles.numberRowContainer}>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(1);
              }}
            >
              1
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(2);
              }}
            >
              2
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(3);
              }}
            >
              3
            </CustomText>
          </View>
          <View style={styles.numberRowContainer}>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(4);
              }}
            >
              4
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(5);
              }}
            >
              5
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(6);
              }}
            >
              6
            </CustomText>
          </View>
          <View style={styles.numberRowContainer}>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(7);
              }}
            >
              7
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(8);
              }}
            >
              8
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(9);
              }}
            >
              9
            </CustomText>
          </View>
          <View style={styles.numberRowContainer}>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance('.');
              }}
            >
              .
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance(0);
              }}
            >
              0
            </CustomText>
            <CustomText
              weight="500"
              style={styles.numberText}
              onPress={() => {
                updateBalance('delete');
              }}
            >
              <Ionicons name="backspace-outline" size={24} color="black" />
            </CustomText>
          </View>
          <Button
            style={{ marginTop: 16 }}
            onPress={() => {
              onNext(balance);
            }}
            textStyle={{ fontSize: 14 }}
          >
            Go to Payment Page
          </Button>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  numberRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  numberText: {
    textAlign: 'center',
    fontSize: 20,
    width: 60,
    height: 60,
    verticalAlign: 'middle',
    ...Platform.select({
      ios: {
        lineHeight: 60, // as same as height
      },
      default: {},
    }),
  },
});

export default InitialView;
