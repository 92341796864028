import { Dimensions, DimensionValue, Image, Platform, Pressable, ScrollView } from 'react-native';
import { RootStackParamList } from '../../types';
import { useState } from 'react';

const Offering = () => {
  const { width } = Dimensions.get('window');

  const [scrollWidth, setScrollWidth] = useState<DimensionValue>('auto');

  const offering: { image: any; pageLink: keyof RootStackParamList }[] = [
    { image: require('../../assets/offer/offer-1.png'), pageLink: 'JoinSuccess' },
    { image: require('../../assets/offer/offer-1.png'), pageLink: 'JoinSuccess' },
    { image: require('../../assets/offer/offer-1.png'), pageLink: 'JoinSuccess' },
  ];

  let currentPage = 0;

  const handleScroll = (event: any) => {
    const xOffset = event.nativeEvent.contentOffset.x;
    const newPage = Math.round(xOffset / width);
    if (newPage === 0) {
      setScrollWidth('auto');
    } else {
      setScrollWidth(310);
    }
  };

  return (
    <ScrollView
      style={{ height: 156, width: scrollWidth, overflow: 'visible' }}
      contentContainerStyle={Platform.select({
        web: { marginRight: 15 },
        default: null,
      })}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      scrollEventThrottle={16}
      pagingEnabled={true}
      onScroll={handleScroll}
    >
      {offering.map((offer, index) => (
        <Pressable
          key={index}
          onPress={() => {
            // @ts-ignore
            // navigation.navigate(offer.pageLink);
          }}
          style={{
            width: 303,
            height: 156,
            marginLeft: 15,
            marginRight: index === offering.length - 1 ? 15 : 0,
          }}
        >
          <Image
            source={offer.image}
            style={{ width: '100%', height: '100%', borderRadius: 8 }}
            resizeMode="contain"
          />
        </Pressable>
      ))}
    </ScrollView>
  );
};

export default Offering;
