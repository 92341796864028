import useIsMobile from '../../hooks/useIsMobile';
import { useEffect, useState } from 'react';
import { StepType } from '../../types/enums/StepType';
import { DataErrorType } from '../../types/enums/DataErrorType';
import { SystemCodes } from '../../types/enums/SystemCodes';
import { RootStackParamList, RootStackScreenProps } from '../../types';
import { signUpService } from '../../services/SSO/SignUpService';
import { DevMessage } from '../../types/enums/DevMessage';

import MobileView from './mobile';
import DesktopView from './desktop';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { validate } from '../../utilities/utilities';

import MobileViewVerify from './mobile-verify';
import DesktopViewVerify from './desktop-verify';

import { AppleGoogleProps } from '../../components/AppleGoogleSSO';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import { ScrollView } from 'react-native-gesture-handler';
import ScrollViewWithSafeAreaContainer from '../../components/utils/ScrollViewWithSafeAreaContainer';

export type PropType = {
  navigation: NativeStackNavigationProp<RootStackParamList, 'Join', undefined>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  validateOnPressCreateAccount(): any;
  loading: boolean;
  validationErrors: ValidationError;
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationError>>;
  appleGoogleFunctions: AppleGoogleProps;
};

export type PropTypeVerify = {
  navigation: NativeStackNavigationProp<RootStackParamList, 'Join', undefined>;
  loading: boolean;
  onPressConfirm(otp: string, setOtpShowError: () => void | undefined): void;
  onPressCancel(): void;
  onPressResend(): void;
};

type ValidationError = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export default function RegisterScreen({ navigation, route }: RootStackScreenProps<'Join'>) {
  const isMobile = useIsMobile();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationError>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });
  const [step, setStep] = useState<StepType>(StepType.First);
  const [loading, setLoading] = useState(false);
  const [_, setUserId] = useState('');
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);

  useEffect(() => {
    // this only happens in redirect flow from login page
    if (route.params?.email && route.params?.step) {
      setStep(route.params.step);
      setEmail(route.params.email);
    }
  }, []);

  const validateOnPressCreateAccount = async () => {
    const _validationError: ValidationError = {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    };
    let isError = false;
    if (firstName === '') {
      _validationError.firstName = 'First Name required';
      isError = true;
    }
    if (lastName === '') {
      _validationError.lastName = 'Last name required';
      isError = true;
    }
    if (!validate('email', email)) {
      _validationError.email = 'Please enter a valid email address.';
      isError = true;
    } else {
      const errorExists = await validateEmail();
      if (errorExists) {
        _validationError.email = errorExists;
        isError = true;
      }
    }
    if (!validate('password', password)) {
      _validationError.password =
        'Password must contain minimum 8 charcters, at least one number, special character and capital letter';
      isError = true;
    }
    setValidationErrors(_validationError);
    if (isError) {
      setLoading(false);
      return;
    }
    onPressCreateAnAccount();
  };

  const validateEmail = async () => {
    try {
      setLoading(true);
      const validateEmailResponse = await signUpService.validateEmail({
        data: {
          systemCode: SystemCodes.CA_SELFSERVE,
          email: email,
        },
      });
      if (validateEmailResponse.data.devMessage === DevMessage.AccountAlreadyExists) {
        return 'Email already exists, Please sign-in.';
      }
      if (
        validateEmailResponse.data.devMessage ===
        DevMessage.AccountAlreadyExistsAndActivationPending
      ) {
        const isOkay = confirm(
          'Email already exists but account is not verified, Please Okay to Verify account.'
        );
        console.log('Test:', isOkay, 'User want to Verify Account, Move to Next Step');
        if (isOkay) {
          setStep(StepType.Second);
          onPressResendNow();
        }
        return 'Email already exists but account is not verified, Please Verify';
      }
      if (validateEmailResponse.data.devMessage === DevMessage.NoRecordFound) {
        return '';
      }
    } catch (error) {
      return `${error}`;
    }
  };

  const onPressCreateAnAccount = () => {
    setLoading(true);
    const signUpAsync = async () => {
      const signUpResponse = await signUpService.signUp({
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!signUpResponse || !signUpResponse?.data?.success) {
        console.error('Something went wrong in signUp(): ', signUpResponse);
        if (
          signUpResponse?.data?.devMessage &&
          signUpResponse?.data?.devMessage === DevMessage.AccountExistsAndNotActivated
        ) {
          // setPasswordErrors(["Account has not been verified. Please click here to proceed to account verification."])
          // skip to next step if they already exist.
          // onPressResendNow();
          setStep(StepType.Second);
        }
        return;
      }

      if (signUpResponse && signUpResponse.data && signUpResponse.data.success) {
        setStep(StepType.Second);
        setUserId(signUpResponse.data.data.userId);
      }
    };

    signUpAsync()
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors) {
          if (err.response.data.errors[DataErrorType.Password]) {
            setValidationErrors({
              ...validationErrors,
              password: err.response.data.errors[DataErrorType.Password],
            });
          }
        }
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPressResendNow = () => {
    const resendActivationCodeAsync = async () => {
      const resendActivationCodeResponse = await signUpService.resendActivationCode({
        data: {
          email: email,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!resendActivationCodeResponse?.data?.success) {
        console.error(
          'Something went wrong with resendActivationCodeAsync():',
          resendActivationCodeResponse
        );
        return;
      }
    };

    resendActivationCodeAsync().catch((err) => console.error(err));
  };

  const onPressConfirm = (otp: string, setOtpShowError: () => void) => {
    if (!otp) {
      return;
    }

    const activateUserByEmailIdAsync = async () => {
      setLoading(true);

      const activateUserResponse = await signUpService.activateUserByEmailId({
        data: {
          email: email,
          code: otp,
          systemCode: SystemCodes.CA_SELFSERVE,
        },
      });

      if (!activateUserResponse?.data?.success) {
        console.error('Something went wrong with activateUserByEmailId(): ', activateUserResponse);
        if (
          activateUserResponse?.data?.devMessage === DevMessage.InvalidOTP ||
          activateUserResponse?.data?.devMessage === DevMessage.InCorrectOTP
        ) {
          setOtpShowError();
        }
        return;
      }

      if (activateUserResponse && activateUserResponse.data.success) {
        navigation.navigate('JoinSuccess', { email, password });
        setStep(StepType.First);
      }
    };

    activateUserByEmailIdAsync()
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isMobile ? (
        <ScrollViewWithSafeAreaContainer minHeight={812}>
          <FullScreenLoader loading={isLoadingAPI} />
          {step === StepType.First ? (
            <MobileView
              navigation={navigation}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              loading={loading}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              validateOnPressCreateAccount={validateOnPressCreateAccount}
              appleGoogleFunctions={{ setIsLoadingAPI }}
            />
          ) : (
            <MobileViewVerify
              navigation={navigation}
              loading={loading}
              onPressConfirm={onPressConfirm}
              onPressResend={onPressResendNow}
              onPressCancel={() => {
                setStep(StepType.First);
              }}
            />
          )}
        </ScrollViewWithSafeAreaContainer>
      ) : (
        <>
          <FullScreenLoader loading={isLoadingAPI} />
          {step === StepType.First ? (
            <DesktopView
              navigation={navigation}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              loading={loading}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              validateOnPressCreateAccount={validateOnPressCreateAccount}
              appleGoogleFunctions={{ setIsLoadingAPI }}
            />
          ) : (
            <DesktopViewVerify
              navigation={navigation}
              loading={loading}
              onPressConfirm={onPressConfirm}
              onPressResend={onPressResendNow}
              onPressCancel={() => {
                setStep(StepType.First);
              }}
            />
          )}
        </>
      )}
    </>
  );
}
