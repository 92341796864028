import { SafeAreaView, StatusBar } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import CustomText from '../../components/utils/CustomText';
import AllTab from './AllTab';
import MobileTab from './MobileTab';
import HomeInternetTab from './HomeInternetTab';
import { useEffect, useState } from 'react';
import { accountService } from '../../services/MyAccount/AccountService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Product } from '../../types/responses/MyAccount/GetMultiLineProductResponse';
import FullScreenLoader from '../../components/utils/FullScreenLoader';
import mobile from '../LandingScreen/mobile';

const Tab = createMaterialTopTabNavigator();

const MyOrderScreen = () => {
  const { email } = useSelector((state: RootState) => state.user);
  const [list, setList] = useState<{
    mobile: Product[];
    all: Product[];
    homeInternet: Product[];
  }>({
    all: [],
    mobile: [],
    homeInternet: [],
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getOrders = async () => {
      try {
        setLoading(true);
        const response = await accountService.getMultiLineProduct(email);
        if (response.data.Success && response.data.Products && response.data.Products.length > 0) {
          const data = response.data.Products.map((products) => products.Product).sort((product) =>
            product.TerminationDate === '' ? -1 : 1
          );
          setList((list) => ({ ...list, all: data, mobile: data }));
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    getOrders();
  }, []);

  return (
    <>
      {loading ? <FullScreenLoader loading={loading} /> : null}
      <SafeAreaView style={{ flex: 1, height: '100%', marginTop: StatusBar.currentHeight }}>
        <Tab.Navigator
          screenOptions={{
            tabBarLabel: ({ color, children }) => (
              <CustomText
                weight={'500'}
                style={{
                  color,
                  fontSize: 16,
                  textTransform: 'capitalize',
                  overflow: 'hidden',
                  width: 120, // Set a fixed width to prevent wrapping
                  textAlign: 'center', // Center text within the width
                }}
              >
                {children}
              </CustomText>
            ),
          }}
        >
          <Tab.Screen name="All" component={() => <AllTab list={list.all} />} />
          <Tab.Screen name="Mobile" component={() => <MobileTab list={list.mobile} />} />
          <Tab.Screen
            name="Home Internet"
            component={() => <HomeInternetTab list={list.homeInternet} />}
          />
        </Tab.Navigator>
      </SafeAreaView>
    </>
  );
};

export default MyOrderScreen;
