import { RootStackScreenProps } from "../types";
import { View, Text, StyleSheet, Pressable, Platform } from "react-native";
import AppContainer from "../components/AppContainer";
import AppHeader from "../components/AppHeader";
import CommonStyles from "../constants/CommonStyles";
import { AppTextInput } from "../components/AppTextInput";
import { useEffect, useState } from "react";
import { AppButton } from "../components/AppButton";
import { AppGap } from "../components/AppGap";
import { Colors } from "../constants/Colors";
import { accountService } from "../services/MyAccount/AccountService";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function VacationHoldScreen({ navigation }: RootStackScreenProps<'VacationHold'>) {
  const { CurrentBalance } = useSelector((state: RootState) => state.selfServeCA.accountInfoCA);
  const [phoneNum, setPhoneNum] = useState("1234123412");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  
  const onPressBack = () => {
    navigation.goBack();
  }

  const onPressCancel = () => {
    navigation.goBack();
  }

  const onPressContinue = () => {
    if (!phoneNum) {
      setErrors(["Please enter a phone number."]);
      return;
    }

    if (CurrentBalance > 0) {
      setErrors(["You must pay your outstanding balance first."]);
      return;
    }

    if (fromDate.length < 5 || endDate.length < 5) {
      setErrors(["Please set a from date and an end date."]);
      return;
    }

    // test if it's a valid month
    if (Number(fromDate.split("/")[0]) > 12 || Number(endDate.split("/")[0]) > 12) {
      setErrors(["Please enter a valid month."]);
      return;
    }

    const now = new Date();
    const barrierDate = new Date(now.getFullYear() + "-" + (now.getMonth() + 2) + "-02");

    // construct the dates as date objects
    const fromDateArray = fromDate.split("/");
    const endDateArray = endDate.split("/");
    // year - month - 02 format
    const fromDateString = "20" + fromDateArray.reverse().join("-") + "-02";
    const endDateString = "20" + endDateArray.reverse().join("-") + "-02";
    const fromDateObj = new Date(fromDateString);
    const endDateObj = new Date(endDateString);

    if (fromDateObj < barrierDate) {
      setErrors(["The from date must be a future date."]);
      return;
    }

    if (endDateObj < fromDateObj) {
      setErrors(["The end date must be past the from date."]);
      return;
    }

    let startDate = new Date(fromDateObj.getTime());
    const threeMonthsAfter = new Date(
      startDate.setMonth(startDate.getMonth() + 3)
    );

    if (threeMonthsAfter < endDateObj) {
      setErrors(["Vacation suspension cannot be longer than 3 months."]);
      return;
    }

    const postSuspensionAsync = async () => {
      setLoading(true);
      const postSuspensionResponse = await accountService.postSuspension({
        sdate: fromDateString,
        edate: endDateString,
        reason: "vacation"
      });

      if (!postSuspensionResponse || !postSuspensionResponse.data) {
        console.error("Something went wrong with postSuspensionAsync()");
        return;
      }

      if (postSuspensionResponse && postSuspensionResponse.data) {
        console.log("suspension response: ", postSuspensionResponse.data);
      }
    }

    postSuspensionAsync().catch(err => { 
      console.error(err);
      setErrors(["Failed to suspend your plan. Please contact support."]);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <AppContainer>
      <AppHeader onPressBack={onPressBack} />
      <Text style={CommonStyles.title}>Vacation Hold</Text>
      <AppGap size={25} direction="vertical"/>
      <Text style={CommonStyles.subtitle}>Which Phone Number?</Text>
      <View style={styles.inputContainer}>
        <AppTextInput maxLength={10} numbersOnly onChangeText={(text) => setPhoneNum(text)} value={phoneNum}/>
      </View>
      <View style={[styles.inputContainer, CommonStyles.justifyRow ]}>
        <View style={{ flex: 1 }}>
          <Text style={{ paddingBottom: 5 }}>From Date</Text>
          <AppTextInput expiryDate value={fromDate} numbersOnly maxLength={5} onChangeText={(text) => setFromDate(text)} placeholder="MM/YY"/>
        </View>
        <AppGap size={10} direction="horizontal"/>
        <View style={{ flex: 1 }}>
          <Text style={{ paddingBottom: 5 }}>End Date</Text>
          <AppTextInput expiryDate value={endDate} numbersOnly maxLength={5} onChangeText={(text) => setEndDate(text)} placeholder="MM/YY"/>
        </View>
      </View>
      {errors.length > 0 &&
        <View style={styles.marginTop}>
          {errors.map((error, index) => {
            return (
              <Text style={CommonStyles.errorText} key={index}>{error}</Text>
            )
          })}
        </View>
      }
      <View style={CommonStyles.bottomContainer}>
        <AppButton text="Continue" loading={loading} onPress={onPressContinue}/>
        <AppGap size={20} direction="vertical"/>
        <AppButton text="Cancel" outline color={Colors.red} onPress={onPressCancel}/>
      </View>
    </AppContainer>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25
  },
  inputContainer: {
    marginVertical: 10
  }
})