import { View, SafeAreaView } from "react-native";
import CommonStyles from "../constants/CommonStyles";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { ReactNode } from "react";

interface AppContainerProps {
  children: ReactNode
}

export default function AppContainer(props: AppContainerProps) {
  const horizontalContainerMargin = useHorizontalContainerMargin();
  return (
    <SafeAreaView style={{ flex: 1, height: "100%" }}>
      <View style={{ marginHorizontal: horizontalContainerMargin, flex: 1, }}>
        <View style={[ CommonStyles.container, { flex: 1 } ]}>
          {props.children}
        </View>
      </View>
    </SafeAreaView>
  )
}