// store file for ephemeral App functionality
// this is best for information that will be temporarily used to make API calls 
// and is not necessarily permanently attached to the user
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ParsedPlan } from "../types/models/ParsedPlan";

type CheckoutTotalInfo = {
  planPrice: number,
  taxes: number,
  subTotal: number,
  total: number,
  simCardFee: number,
  taxRate: string,
  chargeDuration: string,
  prorateDuration: string,
  prorateFee: number
}

type ShippingInfo = {
  sfID: number,
  sfName: string,
  sfFee: number,
  delivery_province: string,
  delivery_postal: string,
  delivery_countryId: string,
  delivery_address: string,
  shippingStreetAddress: string,
  shippingCity: string,
  shippingProvince: string,
  shipping_deliLongest: number
}

type PostpaidSummaryInfo = {
  prorateDataCap: string,
  prorateStartDate: string,
  prorateEndDate: string,
  proratePct: string,
  prorate_duration: string,
  charge_duration: string,
  chargeStart: string
}

const initialCheckoutTotalInfo = {
  planPrice: 0,
  taxes: 0,
  total: 0,
  subTotal: 0,
  simCardFee: 0,
  taxRate: "",
  chargeDuration: "",
  prorateDuration: "",
  prorateFee: 0
}

const initialState = {
  simcardOrder: false,      // false.
  simcard_no: "",          // leave empty
  startDateLocal: null,
  endDateLocal: null,
  startDate: "",  // from handleActivationDateChange    // set
  endDate: "",   // from handleActivationDateChange    // set
  planId: 0,     // selectedPlanId    // set
  activationDateObject: null,
  activationDateString: "",
  esim: true,
  firstName: "",     // from handleformDataChange
  lastName: "",      // from handleformDataChange  
  email: "",   // from handleFormDataChange  
  emailConfirm: "",  // from handleFormDataChange
  serviceType: "",   // "leave empty"
  service_countryId: 42,  // from getServiceblhblahblah  // set by useeffect
  service_province: "",  // selected by user    // set by "handleStateMenuClick" & "handleProvinceMenuClick"
  service_city: "",   // to be selected by user    // set by handleCityChange
  currency: "CAD",  // set by country useEffect
  promocode: "",   // will be blank for now
  prepaidPromocode: "",
  bizId: 0,   // will be blank for now
  referral_cnum: "",  // will be blank for now
  consent_cem: false,
  us_activation_date: null, // for US checkout
  total: 0,
  us_plintron_plan_id: 0,
  us_i_customer: 0,
  us_main_i_product: 0, // from getDefaultPlans
  us_i_account: 0,  // from checkout.checkout
  payment_method: "",
  usPromocode: "",
  promocredit: 0,
  usSimCardNoCharge: false,
  countries: [],

  // shipping related
  sfID: 0,
  sfFee: 0,
  sfName: "",
  delivery_countryId: "",     // string version of get service country   // apparently unneeded as of now
  delivery_province: "",    // leave empty
  delivery_address: "",   // leave empty
  delivery_postal: "",   // leave empty
  shippingCity: "",
  shippingProvince: "",
  shippingStreetAddress: "",
  shipping_deliLongest: 0,

  // portin
  portin_carrier: "",
  portin_accountNo: "",
  portin_phoneNo: "",
  portin_other: "",

  // us portin 
  us_portin_service_unitNo: "",
  us_portin_service_street_address: "",
  us_portin_service_city: "",
  us_portin_service_state_province: "",
  us_portin_service_country: "United States",
  us_portin_service_country_index: 247,
  us_portin_service_zip_postal: "",
  us_portin_service_full_address: "",
  us_portin_account_number: "",
  us_portin_pin_number: "",
  us_portin_phone_number: "",

  // postpaid section:
  isPostpaid: false,
  prorateDataCap: "",
  prorateStartDate: "",
  prorateEndDate: "",
  proratePct: "",
  prorate_duration: "",
  charge_duration: "",
  chargeStart: "",
  delivery_type: 0,
  delivery_needed: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: false,
    signUpVars: {
      signUpPhase: "Welcome",
      isEsim: false
    },
    isInOnboardingFlow: false,
    onboardingPlan: {
      calling: "",
      carrier: "",
      data: "",
      message: "",
      planId: 0,
      planTypeD: "",
      plan_fee: 0,
      plintronPlanId: null,
      subscriptionId: null
    } as ParsedPlan,
    activationInfo: initialState,
    checkoutTotalInfo: initialCheckoutTotalInfo,
    drawerVisible: false,
    isNewUser: true,
  },
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSignUpPhase(state, action: PayloadAction<string>) {
      state.signUpVars.signUpPhase = action.payload;
    },
    setSignUpIsEsim(state, action: PayloadAction<boolean>) {
      state.signUpVars.isEsim = action.payload;
    },
    setActivationInfoEsim(state, action: PayloadAction<boolean>) {
      state.activationInfo.esim = action.payload;
    },
    setActivationInfoPlanId(state, action: PayloadAction<number>) {
      state.activationInfo.planId = action.payload;
    },
    setActivationInfoCity(state, action: PayloadAction<string>) {
      state.activationInfo.service_city = action.payload;
    },
    setActivationInfoProvince(state, action: PayloadAction<string>) {
      state.activationInfo.service_province = action.payload;
    },
    setActivationInfoIsPostpaid(state, action: PayloadAction<boolean>) {
      state.activationInfo.isPostpaid = action.payload;
    },
    setActivationInfoStartDate(state, action: PayloadAction<string>) {
      state.activationInfo.startDate = action.payload;
    },
    setActivationInfoEndDate(state, action: PayloadAction<string>) {
      state.activationInfo.endDate = action.payload;
    },
    setActivationInfoConsentCem(state, action: PayloadAction<boolean>) {
      state.activationInfo.consent_cem = action.payload;
    },
    setActivationInfoFirstName(state, action: PayloadAction<string>) {
      state.activationInfo.firstName = action.payload;
    },
    setActivationInfoLastName(state, action: PayloadAction<string>) {
      state.activationInfo.lastName = action.payload;
    },
    setActivationInfoEmail(state, action: PayloadAction<string>) {
      state.activationInfo.email = action.payload;
    },
    setOnboardingPlan(state, action: PayloadAction<ParsedPlan>) {
      state.onboardingPlan = action.payload;
    },
    setCheckoutTotalInfo(state, action: PayloadAction<CheckoutTotalInfo>) {
      state.checkoutTotalInfo = action.payload;
    },
    setPostpaidSummaryInfo(state, action: PayloadAction<PostpaidSummaryInfo>) {
      state.activationInfo.prorateDataCap = action.payload.prorateDataCap;
      state.activationInfo.prorateStartDate = action.payload.prorateStartDate;
      state.activationInfo.prorateEndDate = state.activationInfo.prorateEndDate;
      state.activationInfo.proratePct = state.activationInfo.proratePct;
      state.activationInfo.prorate_duration = state.activationInfo.prorate_duration;
      state.activationInfo.charge_duration = state.activationInfo.charge_duration;
      state.activationInfo.chargeStart = state.activationInfo.chargeStart;
    },
    setShippingInfo(state, action: PayloadAction<ShippingInfo>) {
      state.activationInfo.shippingStreetAddress = action.payload.shippingStreetAddress;
      state.activationInfo.shippingCity = action.payload.shippingCity;
      state.activationInfo.shippingProvince = action.payload.shippingProvince;
      state.activationInfo.sfID = action.payload.sfID;
      state.activationInfo.sfFee = action.payload.sfFee;
      state.activationInfo.sfName = action.payload.sfName;
      state.activationInfo.delivery_countryId = action.payload.delivery_countryId;
      state.activationInfo.delivery_province = action.payload.delivery_province;
      state.activationInfo.delivery_address = action.payload.delivery_address;
      state.activationInfo.delivery_postal = action.payload.delivery_postal;
      state.activationInfo.shipping_deliLongest = action.payload.shipping_deliLongest;
    },
    setDrawerVisible(state, action: PayloadAction<boolean>) {
      state.drawerVisible = action.payload;
    },
    setIsInOnboardingFlow(state, action: PayloadAction<boolean>) {
      state.isInOnboardingFlow = action.payload;
    },
    setIsNewUser(state, action: PayloadAction<boolean>) {
      state.isNewUser = action.payload
    }
  }
});

export const {
  setIsLoading,
  setSignUpPhase,
  setSignUpIsEsim,
  setOnboardingPlan,
  setActivationInfoFirstName,
  setActivationInfoLastName,
  setActivationInfoEmail,
  setActivationInfoConsentCem,
  setActivationInfoPlanId,
  setActivationInfoEsim,
  setActivationInfoCity,
  setActivationInfoProvince,
  setActivationInfoIsPostpaid,
  setActivationInfoStartDate,
  setActivationInfoEndDate,
  setCheckoutTotalInfo,
  setPostpaidSummaryInfo,
  setShippingInfo,
  setDrawerVisible,
  setIsInOnboardingFlow,
  setIsNewUser
} = appSlice.actions;

export default appSlice.reducer;