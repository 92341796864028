import AppGradientContainer from '../../components/AppGradientContainer';
import { PropType } from './index';
import { Image, SafeAreaView, StyleSheet, Text, View } from 'react-native';
import BottomWhiteSheet from '../../components/BottomWhiteSheet';
import { Colors } from '../../constants/Colors';
import CustomText from '../../components/utils/CustomText';

export default (props: PropType) => {
  return (
    <AppGradientContainer
      colorLeft={Colors.gradientDarkBlue}
      colorRight={Colors.gradientLightBlue}
      direction="LEFT-RIGHT"
    >
      <SafeAreaView style={{ display: 'flex', height: '100%' }}>
        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
          <Image
            source={require('../../assets/images/logo_white.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <BottomWhiteSheet height={350}>
          <CustomText>This is Sparta</CustomText>
        </BottomWhiteSheet>
      </SafeAreaView>
    </AppGradientContainer>
  );
};
const styles = StyleSheet.create({
  logo: {
    flex: 1,
    width: 100,
    height: 50,
  },
});
